import React from "react";
import ball from "../../../../Assets/photos/ball.png";
import football from "../../../../Assets/photos/football.png";
import tenis from "../../../../Assets/photos/table-tennis.png";

const GameTypeBall = ({ data }) => {
  return (
    <div>
      {data == 1 ? (
        <img src={football} alt="ball" style={{ width: "30px" }} />
      ) : data == 2 ? (
        <img src={ball} alt="ball" style={{ width: "35px" }} />
      ) : (
        <img src={tenis} alt="ball" style={{ width: "35px" }} />
      )}
    </div>
  );
};

export default GameTypeBall;
