import React, { useState } from "react";
import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import AddQuestionModal from "./AddQuestionModal";
import DefaultAddQuestionModal from "./DefaultAddQuestionModal";
import { useRemoveSetDefaultMatchMutation } from "../../../../features/api/setdefaultmatchApi";
import { toast } from "react-toastify";

const SetDefaultActionModal = ({ data, closeModal, openModal }) => {
  console.log(data);
  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  const [openAddQuestionModal, setOpenAddQuestionModal] = useState(false);
  const handleAddQuestionModal = (data) => {
    setOpenAddQuestionModal(true);
    // closeModal()
  };

  const [removeSetDefaultMatch] = useRemoveSetDefaultMatchMutation();
  const handleDeleteDefaultMatch = async () => {
    try {
      const confirm = window.confirm("Are you sure you want to delete");
      if (confirm) {
        const response = await removeSetDefaultMatch(data?.id);
        if (response?.data) {
          toast.success(response?.data?.message);
          closeModal();
        }
      }
      // const response = await removeSetDefaultMatch(data?.id);
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseAddQuestionModal = () => setOpenAddQuestionModal(false);

  if (!openModal) return null;
  return (
    <div
      id="ModalBackground"
      // onClick={ModalClose}
      className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50"
    >
      <div className="w-11/12 sm:4/5 md:3/5 lg:w-1/5 p-5 rounded bg-white text-black">
        <div className="flex justify-end">
          <p
            className="text-lg text-gray-500 cursor-pointer"
            onClick={closeModal}
          >
            <FaTimes />
          </p>
        </div>
        <div className="flex flex-col mt-4 gap-y-2">
          <button
            onClick={() => handleAddQuestionModal()}
            className="bg-white shadow-lg border rounded-sm py-3 text-emerald-600 font-bold"
          >
            Add Question
          </button>

          <button
            onClick={() => handleDeleteDefaultMatch()}
            className="bg-white shadow-lg border rounded-sm py-3 text-emerald-600 font-bold"
          >
            Delete Match
          </button>
        </div>
      </div>
      <DefaultAddQuestionModal
        qiestionData={data}
        openModal={openAddQuestionModal}
        closeModal={handleCloseAddQuestionModal}
      />
    </div>
  );
};

export default SetDefaultActionModal;
