import { RouterProvider } from "react-router-dom";
import router from "./Routers/Routes/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="scroll-smooth">
      <RouterProvider router={router} />
      <ToastContainer theme="dark" position="top-left" autoClose={3000} />
    </div>
  );
}

export default App;
