import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaEye, FaEyeSlash, FaTimes } from 'react-icons/fa';

const TransferBalance = ({ closeModal, openModal }) => {

    useEffect(() => {
        if (openModal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [openModal]);

    const ModalClose = (e) => {
        if (e.target.id === 'ModalBackground')
            closeModal()
    }
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    if (!openModal) return null;
    return (
        <div
            id='ModalBackground'
            onClick={ModalClose}
            className='fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50'
        >
            <div
                className='w-11/12 sm:4/5 md:3/5 lg:w-2/5 p-5 rounded bg-white text-black'
            >
                <div className='flex items-center justify-between '>
                    <h1 className='text-xl font-bold'> <span className='pr-3 text-green-400'>|</span>Transfer Balance </h1>
                    <p className='text-lg text-gray-500' onClick={closeModal}><FaTimes /></p>
                </div>
                <div className='' >
                    <form action="" className='mt-5'>
                        <div className="flex items-center justify-between w-full my-4 ">
                            <label
                                htmlFor="UserId"
                                className="text-sm font-bold "
                            >
                                User Id
                            </label>
                            <input
                                type="text"
                                id="UserId"
                                placeholder="User Id"
                                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
                            />
                        </div>

                        <div className="flex items-center justify-between w-full my-4 ">
                            <label
                                htmlFor="Amount"
                                className="text-sm font-bold "
                            >
                                Amount
                            </label>
                            <input
                                type="text"
                                id="Amount"
                                placeholder="Enter Amount"
                                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
                            />
                        </div>

                        <div className="relative flex items-center justify-between w-full my-4 ">
                            <label htmlFor="Password" className="text-sm font-bold">
                                Password
                            </label>
                            <div className="relative  w-2/3 " id="Password">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    className="p-2 rounded w-full text-sm border-2 focus:outline-teal-600 "
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <div
                                    className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 text-xl cursor-pointer"
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </div>
                            </div>
                        </div>
                        <button type='submit' className='bg-green-800 text-white text-sm w-full font-bold p-2 mt-3 rounded'>Submit</button>
                    </form>
                </div >
            </div >
        </div >
    );
};

export default TransferBalance;