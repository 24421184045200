import React from "react";
import { useGetClubTransactionQuery } from "../../features/api/mybetsApi";

const ClubTransaction = () => {
  const { data, isLoading } = useGetClubTransactionQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const getData = data?.transaction;

  return (
    <div className="mt-12 p-5">
      <h1 className="text-lg my-4">Transaction History</h1>
      <div className="w-full">
        {/* Desktop Table */}
        <div className="hidden md:block overflow-x-auto">
          <table className="min-w-full border-collapse border border-gray-200">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left text-black bg-gray-100 border-b border-gray-200">
                  Sl No
                </th>
                <th className="px-4 py-2 text-left text-black bg-gray-100 border-b border-gray-200">
                  Date & Time
                </th>
                <th className="px-4 py-2 text-left text-black bg-gray-100 border-b border-gray-200">
                  Description
                </th>
                <th className="px-4 py-2 text-left text-black bg-gray-100 border-b border-gray-200">
                  Debit (Out)
                </th>
                <th className="px-4 py-2 text-left text-black bg-gray-100 border-b border-gray-200">
                  Credit (In)
                </th>
                <th className="px-4 py-2 text-left text-black bg-gray-100 border-b border-gray-200">
                  Balance
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="6" className="text-center py-4">
                    Loading...
                  </td>
                </tr>
              ) : (
                getData?.map((transaction, index) => (
                  <tr key={index} className="border-b border-gray-200">
                    <td className="px-4 py-2">{index + 1}</td>
                    <td className="px-4 py-2">{transaction.time}</td>
                    <td className="px-4 py-2">{transaction.description}</td>
                    <td className="px-4 py-2">{transaction.debit || 0}</td>
                    <td className="px-4 py-2">{transaction.credit || 0}</td>
                    <td className="px-4 py-2">{transaction.total}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {/* Mobile version listing */}
        <div className="block md:hidden mt-8">
          {isLoading ? (
            <div className="text-center">Loading...</div>
          ) : (
            getData?.map((transaction, index) => (
              <div
                key={index}
                className="border p-4 mb-4 rounded-lg shadow-md"
              >
                <h2 className="font-bold">Transaction {index + 1}</h2>
                <p><strong>Date & Time:</strong> {transaction.time}</p>
                <p><strong>Description:</strong> {transaction.description}</p>
                <p><strong>Debit (Out):</strong> {transaction.debit || 0}</p>
                <p><strong>Credit (In):</strong> {transaction.credit || 0}</p>
                <p><strong>Balance:</strong> {transaction.total}</p>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ClubTransaction;
