export const selectSectionList = [
  { id: 1, name: "A1" },
  { id: 2, name: "A2" },
  { id: 3, name: "B1" },
  { id: 4, name: "B2" },
  { id: 5, name: "C1" },
  { id: 6, name: "C2" },
  { id: 7, name: "D1" },
  { id: 8, name: "D2" },
  { id: 9, name: "E1" },
  { id: 10, name: "E2" },
];
