import React, { useState } from "react";
import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import AddQuestionModal from "./AddQuestionModal";
import AddInningsModal from "./AddInningsModal";
import OverUnderModal from "./OverUnderModal";
import EveryOverUnderModal from "./EveryOverUnderModal";
import { useRemoveCloseTheMatchMutation } from "../../../../features/api/bettingmatchApi";
import { toast } from "react-toastify";
import axios from "axios";
import UpdateMatchModal from "./UpdateMatchModal";

const ActionModal = ({ data, closeModal, openModal }) => {
  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  const [openUpdateMatchModal, setOpenUpdateMatchModal] = useState(false);
  const handleUpdateMatchModal = () => {
    setOpenUpdateMatchModal(true);
  };
  const handleCloseUpdateMatchModal = () => setOpenUpdateMatchModal(false);

  const [openAddQuestionModal, setOpenAddQuestionModal] = useState(false);
  const handleAddQuestionModal = (data) => {
    setOpenAddQuestionModal(true);
    // closeModal()
  };
  const handleCloseAddQuestionModal = () => setOpenAddQuestionModal(false);

  const [removeCloseTheMatch] = useRemoveCloseTheMatchMutation();
  const handleCloseMatch = async (id) => {
    console.log(id);
    try {
      const response = await axios.put(
        `https://rakibvai.munihaelectronics.com/backend/public/api/closed-match/${id}`,
        null,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      console.log(response);
      if (!response?.error) {
        toast.success("Match has been closed successfully");
        closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!openModal) return null;
  return (
    <div
      id="ModalBackground"
      // onClick={ModalClose}
      className="fixed inset-0 bg-black bg-opacity-40 flex justify-center z-50"
    >
      <div className="w-11/12 sm:4/5 md:3/5 lg:w-1/5 h-[max-content] m-2 p-2 rounded bg-white text-black">
        <div className="flex justify-end">
          <p
            className="text-lg text-gray-500 cursor-pointer"
            onClick={closeModal}
          >
            <FaTimes />
          </p>
        </div>
        <div className="flex flex-col mt-4 gap-y-2">
          <button
            onClick={() => handleAddQuestionModal()}
            className="bg-white shadow-lg border rounded-sm py-3 text-emerald-600 font-bold"
          >
            Add Question
          </button>
          {data?.gameType == "2" && <AddInningsModal data={data} />}
          {data?.gameType == "2" && <OverUnderModal data={data} />}
          <button
            onClick={() => handleUpdateMatchModal()}
            className="bg-white shadow-lg border rounded-sm py-3 text-emerald-600 font-bold"
          >
            Update Match
          </button>
          {data?.gameType == "2" && <EveryOverUnderModal data={data} />}
          <button
            onClick={() => handleCloseMatch(Number(data?.id))}
            className="bg-white shadow-lg border rounded-sm py-3 text-emerald-600 font-bold"
          >
            Close The Match
          </button>
        </div>
      </div>

      <UpdateMatchModal
        openModal={openUpdateMatchModal}
        closeModal={handleCloseUpdateMatchModal}
        data={data}
      />

      <AddQuestionModal
        qiestionData={data}
        openModal={openAddQuestionModal}
        closeModal={handleCloseAddQuestionModal}
      />
    </div>
  );
};

export default ActionModal;
