import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import {
  useEditAnswerRateMutation,
  useEditOverUnderAnswerRateMutation,
} from "../../../../features/api/getanswerApi";
import { toast } from "react-toastify";

const TestingModal = ({ passData, status }) => {
  const [visible, setVisible] = useState(false);
  const [rate, setRate] = useState(""); // Initialize as an empty string
  const rateInputRef = useRef(null); // Create a ref for the rate input field

  const [answerRateEdit] = useEditAnswerRateMutation();
  const [editOverUnderAnswerRate] = useEditOverUnderAnswerRateMutation();

  useEffect(() => {
    if (visible) {
      rateInputRef.current?.focus();
    }
  }, [visible]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Convert rate to a number and validate
    const numericRate = parseFloat(rate);
    if (isNaN(numericRate)) {
      toast.error("Please enter a valid number.");
      return;
    }

    try {
      const sendData = {
        answerId: passData?.id,
        editAns: passData?.title,
        editAnsRate: numericRate, // Use the numeric rate
        questionIdForEditAns: passData?.bettingSubTitleId,
        matchidForEditAns: passData?.betting_titles_id,
      };

      let response;
      if (status === 1) {
        response = await answerRateEdit(sendData);
      } else {
        response = await editOverUnderAnswerRate(sendData);
      }

      if (response?.data) {
        toast.success("Success...!!");
        setVisible(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred.");
    }
  };

  return (
    <div className="card flex justify-content-center">
      <button
        onClick={() => setVisible(true)}
        className="p-2 text-xl mx-auto rounded-sm hover:bg-emerald-900 bg-emerald-700 text-white"
      >
        {passData?.amount}
      </button>
      <Dialog
        header="Edit Answer Rate"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{
          width: "20vw",
          backgroundColor: "white",
          color: "black",
          padding: "10px",
        }}
        breakpoints={{ "960px": "50vw", "641px": "80vw" }}
      >
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              paddingTop: "10px",
              marginTop: "10px",
              borderTop: "1px solid gray",
            }}
          >
            <input
              type="text"
              disabled
              value={passData?.title}
              style={{
                border: "1px solid gray",
                width: "100%",
                height: "30px",
                padding: "5px",
              }}
            />
            <input
              type="number"
              onChange={(e) => setRate(e.target.value)}
              value={rate} // Ensure the input reflects the state value
              ref={rateInputRef} // Attach the ref to the input field
              style={{
                border: "1px solid gray",
                width: "100%",
                height: "30px",
                padding: "5px",
              }}
              min="0" // Minimum value (optional)
              step="0.01" // Allow decimal values
            />
            <button
              style={{
                backgroundColor: "green",
                color: "white",
                padding: "6px 0",
                borderRadius: "3px",
              }}
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default TestingModal;
