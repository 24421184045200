import React, { useEffect } from "react";
import { FaUsersCog } from "react-icons/fa";
import AddNewAdminInPanel from "../../../components/ModalAdmin/AddNewAdminInPanel/AddNewAdminInPanel";
import { useState } from "react";
import BalanceUpdate from "../../../components/ModalAdmin/BalanceUpdate/BalanceUpdate";
import UpdateUserRules from "../../../components/ModalAdmin/UpdateUserRules/UpdateUserRules";
import TransferBalance from "../../../components/ModalAdmin/TransferBalance/TransferBalance";
import UpdateAdmin from "../../../components/ModalAdmin/UpdateAdmin/UpdateAdmin";
import { useGetRulesQuery } from "../../../features/api/rulesApi";
import { useGetUsersQuery } from "../../../features/api/usersApi";
import { useGetCompanyAccQuery} from "../../../features/api/bettingmatchApi";
import axios from "axios";

const Settings = () => {
  const [adminType, setAdminType] = useState("");

  const [openAddNewAdminInPanelModal, setOpenAddNewAdminInPanelModal] =
    useState(false);
  const handelOpenAddNewAdminInPanelModal = (Type) => {
    setOpenAddNewAdminInPanelModal(true);
    setAdminType(Type);
  };
  const handelCloseAddNewAdminInPanelModal = () =>
    setOpenAddNewAdminInPanelModal(false);

  const [openUpdateAdminModal, setOpenUpdateAdminModal] = useState(false);
  const handelOpenUpdateAdminModal = (Type) => {
    setOpenUpdateAdminModal(true);
  };
  const handelCloseUpdateAdminModal = () => setOpenUpdateAdminModal(false);

  // user rules
  const [openUpdateUserRulesModal, setOpenUpdateUserRulesModal] =
    useState(false);
  const [ruleTitle, setRuleTitle] = useState("");
  const handelOpenUpdateUserRulesModal = (rule) => {
    setOpenUpdateUserRulesModal(true);
    setRuleTitle(rule);
  };
  const handelCloseUpdateUserRulesModal = () =>
    setOpenUpdateUserRulesModal(false);

  const [openTransferBalanceModal, setOpenTransferBalanceModal] =
    useState(false);
  const handelOpenTransferBalanceModal = () =>
    setOpenTransferBalanceModal(true);
  const handelCloseTransferBalanceModal = () =>
    setOpenTransferBalanceModal(false);

  const [openBalanceUpdateModal, setOpenBalanceUpdateModal] = useState(false);
  const handelOpenBalanceUpdateModal = () => setOpenBalanceUpdateModal(true);
  const handelCloseBalanceUpdateModal = () => setOpenBalanceUpdateModal(false);

  // get user rules
  const rules = useGetRulesQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.rules;

  // console.log(rules)
  // Fetch company account capital
  const { data: companyData, error: companyError, isLoading: companyLoading } = useGetCompanyAccQuery();
  return (
    <div className="mt-16">
      <div className="bg-white text-black m-5 rounded">
        <div className="flex items-center gap-2 px-5 py-2 bg-gray-100 rounded-t">
          <FaUsersCog className="text-lg" />
          <h1 className="text-lg font-semibold">Settings</h1>
        </div>
        <hr />
        <div className="p-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
          {/* 2nd panel admin  */}
          <div className=" rounded ">
            <div className="border-b text-base font-semibold p-3 bg-gray-100 rounded-t">
              <h1>2nd Panel Admin</h1>
            </div>
            <div className="divide-y  border-x">
              <div className="flex items-center justify-between gap-2 p-3">
                <div className="flex flex-warp items-center gap-2">
                  <h1 className="text-base"> Rakib ( 00.00 )</h1>
                  <button className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded hover:bg-cyan-600">
                    Permission Setup
                  </button>
                </div>
                <button
                  className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded hover:bg-cyan-600"
                  onClick={() => handelOpenUpdateAdminModal()}
                >
                  update
                </button>
              </div>
              <div className="flex items-center justify-between gap-2 p-3">
                <div className="flex flex-warp items-center gap-2">
                  <h1 className="text-base"> Shown ( 00.00 )</h1>
                  <button className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded hover:bg-cyan-600">
                    Permission Setup
                  </button>
                </div>

                <button
                  className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded hover:bg-cyan-600"
                  onClick={() => handelOpenUpdateAdminModal()}
                >
                  update
                </button>
              </div>
            </div>

            <button
              className="mx-auto w-full bg-teal-600 text-white font-semibold p-2 rounded-b"
              onClick={() =>
                handelOpenAddNewAdminInPanelModal("2nd Panel Admin")
              }
            >
              Add New
            </button>
          </div>

          {/* 3rd panel admin  */}
          <div className=" rounded ">
            <div className="border-b text-base font-semibold p-3 bg-gray-100 rounded-t">
              <h1>3rd Panel Admin</h1>
            </div>
            <div className="divide-y  border-x">
              <div className="flex flex-warp items-center justify-between gap-2 p-3">
                <div className="flex items-center gap-2">
                  <h1 className="text-base"> Rakib ( 00.00 )</h1>
                  <button
                    className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded hover:bg-cyan-600"
                    onClick={() => handelOpenTransferBalanceModal()}
                  >
                    Transfer Balance
                  </button>
                </div>
                <button
                  className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded hover:bg-cyan-600"
                  onClick={() => handelOpenUpdateAdminModal()}
                >
                  update
                </button>
              </div>
              <div className="flex items-center justify-between gap-2 p-3">
                <div className="flex flex-warp items-center gap-2">
                  <h1 className="text-base"> Shown ( 00.00 )</h1>
                  <button
                    className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded hover:bg-cyan-600"
                    onClick={() => handelOpenTransferBalanceModal()}
                  >
                    Transfer Balance
                  </button>
                </div>

                <button
                  className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded hover:bg-cyan-600"
                  onClick={() => handelOpenUpdateAdminModal()}
                >
                  update
                </button>
              </div>
              <div className="flex items-center justify-between gap-2 p-3">
                <div className="flex flex-warp items-center gap-2">
                  <h1 className="text-base"> Rocky ( 00.00 )</h1>
                  <button
                    className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded hover:bg-cyan-600"
                    onClick={() => handelOpenTransferBalanceModal()}
                  >
                    Transfer Balance
                  </button>
                </div>

                <button
                  className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded hover:bg-cyan-600"
                  onClick={() => handelOpenUpdateAdminModal()}
                >
                  update
                </button>
              </div>
            </div>

            <button
              className="mx-auto w-full bg-teal-600 text-white font-semibold p-2 rounded-b"
              onClick={() =>
                handelOpenAddNewAdminInPanelModal("3rd Panel Admin")
              }
            >
              Add New
            </button>
          </div>

          {/* User Rules */}
          <div className=" rounded ">
            <div className="border-b text-base font-semibold p-3 bg-gray-100 rounded-t">
              <h1>User Rules</h1>
            </div>
            <div>
              {rules?.map((rule, i) => (
                <div className="divide-y  border-x border-b rounded-b" key={i}>
                  <div className="flex items-center justify-between gap-2 p-3">
                    <h1 className="text-base">
                      Minimum balance
                      <span className="px-1 text-white text-xs font-semibold bg-cyan-500 rounded mx-1">
                        {rule.minimumBalance} Tk
                      </span>
                    </h1>
                    <button
                      className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded hover:bg-cyan-600"
                      onClick={() =>
                        handelOpenUpdateUserRulesModal("minimum balance")
                      }
                    >
                      update
                    </button>
                  </div>
                  <div className="flex items-center justify-between gap-2 p-3">
                    <h1 className="text-base">
                      Waiting time after win
                      <span className="px-1 text-white text-xs font-semibold bg-cyan-500 rounded mx-1">
                        {rule.waitingTime} min
                      </span>
                    </h1>
                    <button
                      className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded hover:bg-cyan-600"
                      onClick={() =>
                        handelOpenUpdateUserRulesModal("waiting time")
                      }
                    >
                      update
                    </button>
                  </div>
                  <div className="flex items-center justify-between gap-2 p-3">
                    <h1 className="text-base">
                      Waiting time after deposit
                      <span className="px-1 text-white text-xs font-semibold bg-cyan-500 rounded mx-1">
                        {rule.waitingTimeAfterDeposit} min
                      </span>
                    </h1>
                    <button
                      className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded hover:bg-cyan-600"
                      onClick={() =>
                        handelOpenUpdateUserRulesModal(
                          "waiting time after deposit"
                        )
                      }
                    >
                      update
                    </button>
                  </div>
                  <div className="flex items-center justify-between gap-2 p-3">
                    <h1 className="text-base">
                      Waiting time after first withdraw
                      <span className="px-1 text-white text-xs font-semibold bg-cyan-500 rounded mx-1">
                        {rule.waitingTimeAfterWithdraw} min
                      </span>
                    </h1>
                    <button
                      className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded hover:bg-cyan-600"
                      onClick={() =>
                        handelOpenUpdateUserRulesModal(
                          "waiting time after first withdraw"
                        )
                      }
                    >
                      update
                    </button>
                  </div>
                  <div className="flex items-center justify-between gap-2 p-3">
                    <h1 className="text-base">
                      All club Commission
                      <span className="px-1 text-white text-xs font-semibold bg-cyan-500 rounded mx-1">
                        {rule.clubCommission} Tk
                      </span>
                    </h1>
                    <button
                      className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded hover:bg-cyan-600"
                      onClick={() =>
                        handelOpenUpdateUserRulesModal("club commission")
                      }
                    >
                      update
                    </button>
                  </div>
                  <div className="flex items-center justify-between gap-2 p-3">
                    <h1 className="text-base">
                      All user sponsor
                      <span className="px-1 text-white text-xs font-semibold bg-cyan-500 rounded mx-1">
                        {rule.userSponsor} Tk
                      </span>
                    </h1>
                    <button
                      className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded hover:bg-cyan-600"
                      onClick={() =>
                        handelOpenUpdateUserRulesModal(`user sponsor`)
                      }
                    >
                      update
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Balance  */}
          <div className=" rounded ">
            <div className="border-b text-base font-semibold p-3 bg-gray-100 rounded-t">
              <h1>Balance</h1>
            </div>
            <div className="divide-y  border-x">
              <h1 className="text-base text-center font-semibold py-2">
                {" "}
                {companyData?.company_accounts?.capital}{" "}
              </h1>
            </div>

            <button
              className="mx-auto w-full bg-teal-600 text-white font-semibold p-2 rounded-b"
              onClick={() => handelOpenBalanceUpdateModal()}
            >
              Balance Update
            </button>
          </div>
        </div>
      </div>
      <AddNewAdminInPanel
        adminType={adminType}
        openModal={openAddNewAdminInPanelModal}
        closeModal={handelCloseAddNewAdminInPanelModal}
      />
      <UpdateAdmin
        openModal={openUpdateAdminModal}
        closeModal={handelCloseUpdateAdminModal}
      />
      <UpdateUserRules
        rule={ruleTitle}
        openModal={openUpdateUserRulesModal}
        closeModal={handelCloseUpdateUserRulesModal}
      />
      <TransferBalance
        openModal={openTransferBalanceModal}
        closeModal={handelCloseTransferBalanceModal}
      />
      <BalanceUpdate
        openModal={openBalanceUpdateModal}
        closeModal={handelCloseBalanceUpdateModal}
        data = {companyData}
      />
    </div>
  );
};

export default Settings;
