import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaAngleDown, FaList } from "react-icons/fa";

import Select from "react-select";
import { useGetUsersQuery } from "../../../../features/api/usersApi";
import { useGetPaymentMethodQuery } from "../../../../features/api/paymentmethodApi";
import { toast } from "react-toastify";
import axios from "axios";
import { formatDateTime } from "../../../../utils/formatDateTime";
import { useGetAlldwnotificationsQuery } from "../../../../features/api/usersApi";

const UserWithdrawInbox = () => {
  const [searchText1, setSearchText1] = useState("");
  const [searchText2, setSearchText2] = useState("");

  const accessToken = localStorage.getItem("accessToken");

  const users = useGetUsersQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.users;
 
  const PaymentMethods = useGetPaymentMethodQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.payment_methods;

  const filterWithdrawForPaymentMethods = PaymentMethods?.filter(
    (wd) => (wd?.type === "wd"));

  const { data = [], isLoading } = useGetAlldwnotificationsQuery(null, {
      refetchOnMountOrArgChange: true,
  });
  const withdrawHistory = data?.dwnotifications;
  // console.log(withdrawHistory);

  const filterWithdrawForZeroOne = withdrawHistory?.filter(
    (withdrawReq) => (withdrawReq?.withdraw != null && withdrawReq?.action == 0) || withdrawReq?.withdraw != null && withdrawReq?.action == 1
  );
  
  const filterWithdrawForTwo = withdrawHistory?.filter(
    (withdrawReq) => withdrawReq?.withdraw !== null && withdrawReq?.action == 2
  );
  
  const handleWithdrawAction = async (e, id) => {
    e.preventDefault();
    const status = +e.target.value;
    const confirm = window.confirm("Do you change the action?");
    if (confirm) {
      try {
        const response = await axios.post(
          `https://rakibvai.munihaelectronics.com/backend/public/api/withdraw-action/${id}/${status}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        console.log(response);
        if (response) {
          setTimeout(() => {
            toast.success("Successfully change the action...!");
            window.location.reload();
          }, 0.5);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const columns = [
    {
      name: "SN",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "User Id",
      selector: (row) => row.userId,
      sortable: true,
    },
    {
      name: "User Balance",
      selector: (row) => {
        const getUserBalance = users?.find((u) => u?.userId == row?.userId);
        return <div>{getUserBalance?.balance}</div>;
      },
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.withdraw,
      sortable: true,
    },
    {
      name: "To",
      selector: (row) => row.to_number,
      sortable: true,
    },
    {
      name: "Method",
      selector: (row) => {
        const name = filterWithdrawForPaymentMethods?.find(
          (m) => m?.id == row?.payment_method
        );
        return <p>{name?.name}</p>;
      },
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => {
        return <div>{formatDateTime(row.created_at)}</div>;
      },
      sortable: true,
      width: "200px",
    },
    {
      name: "Go to User Account",
      selector: (row) => (
        <button className="px-3 py-2 bg-cyan-600 hover:bg-green-600 hover:text-sm text-white font-semibold rounded">
          visit
        </button>
      ),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <select
            className="p-2 bg-cyan-600 text-white font-semibold rounded"
            value={row?.action} // Set the value to the current action
            onChange={(e) => handleWithdrawAction(e, row?.id)}
          >
            <option value={0}>Requested</option>
            <option value={1}>Processing</option>
            <option value={2}>Processed</option>
            <option value={3}>Cancel</option>
          </select>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
  ];
  const columns2 = [
    {
      name: "SN",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "User Id",
      selector: (row) => row.userId,
      sortable: true,
    },
    {
      name: "User Balance",
      selector: (row) => {
        const getUserBalance = users?.find((u) => u?.userId == row?.userId);
        return <div>{getUserBalance?.balance}</div>;
      },
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.withdraw,
      sortable: true,
    },
    {
      name: "To",
      selector: (row) => row.to_number,
      sortable: true,
    },
    {
      name: "Method",
      selector: (row) => {
        const name = filterWithdrawForPaymentMethods?.find(
          (m) => m?.id == row?.payment_method
        );
        return <p>{name?.name}</p>;
      },
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => {
        return <div>{formatDateTime(row.created_at)}</div>;
      },
      sortable: true,
      width: "200px",
    },
  ];

  const customStyles = {
    // table: {
    //     style: {
    //         color: 'black',
    //         border: '1px solid gray'

    //     },
    // },
    headRow: {
      style: {
        color: "white",
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: "teal",
      },
    },
    headCells: {
      style: {
        width: "auto",
        wordBreak: "break-all",
      },
    },
  };
  const customFilter = (rows, searchText) => {
    if (!Array.isArray(rows)) {
        return []; // Return an empty array if rows is not an array
    }

    return rows.filter(row => {
        return Object.values(row).some(value =>
            String(value).toLowerCase().includes(searchText.toLowerCase())
        );
    });
};

// Check if data is loading or not available
if (isLoading) {
    return <div>Loading...</div>;
}

  return (
    <div className="mt-10">
      <div className="bg-white text-black p-5 flex gap-2 ">
        <FaList className="text-xl mt-2"></FaList>
        <h1 className="text-2xl font-semibold"> User withdraw inbox </h1>
      </div>
      <div className="m-5 bg-white rounded ">
        <div className="flex items-center px-5 py-4 ">
          <label
            htmlFor="search"
            className="text-black text-sm font-semibold p-1"
          >
            Search:
          </label>
          <input
            type="text"
            id="search"
            placeholder="search"
            className=" text-black text-sm border-2 px-2 py-1 rounded focus:outline-teal-600"
            value={searchText1}
            onChange={(e) => setSearchText1(e.target.value)}
          />
        </div>
        <div className="px-5">
          <DataTable
            columns={columns}
            data={customFilter(filterWithdrawForZeroOne, searchText1)}
            customStyles={customStyles}
            sortIcon={<FaAngleDown className="m-1" />}
            defaultSortAsc={true}
            paginationRowsPerPageOptions={[50, 100, 150]}
            paginationPerPage={100}
            pagination
            highlightOnHover
          />
        </div>
      </div>
      <div className="m-5 mt-10 bg-white rounded ">
        <div className="flex items-center px-5 py-4 ">
          <label
            htmlFor="search"
            className="text-black text-sm font-semibold p-1"
          >
            Search:
          </label>
          <input
            type="text"
            id="search"
            placeholder="search"
            className=" text-black text-sm border-2 px-2 py-1 rounded focus:outline-teal-600"
            value={searchText2}
            onChange={(e) => setSearchText2(e.target.value)}
          />
        </div>
        <div className="px-5">
          <DataTable
            columns={columns2}
            data={customFilter(filterWithdrawForTwo, searchText2)}
            customStyles={customStyles}
            sortIcon={<FaAngleDown className="m-1" />}
            defaultSortAsc={true}
            paginationRowsPerPageOptions={[50, 100, 150]}
            paginationPerPage={100}
            pagination
            highlightOnHover
          />
        </div>
      </div>
    </div>
  );
};

export default UserWithdrawInbox;
