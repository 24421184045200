import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useAddClubWithdrawMutation } from "../../../features/api/mybetsApi";
import { toast } from "react-toastify";

const ClubWithdrawModal = ({ closeModal, openModal }) => {
  const [amount, setAmount] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [addClubWithdraw] = useAddClubWithdrawMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        wAmount: amount,
        password: password,
      };
      const response = await addClubWithdraw(data);
      if (response?.data) {
        closeModal();
        setAmount("");
        setPassword("");
        toast.success("Withdrawal request sent successfully");
      } else if (response?.error) {
        setError(
          response?.error?.data?.error || response?.error?.data?.message
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  const ModalClose = (e) => {
    if (e.target.id === "ModalBackground") closeModal();
  };

  if (!openModal) return null;
  return (
    <div
      id="ModalBackground"
      onClick={ModalClose}
      className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50"
    >
      <div className="w-11/12 sm:4/5 md:3/5 lg:w-2/5 p-5 rounded bg-[#084841]">
        <div className="flex items-center justify-between text-white">
          <h1 className="text-xl font-bold">
            {" "}
            <span className="pr-3 text-green-300">|</span>Make Withdraw
          </h1>
          <p
            className="text-lg text-gray-400 cursor-pointer"
            onClick={closeModal}
          >
            <i className="fa-solid fa-xmark "></i>
          </p>
        </div>
        <div className="">
          <form onSubmit={handleSubmit} className="mt-5 ">
            <hr className="my-2 text-gray-500" />
            {error && <p className="text-red-500 text-center text-lg">{error}</p>}
            <div className="lg:flex gap-3">
              <div className="flex flex-col w-full my-4 ">
                <label htmlFor="amount" className="text-sm font-bold mb-3 ">
                  AMOUNT <span className="text-red-400">*</span>
                </label>
                <input
                  type="number"
                  id="amount"
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Amount"
                  className="p-2 rounded text-sm text-white bg-[#3c857e] focus:bg-transparent focus:outline-gray-200"
                />
              </div>
              <div className="relative flex flex-col w-full my-4 ">
                <label htmlFor="Password" className="text-sm font-bold mb-3">
                  PASSWORD <span className="text-red-400">*</span>
                </label>
                <div className="relative " id="Password">
                  <input
                    type={showPassword ? "text" : "password"}
                    autoComplete={showPassword ? "on" : "new-password"}
                    placeholder="Password"
                    className="p-2 rounded w-full text-sm text-white bg-[#3c857e] focus:bg-transparent focus:outline-gray-200 "
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div
                    className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 text-base cursor-pointer text-gray-200"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <i className="fa-solid fa-eye-slash" />
                    ) : (
                      <i className="fa-solid fa-eye" />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <hr className="my-2 text-gray-500" />
            <button
              type="submit"
              className="bg-[#7934f3] w-full text-white font-semibold p-2 rounded mt-3"
            >
              SUBMIT
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClubWithdrawModal;
