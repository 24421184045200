import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";

import { MdSportsCricket } from "react-icons/md";
import { FaGlobeAmericas, FaRegFutbol, FaTableTennis } from "react-icons/fa";
import Cricket from "./Cricket/Cricket";
import AllGame from "./AllGame/AllGame";
import TableTennis from "./TableTennis/TableTennis";
import Football from "./Football/Football";
import MyBet from "../../components/MyBet/MyBet";
import {
  useGetCombinedMatchesQuery,
  useGetNoticeQuery,
} from "../../features/api/bettingmatchApi";

const Home = () => {
  const { data } = useGetNoticeQuery("", {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const notice = data?.notice?.notice;
  const [isClickedAllMatch, setIsClickedAllMatch] = useState(true);
  const [isClickedCricket, setIsClickedCricket] = useState(false);
  const [isClickedFootball, setIsClickedFootball] = useState(false);
  const [isClickedTableTennis, setIsClickedTableTennis] = useState(false);

  const handleClick = (type) => {
    if (type === "All") {
      setIsClickedAllMatch(true);
      setIsClickedCricket(false);
      setIsClickedFootball(false);
      setIsClickedTableTennis(false);
    } else if (type === "Cricket") {
      setIsClickedAllMatch(false);
      setIsClickedCricket(true);
      setIsClickedFootball(false);
      setIsClickedTableTennis(false);
    } else if (type === "Football") {
      setIsClickedAllMatch(false);
      setIsClickedCricket(false);
      setIsClickedFootball(true);
      setIsClickedTableTennis(false);
    } else if (type === "TableTennis") {
      setIsClickedAllMatch(false);
      setIsClickedCricket(false);
      setIsClickedFootball(false);
      setIsClickedTableTennis(true);
    }
  };

  const {
    data: combined,
    isLoading: combinedLoading,
    refetch,
  } = useGetCombinedMatchesQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const [liveMatches, setLiveMatches] = useState([]);
  const totalCricketLive = liveMatches?.filter(
    (d) => d.match?.gameType == 2
  )?.length;
  const totalFootballLive = liveMatches?.filter(
    (d) => d.match?.gameType == 1
  )?.length;
  const totalTennisLive = liveMatches?.filter(
    (d) => d.match?.gameType == 3
  )?.length;
  const [upComingMatches, setUpComingMatches] = useState([]);
  const totalCricketUp = upComingMatches?.filter(
    (d) => d.match?.gameType == 2
  )?.length;
  const totalFootballUp = upComingMatches?.filter(
    (d) => d.match?.gameType == 1
  )?.length;
  const totalTennisUp = upComingMatches?.filter(
    (d) => d.match?.gameType == 3
  )?.length;
  const totalCricket = totalCricketLive + totalCricketUp;
  const totalFootball = totalFootballLive + totalFootballUp;
  const totalTennis = totalTennisLive + totalTennisUp;

  useEffect(() => {
    if (!combinedLoading && combined) {
      setLiveMatches(combined.live_matches);
      setUpComingMatches(combined.upcoming_matches);
    }
  }, [combined, combinedLoading]);

  // Function to filter users and process them
  const processUsers = () => {
    // Filter users based on generator_id (2 and 7)
    const filteredUsers = users.filter(
      (user) => user.generator_id === 2 || user.generator_id === 7
    );

    // Check if the filtered list has 10 or more elements
    if (filteredUsers.length >= 10) {
      const get10users = filteredUsers.slice(0, 10);
      // Check if all users in the filtered list have 1500 points
      const allHave1500Points = get10users.every(
        (user) => user.points === 1500
      );

      if (allHave1500Points) {
        // Create a new array with unique user_id, generator_id, and points incremented by 160
        const newArray = filteredUsers.reduce((acc, user) => {
          // Check if user_id is not already in the array
          if (!acc[user.user_id]) {
            acc[user.user_id] = {
              userID: user.generator_id,
              points: 160,
            };
          }
          return acc;
        }, {});

        // You can now use newArray for further processing or display
      } else {
        console.log("Not all users in the filtered list have 1500 points.");
      }
    } else {
      console.log("Filtered list has less than 10 elements.");
    }
  };

  // Call the function to process users
  processUsers();

  return (
    <div className="mx-auto max-w-5xl lg:px-0">
      <div className="my-2 px-5 lg:px-0">
        <Marquee className="mx-auto max-w-3xl text-sm text-[#ffff00] z-1">
          <span dangerouslySetInnerHTML={{ __html: notice }} />
        </Marquee>
      </div>

      <div className="">
        <div className="flex items-center gap-1 px-5 lg:px-0 lg:gap-2">
          <div
            className={
              isClickedAllMatch
                ? " p-1 text-[#8fb568] border-[#8fb568] border-b-2"
                : " p-1"
            }
            onClick={() => handleClick("All")}
          >
            <h1 className="flex items-center gap-1 ">
              <FaGlobeAmericas />{" "}
              <span className="text-xs font-semibold">
                All {liveMatches?.length + upComingMatches?.length}
              </span>
            </h1>
          </div>
          <div
            className={
              isClickedCricket
                ? " p-1 text-[#8fb568] border-[#8fb568] border-b-2"
                : " p-1"
            }
            onClick={() => handleClick("Cricket")}
          >
            <h1 className="flex items-center gap-1 ">
              <MdSportsCricket className="" />{" "}
              <span className="text-xs font-semibold">
                Cricket {totalCricket}
              </span>
            </h1>
          </div>
          <div
            className={
              isClickedFootball
                ? " p-1 text-[#8fb568] border-[#8fb568] border-b-2"
                : "  p-1"
            }
            onClick={() => handleClick("Football")}
          >
            <h1 className="flex items-center gap-1 ">
              <FaRegFutbol />{" "}
              <span className="text-xs font-semibold">
                Football {totalFootball}
              </span>
            </h1>
          </div>
          <div
            className={
              isClickedTableTennis
                ? " p-1 text-[#8fb568] border-[#8fb568] border-b-2"
                : " p-1"
            }
            onClick={() => handleClick("TableTennis")}
          >
            <h1 className="flex items-center gap-1 ">
              <FaTableTennis />{" "}
              <span className=" text-xs font-semibold">
                Table Tennis {totalTennis}
              </span>
            </h1>
          </div>
        </div>

        <div className=" my-3">
          <div className={isClickedAllMatch ? "block" : "hidden"}>
            <AllGame />
          </div>

          <div className={isClickedCricket ? "block" : "hidden"}>
            <Cricket />
          </div>
          <div className={isClickedFootball ? "block" : "hidden"}>
            <Football />
          </div>
          <div className={isClickedTableTennis ? "block" : "hidden"}>
            <TableTennis />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

const users = [
  { user_id: 1, generator_id: 0, points: 1500 },
  { user_id: 2, generator_id: 0, points: 1500 },
  { user_id: 3, generator_id: 1, points: 1500 },
  { user_id: 4, generator_id: 1, points: 1200 },
  { user_id: 5, generator_id: 1, points: 1000 },
  { user_id: 6, generator_id: 0, points: 800 },
  { user_id: 7, generator_id: 0, points: 1200 },
  { user_id: 8, generator_id: 0, points: 700 },
  { user_id: 9, generator_id: 0, points: 1300 },
  { user_id: 10, generator_id: 0, points: 600 },
  { user_id: 11, generator_id: 2, points: 1500 },
  { user_id: 12, generator_id: 2, points: 1500 },
  { user_id: 13, generator_id: 2, points: 1500 },
  { user_id: 14, generator_id: 2, points: 1500 },
  { user_id: 15, generator_id: 2, points: 1500 },
  { user_id: 16, generator_id: 2, points: 1500 },
  { user_id: 17, generator_id: 2, points: 1500 },
  { user_id: 18, generator_id: 2, points: 1500 },
  { user_id: 19, generator_id: 2, points: 1500 },
  { user_id: 20, generator_id: 2, points: 1500 },
  { user_id: 21, generator_id: 0, points: 1100 },
  { user_id: 22, generator_id: 0, points: 900 },
  { user_id: 23, generator_id: 0, points: 1300 },
  { user_id: 24, generator_id: 0, points: 1000 },
  { user_id: 25, generator_id: 0, points: 1500 },
  { user_id: 26, generator_id: 7, points: 1500 },
  { user_id: 27, generator_id: 7, points: 1500 },
  { user_id: 28, generator_id: 7, points: 1500 },
  { user_id: 29, generator_id: 7, points: 1500 },
  { user_id: 30, generator_id: 7, points: 1500 },
  { user_id: 31, generator_id: 7, points: 1500 },
  { user_id: 32, generator_id: 7, points: 1500 },
  { user_id: 33, generator_id: 7, points: 1500 },
  { user_id: 34, generator_id: 7, points: 1500 },
  { user_id: 35, generator_id: 7, points: 1500 },
  { user_id: 36, generator_id: 2, points: 1200 },
  { user_id: 37, generator_id: 2, points: 1500 },
  { user_id: 38, generator_id: 2, points: 1500 },
  { user_id: 39, generator_id: 2, points: 600 },
  { user_id: 40, generator_id: 2, points: 1500 },
  { user_id: 41, generator_id: 10, points: 1500 },
  { user_id: 42, generator_id: 10, points: 1500 },
  { user_id: 43, generator_id: 10, points: 1500 },
  { user_id: 44, generator_id: 10, points: 500 },
  { user_id: 45, generator_id: 10, points: 1500 },
  { user_id: 46, generator_id: 10, points: 1500 },
  { user_id: 47, generator_id: 10, points: 800 },
  { user_id: 48, generator_id: 10, points: 1500 },
  { user_id: 49, generator_id: 10, points: 1400 },
  { user_id: 50, generator_id: 10, points: 1500 },
];
