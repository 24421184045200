import React, { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

const UserToUserBalanceTransferForm = ({ closeForm, openForm }) => {
    useEffect(() => {
        if (openForm) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [openForm]);

    const FormClose = (e) => {
        if (e.target.id === 'FormBackground')
            closeForm()
    }

    if (!openForm) return null;

    return (
        <div
            id='FormBackground'
            onClick={FormClose}
            className='fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50'
        >
            <div
                className='w-11/12 sm:4/5 md:3/5 lg:w-2/5 p-5 rounded bg-white text-black'
            >
                <div className='flex items-center justify-between '>
                    <h1 className='text-xl font-bold'> <span className='pr-3 text-green-400'>|</span>User To User Balance Transfer</h1>
                    <p className='text-lg text-gray-500' onClick={closeForm}><FaTimes /></p>
                </div>
                <div className='' >
                    <form action="" className='mt-5'>
                        <div className="flex items-center justify-between w-full my-4 ">
                            <label
                                htmlFor="ToUserId"
                                className="text-sm font-bold "
                            >
                                To User Id <span className="text-red-400">*</span>
                            </label>
                            <input
                                type="text"
                                id="ToUserId"
                                placeholder="Enter User Id"
                                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
                            />
                        </div>
                        <div className="flex items-center justify-between w-full my-4 ">
                            <label
                                htmlFor="FromUserId"
                                className="text-sm font-bold "
                            >
                                From User Id <span className="text-red-400">*</span>
                            </label>
                            <input
                                type="text"
                                id="FromUserId"
                                placeholder="Enter User Id"
                                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
                            />
                        </div>

                        <div className="flex items-center justify-between w-full my-4 ">
                            <label
                                htmlFor="Notes"
                                className="lg:text-sm font-bold "
                            >
                                Notes
                            </label>
                            <textarea
                                type="text"
                                id="Notes"
                                placeholder="Enter Notes"
                                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
                            />
                        </div>
                        <div className="flex items-center justify-between w-full my-4 ">
                            <label
                                htmlFor="Amount"
                                className="lg:text-sm font-bold "
                            >
                                Amount <span className="text-red-400">*</span>
                            </label>
                            <input
                                type="text"
                                id="Amount"
                                placeholder="Enter Amount"
                                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
                            />
                        </div>
                        <button type='submit' className='bg-green-800 text-white text-sm w-full font-bold p-2 mt-3 rounded'>Submit</button>
                    </form>
                </div >
            </div >
        </div >
    );
};

export default UserToUserBalanceTransferForm;