import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";
import bkashLogo from "../../../Assets/Logo/bkash-logo.png";
import nagadLogo from "../../../Assets/Logo/nagad-logo.png";
import { toast } from "react-toastify";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  useAddWithdrawMutation,
  useGetUserQuery,
} from "../../../features/api/loginApi";
import { useGetPaymentMethodQuery } from "../../../features/api/paymentmethodApi";

const Withdraw = ({ closeModal, openModal }) => {
  const [withdrawMethod, setWithdrawMethod] = useState([]);
  const [activeMethod, setActiveMethod] = useState(null);
  const [activeSaveNumber, setActiveSaveNumber] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    fetch(
      "https://rakibvai.munihaelectronics.com/backend/public/api/get-deposit-receiving-numbers-list"
    )
      .then((res) => res.json())
      .then((data) => setWithdrawMethod(data?.deposit_receiving_numbers));
  }, []);

  const depositPaymentMethods = useGetPaymentMethodQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.payment_methods?.filter((tp) => tp?.type == "wd");

  const accessToken = localStorage.getItem("accessToken");
  const user = useGetUserQuery(accessToken, {
    skip: !accessToken,
    refetchOnMountOrArgChange: true,
  })?.data?.user;

  const [saveWithdrawNum, setSaveWithdrawNUm] = useState("");

  useEffect(() => {
    fetch(
      "https://rakibvai.munihaelectronics.com/backend/public/api/getWithdrawSavedNumbers",
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setSaveWithdrawNUm(data?.data));
  }, []);

  const handleWithdrawMethod = (name, data) => {
    setActiveMethod(data);
  };
  const handleActiveSaveNumber = (method, matched, i) => {
    setActiveMethod(method);
    setActiveSaveNumber(matched);
    setActiveIndex(i);
  };

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [inputAmount, setInputAmount] = useState("");
  const [inputNumber, setInputNumber] = useState("");
  const [errors, setErrors] = useState(null);

  const [addNewWithdraw] = useAddWithdrawMutation();
  
  // Validation function
  const validateInput = () => {
    let validationErrors = [];
    if (inputAmount.length === 0) {
      validationErrors.push("Amount is required.");
    } else if (inputAmount.length > 5) {
      validationErrors.push("Amount cannot exceed 5 digits.");
    }
    
    if (!inputNumber && !activeSaveNumber) {
      validationErrors.push("To Number is required.");
    } else if (inputNumber.length > 11) {
      validationErrors.push("To Number cannot exceed 11 digits.");
    }

    if (validationErrors.length > 0) {
      setErrors(validationErrors.join(" "));
      return false;
    }
    setErrors(null);
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateInput()) return;

    const saveNew = {
      withdraw: inputAmount,
      to_number: inputNumber ? inputNumber : activeSaveNumber?.number,
      payment_method_id: activeMethod?.id,
    };

    try {
      const response = await addNewWithdraw(saveNew);

      if (response?.data) {
        setInputNumber("");
        toast.success(response?.data?.message);
        closeModal();
      } else if (response?.error) {
        toast.error(response?.error?.data?.message);
      }
    } catch (error) {
      setErrors(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  const modalClose = (e) => {
    if (e.target.id === "modalBackground") closeModal();
  };

  if (!openModal) return null;

  return (
    <div
      id="modalBackground"
      onClick={modalClose}
      className="fixed inset-0 bg-black bg-opacity-40 snap-alw flex justify-center items-center"
    >
      <div
        className="w-11/12 sm:4/5 md:3/5 lg:w-2/5 p-5 rounded"
        style={{ background: "linear-gradient(360deg, #37103a, #08545B)" }}
      >
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">
            {" "}
            <span className="pr-3 text-green-400">|</span>Request A Withdraw
          </h1>
          <p className="text-lg text-gray-500" onClick={closeModal}>
            <FaTimes />
          </p>
        </div>
        <div>
          <form onSubmit={handleSubmit} className="mt-5">
            {errors && <p className="text-red-500 text-sm py-1">{errors}</p>}
            <div className="w-full my-2  ">
              <label for="" className="font-semibold">
                {" "}
                Method <span className="text-red-400">*</span>
              </label>
              <div className="flex items-center gap-x-4">
                {depositPaymentMethods?.map((data, i) => {
                  const findSaveMethod =
                    saveWithdrawNum &&
                    saveWithdrawNum?.find(
                      (d) => d?.payment_method_id === data?.method_id
                    );
                  const matched =
                    saveWithdrawNum &&
                    saveWithdrawNum?.find(
                      (d) => d?.payment_method_id == data?.id
                    );
                  return (
                    <div key={data?.id} className="">
                      <div className="flex  gap-2 w-full my-2">
                        <img
                          src={`https://rakibvai.munihaelectronics.com/backend/storage/app/public/${data?.img}`}
                          alt="Nagad Logo"
                          className={`w-24 h-12  p-2 rounded ${
                            activeIndex == i
                              ? " bg-white"
                              : "bg-gray-600 opacity-60"
                          }`}
                          onClick={() =>
                            handleActiveSaveNumber(data, matched, i)
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="w-full my-2">
              <label for="Amount" className="font-semibold">
                {" "}
                Amount <span className="text-red-400">*</span>
              </label>
              <input
                type="number"
                id="Amount"
                placeholder="Amount"
                required
                onChange={(e) => setInputAmount(e.target.value)}
                className="p-2 rounded w-full text-sm text-white bg-gray-950"
              />
            </div>
            <div className="w-full my-2">
              <label for="ToNumber" className="font-semibold">
                {" "}
                To Number <span className="text-red-400">*</span>
              </label>
              {saveWithdrawNum?.length > 0 ? (
                <div>
                  <input
                    type="number"
                    id="ToNumber"
                    onChange={(e) => setInputNumber(e.target.value)}
                    value={
                      activeSaveNumber?.payment_method_id == activeMethod?.id
                        ? activeSaveNumber?.number
                        : inputNumber
                    }
                    disabled={
                      activeSaveNumber?.payment_method_id == activeMethod?.id
                    }
                    required
                    className={`p-2 rounded ${
                      activeSaveNumber?.payment_method_id == activeMethod?.id &&
                      "cursor-not-allowed"
                    } w-full text-sm text-white bg-gray-950`}
                  />
                </div>
              ) : (
                <input
                  type="number"
                  id="ToNumber"
                  placeholder="Enter the number"
                  onChange={(e) => setInputNumber(e.target.value)}
                  value={inputNumber}
                  required
                  className="p-2 rounded w-full text-sm text-white bg-gray-950"
                />
              )}
            </div>
            <div className="relative w-full my-2">
              <label htmlFor="Password" className="text-sm font-semibold">
                Password <span className="text-red-400">*</span>
              </label>
              <div className="relative" id="Password">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="p-2 rounded w-full text-sm text-white bg-gray-950 "
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div
                  className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 text-white text-xl cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="bg-green-800 text-sm w-full font-bold p-3 mt-3 rounded"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
