import React, { useEffect, useRef, useState } from 'react';

const DateTime = () => {
    const newDate = useRef(new Date());

    const [currentHours, setCurrentHours] = useState(newDate.current.getHours());
    const [currentMinutes, setCurrentMinutes] = useState(newDate.current.getMinutes());
    const [currentSeconds, setCurrentSeconds] = useState(newDate.current.getSeconds());
    const [amPm, setAmPm] = useState('AM'); // Initialize with AM

    useEffect(() => {
        const interval = setInterval(() => {
            newDate.current = new Date();
            const hours = newDate.current.getHours();
            const minutes = newDate.current.getMinutes();
            const seconds = newDate.current.getSeconds();

            // Determine AM or PM
            const newAmPm = hours >= 12 ? 'PM' : 'AM';

            // Convert hours to 12-hour format
            const formattedHours = hours % 12 || 12;

            setCurrentHours(formattedHours);
            setCurrentMinutes(minutes);
            setCurrentSeconds(seconds);
            setAmPm(newAmPm);
        }, 1000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(interval);
    }, []);

    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];

    const nowDate = `${newDate.current.getDate()} ${monthNames[newDate.current.getMonth()]} ${newDate.current.getFullYear()}`;

    const nowTime = `${currentHours}:${String(currentMinutes).padStart(2, '0')}:${String(currentSeconds).padStart(2, '0')} ${amPm}`;
    return (
        <div className='py-2'>
            {nowDate} - {nowTime}
        </div>
    );
};

export default DateTime;