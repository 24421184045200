import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link } from "react-router-dom";

const UserNotificationsPopUp = ({ closePopUp, openPopUp, depoData }) => {
  const PopUpClose = (e) => {
    if (e.target.id === "PopUpBackground") closePopUp();
  };

  if (!openPopUp) return null;
  return (
    <div
      id="PopUpBackground"
      onClick={PopUpClose}
      className="fixed inset-0 z-50 "
    >
      <div className=" relative">
        <div className="absolute top-11 right-0 mr-5 text-black bg-white drop-shadow-2xl">
          <div className="text-center text-sm">
            <div>
              <p className="bg-sky-500 py-2 px-8">You have new notification</p>
              <div>
                {depoData?.map((d, i) => (
                  <Link
                    onClick={() => closePopUp()}
                    to="/admin/user-deposit-inbox"
                    className="bg-gray-200 py-2 flex items-center justify-center gap-x-2 cursor-pointer"
                    key={i}
                  >
                    <p>
                      <MailOutlineIcon />
                    </p>
                    <p>{d?.userId} sent you a deposit</p>
                  </Link>
                ))}
              </div>
              <p className="bg-gray-300 py-2 text-blue-500 font-semibold cursor-pointer">
                <Link
                  onClick={() => closePopUp()}
                  to="/admin/user-deposit-inbox"
                >
                  See all notification
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserNotificationsPopUp;
