import { apiSlice } from "./apiSlice";

const clubdashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllMyMembers: builder.query({
      query: () => ({
        url: "/myMembers",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["myMembers"],
    }),
    getAgentDashboardAllCounter: builder.query({
      query: () => ({
        url: "/club-dashboard-all-counter",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["myMembers"],
    }),

    addTransfer: builder.mutation({
      query: (data) => ({
        url: `/add-transfer`,
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["myMembers"],
    }),
  }),
});

export const { 
  useGetAllMyMembersQuery,
  useGetAgentDashboardAllCounterQuery
 } = clubdashboardApi;
