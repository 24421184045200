import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { FaAngleDown, FaList } from 'react-icons/fa';

const ThirdAdminBalanceTransfer = () => {
    const [searchText, setSearchText] = useState('');
    const columns = [
        {
            name: 'SN',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'User Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Amount',
            selector: row => row.amount,
            sortable: true,
        },
        {
            name: 'Last Balance',
            selector: row => row.balance,
            sortable: true,
        },
        {
            name: 'Note',
            selector: row => row.note,
            sortable: true,
        },
        {
            name: 'Time',
            selector: row => row.time,
            sortable: true,
        }
    ];

    const data = [
        {
            id: 1,
            name: 'KA',
            amount: '1400',
            balance: '5000',
            note: 'Cark',
            time: '10 oct 23',
        },
        {
            id: 2,
            name: 'MA',
            amount: '1200',
            balance: '2000',
            note: 'dark',
            time: '10 oct 23',
        },
        {
            id: 3,
            name: 'KL',
            amount: '1300',
            balance: '3000',
            note: 'Bark',
            time: '10 oct 23',
        },
    ]

    const customStyles = {
        // table: {
        //     style: {
        //         color: 'black',
        //         border: '1px solid gray'

        //     },
        // },
        headRow: {
            style: {
                color: 'white',
                fontSize: 14,
                fontWeight: "bold",
                backgroundColor: 'teal',
            },
        },

    }
    const customFilter = (rows, searchText) => {
        return rows.filter(row => {
            return Object.values(row).some(value =>
                String(value).toLowerCase().includes(searchText.toLowerCase())
            );
        });
    };
    return (
        <div className='mt-10' >
            <div className='bg-white text-black p-5 flex gap-2 '>
                <FaList className='text-xl mt-2'></FaList>
                <h1 className='text-2xl font-semibold'>History</h1>
            </div>
            <div className='m-5 bg-white rounded '>
                <div className='flex items-center justify-end px-5 py-4 '>
                    <label htmlFor="search" className='text-black text-sm font-semibold p-1'>Search:</label>
                    <input
                        type="text"
                        id='search'
                        placeholder=''
                        className=' text-black text-sm border-2 px-2 py-1 rounded focus:outline-teal-600'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)} />
                </div>
                <div className='px-5'>
                    <DataTable
                        columns={columns}
                        data={customFilter(data, searchText)}
                        customStyles={customStyles}
                        sortIcon={<FaAngleDown className='m-1' />}
                        defaultSortAsc={true}
                        paginationRowsPerPageOptions={[50, 100, 150]}
                        paginationPerPage={100}
                        pagination
                        highlightOnHover
                    />
                </div>
            </div>
        </div >
    );
};

export default ThirdAdminBalanceTransfer;