import React, { useState } from "react";
import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { useAddDefaultQuestionMutation } from "../../../../features/api/setdefaultmatchApi";
import { selectSectionList } from "../../../../data";

const DefaultAddQuestionModal = ({ qiestionData, closeModal, openModal }) => {
  console.log(qiestionData);
  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  const [cricketType, setCricketType] = useState("1");

  const [title, setTitle] = useState("");
  const [selectSection, setSelectSection] = useState("");

  const [addDefaultQuestion] = useAddDefaultQuestionMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const sendData = {
        title: title,
        match_id: qiestionData?.id,
        section_id: selectSection,
        g_s_type: qiestionData?.g_type == "2" ? cricketType : "0",
        g_type: qiestionData?.g_type,
      };
      const response = await addDefaultQuestion(sendData);
      if (response?.data) {
        toast.success(response?.data?.message);
        closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!openModal) return null;

  return (
    <div
      id="ModalBackground"
      // onClick={ModalClose}
      className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50"
    >
      <div className="w-11/12 sm:4/5 md:3/5 lg:w-2/5 rounded bg-white text-black">
        <div className="flex justify-between border-b p-4">
          <h1 className="text-lg font-bold">Default Add Question</h1>
          <p
            className="text-lg text-gray-500 cursor-pointer"
            onClick={closeModal}
          >
            <FaTimes />
          </p>
        </div>
        <div className="flex flex-col gap-y-2 p-4">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-y-3">
              <label className="text-lg">
                Match: {qiestionData?.team_a} vs {qiestionData?.team_b}
              </label>
              <input
                type="text"
                onChange={(e) => setTitle(e.target.value)}
                className="p-2 rounded w-full text-sm border-2 focus:outline-teal-600"
              />
            </div>

            <div className="mt-2">
              <select
                value={selectSection}
                onChange={(e) => setSelectSection(Number(e.target.value))}
                className="p-2 rounded w-full text-sm border-2 focus:outline-teal-600"
              >
                <option value={""}>--select section--</option>
                {selectSectionList?.map((item) => (
                  <option value={item?.id} key={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mt-4">
              {qiestionData?.g_type == "2" && (
                <div className="relative z-0 w-full mb-6 flex items-center gap-x-6">
                  <h1>Cricket Type:</h1>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      onChange={(e) => setCricketType(e.target.value)}
                      id="odi"
                      name="crickettype"
                      value="1"
                      defaultChecked
                    />
                      <label for="odi">ODI</label>
                  </div>

                  <div className="flex items-center">
                    <input
                      type="radio"
                      onChange={(e) => setCricketType(e.target.value)}
                      id="t20"
                      name="crickettype"
                      value="2"
                    />
                      <label for="t20">T20</label>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      onChange={(e) => setCricketType(e.target.value)}
                      id="test"
                      name="crickettype"
                      value="3"
                    />
                      <label for="test">Test</label>
                  </div>
                </div>
              )}
            </div>
            <button
              type="submit"
              className="bg-green-800 text-white text-sm w-full font-bold p-2 mt-3 rounded"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DefaultAddQuestionModal;
