import React from "react";
import { Link } from "react-router-dom";
import { useGetUserQuery } from "../../../features/api/loginApi";
import { useGetCompanyAccQuery} from "../../../features/api/bettingmatchApi";

const AdminPopUp = ({ closePopUp, openPopUp }) => {
  const PopUpClose = (e) => {
    if (e.target.id === "PopUpBackground") closePopUp();
  };

  const logOut = () => {
    localStorage.removeItem("accessToken");
    setTimeout(() => {
      window.location.reload();
    }, 0.5);
  };

  const accessToken = localStorage.getItem("accessToken");
  const { data, error, isLoading } = useGetUserQuery(accessToken, {
    skip: !accessToken,
    refetchOnMountOrArgChange: true,
  });

// Fetch company account capital
const { data: companyData, error: companyError, isLoading: companyLoading } = useGetCompanyAccQuery();


// console.log(companyData?.company_accounts?.capital)

  if (!openPopUp) return null;
  return (
    <div
      id="PopUpBackground"
      onClick={PopUpClose}
      className="fixed inset-0 z-50  "
    >
      <div className=" relative">
        <div className="absolute top-11 right-0 mr-5 text-black bg-white drop-shadow-2xl">
          <div className="m-2">
            <ul className="text-sm ">
              <Link to="/admin/transaction-history">
                <li className="my-1 p-2 hover:bg-gray-200 cursor-pointer transition duration-75 ease-in-out rounded">
                  <i className="fa-solid fa-money-bill-1 w-6" />( {companyLoading ? 'Loading...' : companyData?.company_accounts?.capital} {/* Display capital */} )
                </li>
              </Link>
              <Link to="/admin/settings">
                <li className="p-2 my-1 hover:bg-gray-200 cursor-pointer transition duration-75 ease-in-out rounded">
                  <i className="fa-solid fa-gears w-6" />
                  Settings
                </li>
              </Link>
              <Link to="/admin/profile" state={data}>
                <li className="p-2 my-1 hover:bg-gray-200 cursor-pointer transition duration-75 ease-in-out rounded">
                  <i className="fa-solid fa-user-gear w-6" />
                  Edit Profile
                </li>
              </Link>

              <Link to="/admin">
                <li className="p-2 my-1 hover:bg-gray-200 cursor-pointer transition duration-75 ease-in-out rounded">
                  <i className="fa-solid fa-heading w-6" />
                  Clear History
                </li>
              </Link>
              <li
                onClick={logOut}
                className="p-2 my-1 hover:bg-gray-200 cursor-pointer transition duration-75 ease-in-out rounded"
              >
                <i className="fa-solid fa-right-from-bracket w-6" />
                Logout
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPopUp;
