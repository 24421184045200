import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useGetUsersQuery } from "../../../features/api/usersApi";
import { useSignupMutation } from "../../../features/api/loginApi";

const JoinUs = ({ closeModal, openModal, loginModal }) => {
    useEffect(() => {
        if (openModal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [openModal]);

    const modalClose = (e) => {
        if (e.target.id === "modalBackground") closeModal();
    };

    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState("");
    const [userId, setUserId] = useState("");

    const [alphaError, setAlphaError] = useState("");
    const handleUserIdChange = (e) => {
        const value = e.target.value;
        const alphanumericRegex = /^[a-zA-Z0-9]*$/;

        if (value.match(alphanumericRegex)) {
            setUserId(value);
            setAlphaError("");
        } else {
            setAlphaError("Only letters and numbers are allowed.");
        }
    };

    const [mobileNumber, setPhone] = useState("");
    const [clubId, setSelectClub] = useState("");
    const [sponsorUsername, setSponsorId] = useState("");

    const [captcha, setCaptcha] = useState(generateCaptcha());
    const [enterCaptcha, setEnterCaptcha] = useState("");

    function generateCaptcha() {
        const captcha = Math.floor(100000 + Math.random() * 9000);
        return captcha.toString();
    }

    function regenerateCaptcha(e) {
        e.preventDefault();
        const newCaptcha = generateCaptcha();
        setCaptcha(newCaptcha);
    }

    const [clubs, setClubs] = useState([]);
    useEffect(() => {
        fetch("https://rakibvai.munihaelectronics.com/backend/public/api/getagents")
            .then((res) => res.json())
            .then((data) => setClubs(data?.agents));
    }, []);

    const { data: users } = useGetUsersQuery(null, {
        refetchOnMountOrArgChange: true,
    });
    const filterCustomer = users?.users?.filter(
        (user) => user?.role === "customer"
    );

    let matched = false;
    filterCustomer?.forEach((u) => {
        if (u?.userId === userId) {
            matched = true;
        }
    });

    let sponsorMatch = false;
    filterCustomer?.forEach((u) => {
        if (u?.userId === sponsorUsername) {
            sponsorMatch = true;
        }
    });

    const [signup] = useSignupMutation();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const newUser = {
            userId,
            role: "customer",
            mobileNumber,
            clubId,
            sponsorUsername,
            password,
        };
        if (matched) {
            toast.warning("ভাই, এই নামে একজন আছে মাপ করেন");
        } else if (captcha !== enterCaptcha) {
            return toast.error("ভাই ক্যাপচা মিলে নাই তো!!");
        } else if (password != confirmPassword) {
            setError("Password did not matched");
        } else {
            try {
                const response = await signup(newUser).unwrap();
                const accessToken = response?.token;
                localStorage.setItem("accessToken", accessToken);
                const from = location.state?.from?.pathname || "/user/dashboard";
                if (response) {
                    closeModal();
                    toast.success("Registration Successfully done...");
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    if (!openModal) return null;
    return (
        <div
            id="modalBackground"
            // onClick={modalClose}
            className="fixed inset-0 bg-black bg-opacity-40 snap-alw flex justify-center items-center"
        >
            <div
                className="w-11/12 sm:4/5 md:3/5 lg:w-2/5 p-5 rounded"
                style={{ background: "linear-gradient(360deg, #37103a, #08545B)" }}
            >
                <div className="flex items-center justify-between">
                    <h1 className="text-2xl font-bold">
                        {" "}
                        <span className="pr-3 text-green-400">|</span>Join Us
                    </h1>
                    <p className="text-lg text-gray-500" onClick={closeModal}>
                        <FaTimes />
                    </p>
                </div>
                <div className="mt-4">
                    <form>
                        <div className="flex gap-2 my-2">
                            <div className="w-1/2 ">
                                <label className="text-sm font-semibold">
                                    User ID <span className="text-red-400">*</span>
                                </label>
                                <input
                                    type="text"
                                    onChange={handleUserIdChange}
                                    placeholder="Enter User Id"
                                    className="p-2 rounded w-full text-sm text-white bg-gray-950 focus:outline-none"
                                />
                                {alphaError ? (
                                    <label className="text-red-500">{alphaError}</label>
                                ) : (
                                    matched === true && (
                                        <label className="text-red-500">Already exist</label>
                                    )
                                )}
                                {/* {alphaError&&<p>{alphaError}</p>} */}
                            </div>

                            <div className="w-1/2 ">
                                <label className="text-sm font-semibold">
                                    Phone No <span className="text-red-400">*</span>
                                </label>
                                <input
                                    type="number"
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder="Phone Number"
                                    className="p-2 rounded w-full text-sm text-white bg-gray-950 focus:outline-none"
                                />
                                {mobileNumber && mobileNumber?.length !== 11 && (
                                    <label className="text-red-500 font-bold">
                                        Invalid number
                                    </label>
                                )}
                            </div>
                        </div>
                        <div className="flex gap-2 my-2">
                            <div className="w-1/2 ">
                                <label className="text-sm font-semibold">
                                    Select Club <span className="text-red-400">*</span>
                                </label>
                                <select
                                    onChange={(e) => setSelectClub(e.target.value)}
                                    name="SelectClub"
                                    className="p-2 rounded w-full text-sm text-white bg-gray-950 focus:outline-none"
                                >
                                    <option selected disabled>
                                        Select Club
                                    </option>
                                    {clubs?.map((club) => (
                                        <option key={club?.id} value={club?.userId}>
                                            {club?.userId}{" "}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="w-1/2 ">
                                <label className="text-sm font-semibold">Sponsor's ID</label>
                                <input
                                    type="text"
                                    onChange={(e) => setSponsorId(e.target.value)}
                                    placeholder="Sponsor's ID"
                                    className="p-2 w-full rounded text-sm text-white bg-gray-950 focus:outline-none"
                                />
                                {sponsorUsername && !sponsorMatch && (
                                    <label className="text-red-500">Did not matched</label>
                                )}
                            </div>
                        </div>
                        <div className="flex gap-2 my-2">
                            <div className="w-1/2 relative">
                                <label className="text-sm font-semibold">
                                    Password <span className="text-red-400">*</span>
                                </label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    className="p-2 rounded w-full text-sm text-white bg-gray-950 focus:outline-none"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <div
                                    className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 text-white text-xl cursor-pointer"
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ? (
                                        <FaEyeSlash className="mt-5" />
                                    ) : (
                                        <FaEye className="mt-5" />
                                    )}
                                </div>
                            </div>
                            <div className="w-1/2 relative">
                                <label className="text-sm font-semibold">
                                    Confirm Password <span className="text-red-400">*</span>
                                </label>
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    placeholder="Confirm Password"
                                    className="p-2 rounded w-full text-sm text-white bg-gray-950 focus:outline-none"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <div
                                    className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 text-white text-xl cursor-pointer"
                                    onClick={toggleConfirmPasswordVisibility}
                                >
                                    {showConfirmPassword ? (
                                        <FaEyeSlash className="mt-5" />
                                    ) : (
                                        <FaEye className="mt-5" />
                                    )}
                                </div>
                                {confirmPassword && password !== confirmPassword && (
                                    <p className="text-red-500 mt-1">Password did not matched</p>
                                )}
                            </div>
                        </div>
                        <div className="flex items-center gap-2 my-2">
                            <div className="w-1/2">
                                <label className="text-sm font-semibold">Enter Captcha</label>
                                <input
                                    type="text"
                                    onChange={(e) => setEnterCaptcha(e.target.value)}
                                    placeholder="Enter Captcha    -->"
                                    className="p-2 w-full rounded text-sm text-white bg-gray-950 focus:outline-none"
                                />
                            </div>
                            <div className="w-1/2">
                                <label className="text-sm font-semibold text-transparent">
                                    Enter Captcha
                                </label>

                                <div className="py-1 w-full rounded text-sm flex justify-around bg-slate-400 text-black">
                                    <span
                                        className="text-xl italic"
                                        style={{ letterSpacing: "6px" }}
                                    >
                                        {captcha}
                                    </span>
                                    <button onClick={regenerateCaptcha}>
                                        <TfiReload size={20} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="bg-green-800 w-full text-sm py-2 my-2 rounded"
                            disabled={password !== confirmPassword}
                            onClick={handleSubmit}
                        >
                            Sign In
                        </button>
                        <p>
                            Already have an account?"{" "}
                            <span
                                className="text-blue-400 cursor-pointer"
                                onClick={loginModal}
                            >
                                Login here
                            </span>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default JoinUs;