import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import { useEditOverUnderGapMutation } from "../../../../features/api/bettingmatchApi";

const GapModal = ({ passData }) => {
  const getData = passData?.subtitles[passData?.subtitles?.length - 1];

  const [visible, setVisible] = useState(false);
  const [setInput, setInputData] = useState(null);
  const [editOverUnderGap] = useEditOverUnderGapMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await editOverUnderGap({
        over_under_title_id: getData?.id,
        initial_gap: setInput,
      });

      if (response?.data) {
        toast.success(response?.data?.message);
        setVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="card flex justify-content-center ">
      <button
        onClick={() => setVisible(true)}
        className="bg-green-700 font-bold text-white p-2 rounded-sm"
      >
        GAP {getData?.initial_gap || 0}
      </button>
      <Dialog
        header="GAP"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{
          width: "20vw",
          backgroundColor: "white",
          color: "black",
          padding: "10px",
        }}
        breakpoints={{ "960px": "50vw", "641px": "80vw" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            paddingTop: "10px",
            marginTop: "10px",
            borderTop: "1px solid gray",
          }}
        >
          <input
            type="text"
            defaultValue={getData?.initial_gap || 0}
            onChange={(e) => setInputData(Number(e.target.value))}
            style={{
              border: "1px solid gray",
              width: "100%",
              height: "30px",
              padding: "5px",
            }}
          />
          <button
            onClick={handleSubmit}
            style={{
              backgroundColor: "green",
              color: "white",
              padding: "6px 0",
              borderRadius: "3px",
            }}
          >
            Submit
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default GapModal;
