import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { FaAngleDown, FaList } from 'react-icons/fa';
import { useGetDepositWithdrawNumberBalanceQuery } from '../../../../features/api/depositnumberApi';

const WithdrawNumberHistory = () => {
    const { data = {}, isLoading } = useGetDepositWithdrawNumberBalanceQuery(null, {
        refetchOnMountOrArgChange: true,
    });

    // Default to an empty array if dwbalance is undefined
    const dwbalance = data.dwbalance || [];
    const filterWithdrawData = dwbalance.filter(d => d.d_or_w === '2');
    
    const [searchText, setSearchText] = useState('');

    const columns = [
        {
            name: 'SN',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Mobile Number',
            selector: row => row.m_number,
            sortable: true,
        },
        {
            name: 'Amount',
            selector: row => row.amount,
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: row => row.startDate,
            sortable: true,
        },
        {
            name: 'Finish Date',
            selector: row => row.finishDate,
            sortable: true,
        },
        {
            name: 'Last UserId',
            selector: row => row.userId,
            sortable: true,
        }
    ];

    const customStyles = {
        headRow: {
            style: {
                color: 'white',
                fontSize: 14,
                fontWeight: "bold",
                backgroundColor: 'teal',
            },
        },
    };

    const customFilter = (rows, searchText) => {
        if (!Array.isArray(rows)) {
            return []; // Return an empty array if rows is not an array
        }

        return rows.filter(row => {
            return Object.values(row).some(value =>
                String(value).toLowerCase().includes(searchText.toLowerCase())
            );
        });
    };

    // Check if data is loading or not available
    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className='mt-10'>
            <div className='bg-white text-black p-5 flex gap-2'>
                <FaList className='text-xl mt-2' />
                <h1 className='text-2xl font-semibold'>Withdraw Number History</h1>
            </div>
            <div className='m-5 bg-white rounded'>
                <div className='flex items-center justify-end px-5 py-4'>
                    <label htmlFor="search" className='text-black text-sm font-semibold p-1'>Search:</label>
                    <input
                        type="text"
                        id='search'
                        placeholder=''
                        className='text-black text-sm border-2 px-2 py-1 rounded focus:outline-teal-600'
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </div>
                <div className='px-5'>
                    <DataTable
                        columns={columns}
                        data={customFilter(filterWithdrawData, searchText)}
                        customStyles={customStyles}
                        sortIcon={<FaAngleDown className='m-1' />}
                        defaultSortAsc={true}
                        paginationRowsPerPageOptions={[50, 100, 150]}
                        paginationPerPage={100}
                        pagination
                        highlightOnHover
                    />
                </div>
            </div>
        </div>
    );
};

export default WithdrawNumberHistory;
