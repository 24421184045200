import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { useEditClubMutation } from "../../../../features/api/loginApi";
import axios from "axios";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { useAddBettingMatchMutation } from "../../../../features/api/bettingmatchApi";
import { useAddSetDefaultMatchMutation } from "../../../../features/api/setdefaultmatchApi";

const DefaultAddItemModal = ({ closeModal, openModal }) => {
  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  // const ModalClose = (e) => {
  //     if (e.target.id === 'ModalBackground')
  //         closeModal()
  // }

  const [A_team, setTeamA] = useState("");
  const [B_team, setTeamB] = useState("");
  const [gameType, setMatchType] = useState("1");
  // const [title, setBetStatement] = useState("");
  // const [date, setDate] = useState("");
  // const [status, setMatchStatus] = useState("1");
  // const [cricketType, setCricketType] = useState("1");
  // const [startDate, setStartDate] = useState(new Date());

  // let matched = false;
  // users?.users?.forEach((u) => {
  //   if (u?.userId === userName) {
  //     matched = true;
  //   }
  // });

  const [addSetDefaultMatch] = useAddSetDefaultMatchMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const addItemData = {
      team_a: A_team,
      team_b: B_team,
      g_type: gameType,
    };

    try {
      const response = await addSetDefaultMatch(addItemData);
      if (response?.data?.success == true) {
        closeModal();
        toast.success("Added Successfully done...");
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!openModal) return null;

  return (
    <div
      id="ModalBackground"
      // onClick={ModalClose}
      className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50"
    >
      <div className="w-11/12 sm:4/5 md:3/5 lg:w-2/5 p-5 rounded bg-white text-black">
        <div className="flex items-center justify-between ">
          <h1 className="text-xl font-bold">
            {" "}
            <span className="pr-3 text-green-400">|</span>Match Time
          </h1>
          <p
            className="text-lg text-gray-500 cursor-pointer"
            onClick={closeModal}
          >
            <FaTimes />
          </p>
        </div>
        <div className="">
          <form onSubmit={handleSubmit} className="mt-5">
            <div className="flex items-center justify-between w-full my-4 ">
              <label htmlFor="name" className="text-sm font-bold ">
                Team A <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="name"
                onChange={(e) => setTeamA(e.target.value)}
                placeholder="Enter A Team name"
                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
              />
            </div>
            <div className="flex items-center justify-between w-full my-4 ">
              <label htmlFor="username" className="text-sm font-bold ">
                Team B <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="username"
                onChange={(e) => setTeamB(e.target.value)}
                placeholder="Enter B Team name"
                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
              />
            </div>
            <div className="relative z-0 w-full mb-6 flex items-center gap-x-6">
              <h1>Match Type:</h1>
              <div className="flex items-center">
                <input
                  type="radio"
                  onChange={(e) => setMatchType(e.target.value)}
                  id="football"
                  name="matchtype"
                  value="1"
                  defaultChecked
                />
                  <label for="football">Football</label>
              </div>

              <div className="flex items-center">
                <input
                  type="radio"
                  onChange={(e) => setMatchType(e.target.value)}
                  id="cricket"
                  name="matchtype"
                  value="2"
                />
                  <label for="cricket">Cricket</label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  onChange={(e) => setMatchType(e.target.value)}
                  id="tabletennis"
                  name="matchtype"
                  value="3"
                />
                  <label for="tabletennis">Table Tennis</label>
              </div>
            </div>
            {/* {gameType == "2" && (
              <div className="relative z-0 w-full mb-6 flex items-center gap-x-6">
                <h1>Cricket Type:</h1>
                <div className="flex items-center">
                  <input
                    type="radio"
                    onChange={(e) => setCricketType(e.target.value)}
                    id="odi"
                    name="crickettype"
                    value="1"
                    defaultChecked
                  />
                    <label for="odi">ODI</label>
                </div>

                <div className="flex items-center">
                  <input
                    type="radio"
                    onChange={(e) => setCricketType(e.target.value)}
                    id="t20"
                    name="crickettype"
                    value="2"
                  />
                    <label for="t20">T20</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    onChange={(e) => setCricketType(e.target.value)}
                    id="test"
                    name="crickettype"
                    value="3"
                  />
                    <label for="test">Test</label>
                </div>
              </div>
            )} */}

            <button
              type="submit"
              className="bg-green-800 text-white text-sm w-full font-bold p-2 mt-3 rounded"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DefaultAddItemModal;
