import React, { useEffect, useState } from "react";
import Pusher from "pusher-js";
import { useGetAllLiveMatchQuery } from "../features/api/bettingmatchApi";

const DummyPage = () => {
  const { data, isLoading, refetch } = useGetAllLiveMatchQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const [getData, setGetData] = useState({});
  useEffect(() => {
    Pusher.logToConsole = true;

    const pusher = new Pusher("d128afcdec80816dd727", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("my-channel");

    const handleDataUpdated = (data) => {
      setGetData(data);
      console.log(data);
    };

    channel.bind("data-updated", handleDataUpdated);

    return () => {
      channel.unbind("data-updated", handleDataUpdated);
      pusher.unsubscribe("my-channel");
    };
  }, []);

  useEffect(() => {
    if (Object.keys(getData).length > 0) {
      refetch();
    }
  }, [getData, refetch]);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  return (
    <div>
      <h1>Pusher Test</h1>
      <h2> {getData?.updatedData?.title}</h2>
      <p>{getData?.updatedData?.amount}</p>

      {data?.matches?.map((item) => {
        return (
          <div key={item.id}>
            <p>{item.title}</p>
            <p>{item.amount}</p>
            <div>
              {item?.questions?.map((it) => {
                return (
                  <div key={it.id}>
                    <p>{it.title}</p>
                    {it?.options?.map((items) => {
                      return (
                        <div key={items.id}>
                          <p>{items.title}</p>
                          <p>{items.amount}</p>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DummyPage;

// import React, { useState, useEffect } from "react";
// import io from "socket.io-client";

// const socket = io("https://rakibvai.munihaelectronics.com/backend/public/api"); // Replace with your Laravel backend URL

// const DummyPage = () => {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     // Establish WebSocket connection and listen for 'data-updated' event
//     socket.on("data-updated", () => {
//       fetchData(); // Fetch updated data from backend
//     });

//     // Clean up on unmount
//     return () => {
//       socket.disconnect();
//     };
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await fetch(
//         "https://rakibvai.munihaelectronics.com/backend/public/api/get-all-live-matches-details"
//       ); // Replace with your Laravel API endpoint
//       const newData = await response.json();
//       setData(newData);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => {
//     // Fetch initial data when component mounts
//     fetchData();
//   }, []);

//   return (
//     <div>
//       {/* Render your data here */}
//       {data?.map((item) => (
//         <div key={item.id}>{item.name}</div>
//       ))}
//     </div>
//   );
// };

// export default DummyPage;
