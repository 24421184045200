import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import ball from "../../../Assets/photos/ball.png";
import FootballImg from "../../../Assets/photos/football.png";
import TableTennisImg from "../../../Assets/photos/table-tennis.png";
import { formatTime } from "../../../components/SharedDateFormat";
import {  FaSkullCrossbones } from "react-icons/fa";
import { useGetUserQuery } from "../../../features/api/loginApi";
import { useAddPlaceBetMutation } from "../../../features/api/mybetsApi";
import { red } from "@mui/material/colors";

const PlaceBetModel = ({ match, question, answer, refetch, mStatus }) => {
  const accessToken = localStorage.getItem("accessToken");
  const { data: user, refetch: userRefetch } = useGetUserQuery(accessToken, {
    skip: !accessToken,
    refetchOnMountOrArgChange: true,
  });

  const [visible, setVisible] = useState(false);
  const [matchScore, setMatchScore] = useState("");
  const [stake, setStake] = useState(100);
  const [loading, setLoading] = useState(false);
  const [suspended, setSuspended] = useState(true);
  const [stackMinMax, setStackMinMax] = useState("");

  useEffect(() => {
    // setInterval(setStackMinMax(""), 3000);
    const interval = setInterval(() => {
      setStackMinMax();
    }, 5000);
    return () => clearInterval(interval);
  }, [setStackMinMax]);

  const [getTime, setGetTime] = useState(null);
  // console.log(getTime);

  const handleStake = (s) => setStake(s);

  const handleInputChange = (event) => {
    setStake(event.target.value);
  };

  const stakeValues = ["500", "1000", "1500", "2000", "3000"];

  const [addPlaceBet] = useAddPlaceBetMutation();
  const [submitErrorMessage, setsubmitErrorMessage] = useState(null);
  console.log(submitErrorMessage)
  const handleSubmit = async () => {
    refetch();

    try {
      if (user?.user?.id) {
        let waitTime = Math.max(match?.waittingTime, question?.waittingTime);

        if (waitTime > 0) {
          setLoading(true);
          await new Promise((resolve) => setTimeout(resolve, waitTime * 1000));
          setLoading(false);
        }

        if (
          match?.showStatus == "1" &&
          question?.showStatus == "1" &&
          answer?.showStatus == "1"
        ) {
          setSuspended(true);

          if (stake >= 20 && stake <= 4000) {
            const response = await addPlaceBet({
              matchId: match?.id,
              questionId: question?.id,
              answerId: answer?.id,
              betAmount: stake,
              club: user?.user?.clubId,
              answerTitle: answer?.title,
              answerRate: answer?.amount,
              returnAmount: (stake * answer?.amount).toFixed(2),
              question_type: "normal",
              matchTitle: match?.title,
            });
            
            if (response?.data) {
              toast.success(response?.data?.message);
              setVisible(false);
              userRefetch();
            }else{
              console.log(response)
              setsubmitErrorMessage(response?.error?.data?.error);
            }
          } else {
            
            setStackMinMax("Input bet-amount between 20 to 4000");
            return;
          }
        } else {
          setSuspended(false);
        }
      } else {
        toast.error("Please login first");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error);
    }
  };

  return (
    <div className="w-full">
      <button
        onClick={() => setVisible(true)}
        disabled={answer?.showStatus == 0}
        className={`px-4 bg-[#1b5562] mb-0 py-3 space-x-1 w-full rounded-sm text-center cursor-pointer ${
          answer?.showStatus == 1 && "cursor-not-allowed"
        }`}
      >
        <span>{answer?.title}</span>
        <span className="text-[#05f8f4]">{answer?.amount}</span>
      </button>
      <Dialog
        header="Place Bet"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{
          width: "20vw",
          background: "linear-gradient(360deg, #37103a, #08545B)",
          color: "white",
          padding: "10px",
        }}
        breakpoints={{ "960px": "50vw", "641px": "80vw" }}
      >
        {submitErrorMessage && <p style={{color:"red", textAlign:"center",fontSize:"16px",fontWeight:"bold"}}>{submitErrorMessage}</p>}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            paddingTop: "10px",
            marginTop: "10px",
            // borderTop: "1px solid gray",
          }}
        >
          {suspended == false ? (
            <p
              style={{
                color: "#EE2400",
                fontWeight: "bolder",
                textAlign: "center",
                fontSize: "1.2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
              }}
            >
              Bet Suspended <FaSkullCrossbones />
            </p>
          ) : null}

          <p
            style={{
              color: "#FFFF00",
              textAlign: "center",
              fontSize: "0.8rem",
              fontWeight: "bolder",
            }}
          >
            {stackMinMax}
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {match?.gameType == 1 ? (
                <img
                  alt="Ball"
                  src={FootballImg}
                  style={{ height: "38px", width: "38px" }}
                />
              ) : match?.gameType == 2 ? (
                <img
                  alt="Ball"
                  src={ball}
                  style={{ height: "38px", width: "38px" }}
                />
              ) : (
                <img
                  alt="Ball"
                  src={TableTennisImg}
                  style={{ height: "38px", width: "38px" }}
                />
              )}

              <div>
                <h1
                  style={{
                    fontSize: "1rem",
                    fontWeight: 600,
                  }}
                >
                  {match?.A_team}{" "}
                  <span
                    style={{
                      color: "#f59e0b",
                    }}
                  >
                    VS
                  </span>{" "}
                  {match?.B_team}
                  <span
                    style={{
                      fontSize: "0.75rem",
                      color: "#FF474D",
                      fontWeight: "bold",
                      letterSpacing: "2px",
                      backgroundColor: "#22543d",
                      padding: "0.5rem",
                      marginLeft: "0.25rem",
                      borderRadius: "0.25rem",
                      textTransform: "uppercase",
                    }}
                  >
                    {mStatus}
                  </span>
                </h1>
                <p
                  style={{
                    fontWeight: 600,
                    marginTop: "5px",
                  }}
                >
                  {match?.title},{" "}
                  <span
                    style={{
                      fontSize: "0.875rem",
                    }}
                  >
                    {formatTime(match?.date)}
                  </span>
                </p>
              </div>
            </div>

            <h1
              style={{
                fontSize: "0.875rem",
                fontWeight: 600,
              }}
            >
              {" "}
              <span
                style={{
                  color: "#68d391",
                  marginRight: "0.5rem",
                }}
              >
                {question?.title}:
              </span>
              {answer?.title} {answer?.amount}
            </h1>

            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h1>Bet Rate</h1>
                <h1>{answer?.amount}</h1>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h1>Set Stake</h1>
                <h1>{stake}</h1>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h1>Winning Return Stake</h1>
                <h1>{(stake * answer?.amount).toFixed(2)}</h1>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                gap: "10px",
                marginTop: "2px",
                justifyContent: "space-between",
                textAlign: "center",
              }}
            >
              {stakeValues?.map((value, index) => (
                <div
                  key={index}
                  style={{
                    padding: "0.25rem",
                    width: "20%",
                    border: "1px solid",
                    borderRadius: "0.25rem",
                    fontSize: "0.875rem",
                    fontWeight: 600,
                    cursor: "pointer",
                  }}
                  onClick={() => handleStake(value)}
                >
                  {value}
                </div>
              ))}
            </div>
          </div>

          <form>
            <input
              type="number"
              name="number"
              value={stake}
              onChange={handleInputChange}
              style={{
                border: "1px solid gray",
                width: "100%",
                height: "30px",
                padding: "5px",
                color: "black",
              }}
            />
          </form>

          {/* <input
            type="number"
            onChange={(e) => setMatchScore(e.target.value)}
            defaultValue={answer?.waittingTime || 0}
            style={{
              border: "1px solid gray",
              width: "100%",
              height: "30px",
              padding: "5px",
            }}
          /> */}
          <button
            style={{
              backgroundColor: "#22543d",
              color: "white",
              fontSize: "0.875rem",
              fontWeight: "bold",
              padding: "0.5rem",
              marginTop: "0.75rem",
              width: "100%",
              borderRadius: "0.25rem",
            }}
            onClick={() => handleSubmit()}
            disabled={loading}
          >
            {loading ? "Loading..." : "Place Your Bet"}
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default PlaceBetModel;
