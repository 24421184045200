import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link } from "react-router-dom";
import { useEditAnswerStatusMutation } from "../../../../features/api/getanswerApi";
import { toast } from "react-toastify";
const PopupMenu = ({ closePopUp, openPopUp, datas }) => {
  console.log(datas);

  const PopUpClose = (e) => {
    if (e.target.id === "PopUpBackground") closePopUp();
  };

  const [editAnswerStatus] = useEditAnswerStatusMutation();

  const handleAnswerActiveStatus = async (val) => {
    try {
      if (val === 1) {
        const sendData1 = {
          id: datas?.id,
          showStatus: datas?.showStatus == "1" ? "0" : "1",
        };
        const response = await editAnswerStatus(sendData1);
        console.log(response);
        if (response?.data) {
          toast.success(response?.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!openPopUp) return null;
  return (
    <div
      id="PopUpBackground"
      onClick={PopUpClose}
      className="fixed inset-0 z-50 "
    >
      <div className=" relative">
        <div className="absolute top-[220px] right-[150px] mr-5 text-black bg-white drop-shadow-2xl">
          <div className="text-center text-sm">
            <div className="flex flex-col gap-y-4">
              <button className="hover:bg-gray-100 px-3 pt-3">
                User Action
              </button>
              <button
                onClick={() => handleAnswerActiveStatus(1)}
                className="hover:bg-gray-100 px-3"
              >
                Stop
              </button>
              <button className="hover:bg-gray-100 px-3">Hide</button>
              <button className="hover:bg-gray-100 px-3 pb-3">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupMenu;
