import React from "react";
import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { useEditOverUnderQuestionHideMutation } from "../../../../features/api/bettingmatchApi";
import { useEditNormalQuestionHideMutation } from "../../../../features/api/getquestionApi";

const QuestionShowModal = ({
  closeModal,
  openModal,
  questions,
  overUnder,
  everyOverUnder,
  inningsTotal,
}) => {
  const [editNormalQuestionHide] = useEditNormalQuestionHideMutation();
  const handleNormalAddToPanel = async (item) => {
    try {
      const sendData = {
        id: item?.id,
        qhide: 1,
      };
      await editNormalQuestionHide(sendData);
    } catch (error) {
      console.log(error);
    }
  };

  const [editOverUnderQuestionHide] = useEditOverUnderQuestionHideMutation();
  const handleOverAddToPanel = async (item, val) => {
    try {
      if (val == 1) {
        const sendData = {
          id: item?.id,
          qhide: 1,
        };
        await editOverUnderQuestionHide(sendData);
      } else if (val == 2) {
        const sendData = {
          id: item?.id,
          qhide: 1,
        };
        await editOverUnderQuestionHide(sendData);
      } else if (val == 3) {
        const sendData = {
          id: item?.id,
          qhide: 1,
        };
        await editOverUnderQuestionHide(sendData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  function formatDate(dateString) {
    const inputDate = new Date(dateString);
    const options = {
      timeZone: "Asia/Dhaka",
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const dhakaTime = inputDate.toLocaleString("en-US", options);
    return dhakaTime;
  }

  if (!openModal) return null;

  return (
    <div
      id="ModalBackground"
      // onClick={ModalClose}
      className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-cente z-50"
    >
      <div className="w-full md:w-2/5 h-[max-content] m-2 md:m-0 rounded-md md:rounded bg-white text-black">
        <div className="flex items-center justify-end border-b p-4">
          <p
            className="text-lg text-gray-500 cursor-pointer"
            onClick={closeModal}
          >
            <FaTimes />
          </p>
        </div>
        <div className="p-4">
          <div>
            <div className="border grid grid-cols-12">
              <span className="col-span-1 border-r text-center py-2 font-bold">
                SN.
              </span>
              <span className="col-span-9 py-2 ml-2 font-bold text-sm">
                Normal Question
              </span>
              <span className="col-span-2 p-2 border-l text-center font-bold">
                Actions
              </span>
            </div>
            <div>
              {questions
                ?.filter((question) => question?.question?.qhide == "0")
                ?.map((ques, idx) => (
                  <div key={idx} className="border grid grid-cols-12">
                    <span className="grid justify-center items-center col-span-1 border-r text-center py-2 font-bold">
                      {idx + 1}
                    </span>
                    <span className="grid items-center col-span-8 md:col-span-9 py-2 ml-2 font-bold">
                      {ques?.question?.title}
                    </span>
                    <span className="grid justify-center items-center col-span-3 md:col-span-2 py-2 border-l text-center font-bold">
                      <button
                        onClick={() => handleNormalAddToPanel(ques?.question)}
                        className="bg-cyan-700 text-white font-bold p-2 rounded-sm"
                      >
                        Show
                      </button>
                    </span>
                  </div>
                ))}
            </div>
          </div>

          <div>
            <div className="border grid grid-cols-12 ">
              <span className="col-span-1 border-r text-center py-2 font-bold">
                SN.
              </span>
              <span className="col-span-9 py-2 ml-2 font-bold text-sm">
                Over Under Question
              </span>
              <span className="col-span-2 p-2 border-l text-center font-bold">
                Actions
              </span>
            </div>
            <div>
              {overUnder
                ?.filter((overundr) => overundr?.qhide == "0")
                ?.map((ovun, idx) => (
                  <div key={idx} className="border grid grid-cols-12">
                    <span className="grid justify-center items-center col-span-1 border-r text-center py-2 font-bold">
                      {idx + 1}
                    </span>
                    <span className="grid items-center col-span-8 md:col-span-9 py-2 ml-2 font-bold">
                      {ovun?.title}
                    </span>
                    <span className="grid justify-center items-center col-span-3 md:col-span-2 py-2 border-l text-center font-bold">
                      <button
                        onClick={() => handleOverAddToPanel(ovun, 1)}
                        className="bg-cyan-700 text-white font-bold p-2 rounded-sm"
                      >
                        Show
                      </button>
                    </span>
                  </div>
                ))}
            </div>
          </div>

          <div>
            <div className="border grid grid-cols-12">
              <span className="col-span-1 border-r text-center py-2 font-bold">
                SN.
              </span>
              <span className="col-span-9 py-2 ml-2 font-bold text-sm">
                Every Over Under Question
              </span>
              <span className="col-span-2 p-2 border-l text-center font-bold">
                Actions
              </span>
            </div>
            <div>
              {everyOverUnder
                ?.filter((overundr) => overundr?.qhide == "0")
                ?.map((ovun, idx) => (
                  <div key={idx} className="border grid grid-cols-12">
                    <span className="grid justify-center items-center col-span-1 border-r text-center py-2 font-bold">
                      {idx + 1}
                    </span>
                    <span className="grid items-center col-span-8 md:col-span-9 py-2 ml-2 font-bold">
                      {ovun?.title}
                    </span>
                    <span className="grid justify-center items-center col-span-3 md:col-span-2 py-2 border-l text-center font-bold">
                      <button
                        onClick={() => handleOverAddToPanel(ovun, 2)}
                        className="bg-cyan-700 text-white font-bold p-2 rounded-sm"
                      >
                        Show
                      </button>
                    </span>
                  </div>
                ))}
            </div>
          </div>

          <div>
            <div className="border grid grid-cols-12">
              <span className="col-span-1 border-r text-center py-2 font-bold">
                SN.
              </span>
              <span className="col-span-9 py-2 ml-2 font-bold text-sm">
                Innings Total
              </span>
              <span className="col-span-2 p-2 border-l text-center font-bold">
                Actions
              </span>
            </div>
            <div>
              <div>
                {inningsTotal
                  ?.filter((overundr) => overundr?.qhide == "0")
                  ?.map((ovun, idx) => (
                    <div key={idx} className="border grid grid-cols-12">
                      <span className="grid justify-center items-center col-span-1 border-r text-center py-2 font-bold">
                        {idx + 1}
                      </span>
                      <span className="grid items-center col-span-8 md:col-span-9 py-2 ml-2 font-bold">
                        {ovun?.title}
                      </span>
                      <span className="grid justify-center items-center col-span-3 md:col-span-2 py-2 border-l text-center font-bold">
                        <button
                          onClick={() => handleOverAddToPanel(ovun, 3)}
                          className="bg-cyan-700 text-white font-bold p-2 rounded-sm"
                        >
                          Show
                        </button>
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionShowModal;
