import React from 'react';
import logo from '../../Assets/logo.png'

const Footer = () => {
    const goToTop = () => {
        window.scrollTo({
            top: 0,
        });
    }

    return (
        <div >
            <div style={{ backgroundColor: '#333' }}>
                <div className='py-5 mx-auto max-w-5xl mb-12 lg:mb-0'>
                    <div className='text-center'>
                        <div className='mx-auto w-32 py-1 mb-3 rounded bg-green-800 ' onClick={() => goToTop()} >
                            <i class="fa-solid fa-angles-up"></i>
                            <h1 className='font-semibold'>Go To Top </h1>
                        </div>
                        <h3 className='text-lg font-bold text-green-400'>Best Quality Betting Platform</h3>
                    </div>

                    <div className='my-3 lg:flex items-center gap-5 '>
                        <div className='mx-auto text-center'>
                            <img src={logo} alt="" className='mx-auto lg:h-24 lg:w-80' />
                        </div>

                        <p className='lg:px-0 px-5 text-justify text-sm font-semibold '>At ball65.com, we're passionate about sports and betting. With a wide range of sports including football, cricket, and table tennis, you'll never run out of exciting betting options. Our platform is designed to be user-friendly, so you can easily find and place your bets on the go. We also offer the latest odds and expert analysis to help you make informed betting decisions. Join the ball65.com community today and experience the thrill of sports betting like never before!
                        </p>
                    </div>

                    <div className='text-center lg:px-0 px-5'>
                        <p>© 2023-2030 ball65 Betting Website. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Footer;