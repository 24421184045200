import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import ActionModal from "./ActionModal";
import AddItemModal from "./AddItemModal";
import PopupMenu from "./PopupMenu";
import AccordionAction1Modal from "./AccordionAction1Modal";
import {
  useGetAllMatchDataQuery,
  useGetCompanyAccQuery,
} from "../../../../features/api/bettingmatchApi";
import { Link } from "react-router-dom";
import HiddenMatchModal from "./HiddenMatchModal";
import UpcomingMatch from "./UpcomingMatch";
import LiveMatch from "./LiveMatch";
import { LinearProgress } from "@mui/material";
import { useGetAllAdminBetsQuery } from "../../../../features/api/mybetsApi";
import ReloadPusher from "../../../../components/ReloadPusher";
import { useGetadmindashboardAllCounterQuery } from "../../../../features/api/bettingmatchApi";
import AccordionActionNormalModal from "./AccordionActionNormalModal";

function BettingPanel() {
  // ! RTK Queries Below !!!

  const { data, refetch: betRefetch } = useGetAllAdminBetsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const allBets = data?.bets;

  const quesBets = allBets?.filter((ques) => ques?.question_type == "normal");
  const overBets = allBets?.filter((ques) => ques?.question_type == "total");
  const everyBets = allBets?.filter((ques) => ques?.question_type == "every");
  const inngsBets = allBets?.filter((ques) => ques?.question_type == "innings");

  const { data: comAccData, refetch: comAccRefetch } = useGetCompanyAccQuery(
    null,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const comAcc = comAccData?.company_accounts;

  const {
    data: bettingData,
    isLoading: isBettingDataLoading,
    refetch,
  } = useGetAllMatchDataQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  // ! React States Below !!!

  const [liveMatches, setLiveMatches] = useState([]);

  const [upComingMatches, setUpComingMatches] = useState([]);

  const [openClubInfoModal, setOpenClubInfoModal] = useState(false);
  const [openHiddenMatchModal, setOpenHiddenMatchModal] = useState(false);
  const [sendToAction, setSendToAction] = useState(null);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [sendToAccordionAction1, setSendToAccordionAction1] = useState(null);
  const [openAccordionAction1Modal, setOpenAccordionAction1Modal] =
    useState(false);
  const [openPopupMenu, setOpenPopupMenu] = useState(false);
  const [recvAnsData, setRcvAnsData] = useState(null);

  // ! UseEffects
  useEffect(() => {
    if (!isBettingDataLoading && bettingData) {
      setLiveMatches(bettingData.live_matches);
      setUpComingMatches(bettingData.upcoming_matches);
    }
  }, [bettingData, isBettingDataLoading]);

  const [getData, setGetData] = useState({});
  useEffect(() => {
    if (Object.keys(getData).length > 0) {
      refetch();
      betRefetch();
      comAccRefetch();
    }
  }, [getData, refetch]);

  // ! Handle Functions Below !!!
  const handleAddItemModal = (data) => {
    setOpenClubInfoModal(true);
  };

  const handleHiddenMatchModal = (data) => {
    setOpenHiddenMatchModal(true);
  };

  const handleCloseClubInfoModal = () => setOpenClubInfoModal(false);
  const handleCloseHiddenMatchModal = () => setOpenHiddenMatchModal(false);
  const handleCloseAccordionAction1Modal = () =>
    setOpenAccordionAction1Modal(false);
  const handleCloseActionModal = () => setOpenActionModal(false);
  const handleClosePopupMenu = () => setOpenPopupMenu(false);
  const handleActionModal = (data) => {
    setOpenActionModal(true);
    setSendToAction(data);
  };
  const handleAccordionAction1Modal = (data) => {
    setOpenAccordionAction1Modal(true);
    setSendToAccordionAction1(data);
  };
  const handleAnswerPopupMenu = (data) => {
    setOpenPopupMenu(true);
    setRcvAnsData(data);
  };
  const allcounters = useGetadmindashboardAllCounterQuery({
    force: true,
  })?.data;
  console.log(allcounters);
  return (
    <div className="mt-10">
      <div className="bg-white text-black p-3">
        <p className="font-semibold text-sm text-justify">
          <i className="fa-solid fa-gauge-high text-lg mr-1" />
          Total User Balance = {allcounters?.user_total_balance} || Total Saving
          Account = {comAcc?.totalSaving} || Total Profit = {comAcc?.s_save}
          {/* Total User Balance = 320062.94 || Total Gaining Amount = 0 || Total
          Sending Amount = 0 || Total Saving Account = 2013904.77 || Total
          Profit = 1744783.74 */}
        </p>
      </div>
      {/* Page Top Action Buttons Section Below */}
      <div className="m-1 bg-white rounded text-black p-2">
        <div className="flex gap-x-1">
          <button
            onClick={() => handleAddItemModal()}
            className="p-2 bg-cyan-700 text-white rounded font-bold flex items-center"
          >
            <IoMdAdd size={18} /> <span>Add Item</span>
          </button>
          <button
            onClick={() => handleHiddenMatchModal()}
            className="p-2 bg-cyan-700 text-white rounded font-bold"
          >
            Hidden Match
          </button>
          <Link
            to="/admin/betting-panel/set-default-match"
            className="p-2 bg-cyan-700 text-white rounded font-bold"
          >
            <button>Set Default Match</button>
          </Link>
        </div>
        {/* Live Match Section */}
        <div className="my-4">
          <p className="mb-2 text-orange-500 font-bold">Live Match</p>
          {!liveMatches?.length ? (
            <div>
              <LinearProgress color="success" />
              <LinearProgress color="success" />
              <LinearProgress color="success" />
            </div>
          ) : (
            <div>
              {liveMatches
                ?.filter((hidePanel) => hidePanel?.match?.hideFromPanel == "0")
                ?.map((item, index) => {
                  const filterMatch = allBets?.filter(
                    (bet) => bet?.matchId == item?.match?.id
                  );
                  return (
                    <div key={index}>
                      <LiveMatch
                        item={item["match"]}
                        questions={item["questions"]}
                        overUnderDetails={item["over_under_details"]}
                        handleActionModal={handleActionModal}
                        handleAccordionAction1Modal={
                          handleAccordionAction1Modal
                        }
                        matchBet={filterMatch}
                        quesBets={quesBets}
                        overBets={overBets}
                        everyBets={everyBets}
                        inngsBets={inngsBets}
                      />
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        {/* Upcoming Match Section */}
        <div className="my-6">
          <p className="mb-2 text-orange-500 font-bold">Upcoming Match</p>
          {!upComingMatches?.length ? (
            <div>
              <LinearProgress color="success" />
              <LinearProgress color="success" />
              <LinearProgress color="success" />
            </div>
          ) : (
            <div>
              {upComingMatches
                ?.filter((hidePanel) => hidePanel?.match?.hideFromPanel == "0")
                ?.map((item, index) => {
                  const filterMatch = allBets?.filter(
                    (bet) => bet?.matchId == item?.match?.id
                  );
                  return (
                    <div key={index}>
                      <UpcomingMatch
                        item={item["match"]}
                        questions={item["questions"]}
                        overUnderDetails={item["over_under_details"]}
                        handleActionModal={handleActionModal}
                        handleAccordionAction1Modal={
                          handleAccordionAction1Modal
                        }
                        matchBet={filterMatch}
                        quesBets={quesBets}
                        overBets={overBets}
                        everyBets={everyBets}
                        inngsBets={inngsBets}
                      />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>

      <AddItemModal
        openModal={openClubInfoModal}
        closeModal={handleCloseClubInfoModal}
      />
      <HiddenMatchModal
        openModal={openHiddenMatchModal}
        closeModal={handleCloseHiddenMatchModal}
        liveMatches={liveMatches}
        upComingMatches={upComingMatches}
      />

      <ActionModal
        data={sendToAction}
        openModal={openActionModal}
        closeModal={handleCloseActionModal}
      />
      <AccordionActionNormalModal
        data={sendToAccordionAction1}
        openModal={openAccordionAction1Modal}
        closeModal={handleCloseAccordionAction1Modal}
      />
      <PopupMenu
        openPopUp={openPopupMenu}
        closePopUp={handleClosePopupMenu}
        datas={recvAnsData}
      />
      <ReloadPusher setGetData={setGetData} />
    </div>
  );
}

export default BettingPanel;
