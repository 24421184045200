import React from "react";

const RunsDisplay = ({ item, handleRunClick }) => {
  const { run } = item;
  const mainRun = parseFloat(run);

  const runs = [];
  for (let i = -3; i <= 3; i++) {
    runs.push(mainRun + i);
  }

  return (
    <div className="mb-1 flex flex-wrap gap-1">
      <button className="bg-[#BF360C] font-bold text-white px-3 py-2 m- rounded-sm text-lg">
        R
      </button>
      {runs?.map((runValue, index) => (
        <button
          key={index}
          onClick={() => handleRunClick(runValue, item?.id)}
          className={` ${
            runValue === mainRun ? "bg-[#558B2F]" : "bg-[#795548]"
          } text-white p-1 rounded-sm text-lg`}
        >
          {runValue.toFixed(1)}
        </button>
      ))}
    </div>
  );
};

export default RunsDisplay;
