import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import {
  useAddNewAnswerMutation,
  useAddSetDefaultAnswerMutation,
} from "../../../../features/api/getanswerApi";
import { useAddDefaultAnswerMutation } from "../../../../features/api/setdefaultmatchApi";

const DefaultQuestionAnserModal = ({ answerData, closeModal, openModal }) => {
  console.log(answerData)
  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  const [addAns, setAddAnswer] = useState(null);
  const [addAnsRate, setRate] = useState(null);

  // const [addDefaultAnswer] = useAddDefaultAnswerMutation();
  const [addSetDefaultAnswer] = useAddSetDefaultAnswerMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const sendData = {
        title: addAns,
        rate: +addAnsRate,
        match_question_id: answerData?.id,
        match_id: Number(answerData?.match_id),
      };
      const response = await addSetDefaultAnswer(sendData);
      console.log(response);
      if (response?.data) {
        toast.success(response?.data?.message);
        closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!openModal) return null;

  return (
    <div
      id="ModalBackground"
      // onClick={ModalClose}
      className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50"
    >
      <div className="w-11/12 sm:4/5 md:3/5 lg:w-2/5 rounded bg-white text-black">
        <div className="flex justify-between border-b p-4">
          <h1 className="text-lg font-bold">Add Answer</h1>
          <p
            className="text-lg text-gray-500 cursor-pointer"
            onClick={closeModal}
          >
            <FaTimes />
          </p>
        </div>
        <div className="flex flex-col gap-y-2 p-4">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-y-3">
              <label className="text-lg">Question: {answerData?.title}</label>
              <input
                type="text"
                onChange={(e) => setAddAnswer(e.target.value)}
                placeholder="Enter Answer"
                className="p-2 rounded w-full text-sm border-2 focus:outline-teal-600"
              />
              <input
                type="number"
                onChange={(e) => setRate(e.target.value)}
                placeholder="Rate"
                className="p-2 rounded w-full text-sm border-2 focus:outline-teal-600"
              />
              <button
                type="submit"
                className="bg-green-800 text-white text-sm w-full font-bold p-2 mt-3 rounded"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DefaultQuestionAnserModal;
