import { apiSlice } from "./apiSlice";

const setdefaultmatchApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSetDefaltMatch: builder.query({
      query: () => ({
        url: "/get-all-defualt-matches",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["get-all-defualt-matches"],
    }),
    addSetDefaultMatch: builder.mutation({
      query: (data) => ({
        url: `/add-default-match`,
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-defualt-matches"],
    }),

    removeSetDefaultMatch: builder.mutation({
      query: (id) => ({
        url: `/delete-default-match/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-defualt-matches"],
    }),
    editSetDefaultMatch: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-default-match-area-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-defualt-matches"],
    }),
    getDefaultQuestion: builder.query({
      query: (matchId) => ({
        url: `/get-default-questions/${matchId}`,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["get-default-questions"],
    }),
    addDefaultQuestion: builder.mutation({
      query: (data) => ({
        url: `/add-default-question`,
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-default-questions"],
    }),
    removeDefaultQuestion: builder.mutation({
      query: (id) => ({
        url: `/delete-default-question/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-default-questions"],
    }),
    editDefaultQuestion: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-default-question-area-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-default-questions"],
    }),
    getDefaultAnswer: builder.query({
      query: ({ getMatch1Id, getMatchId }) => ({
        url: `/get-default-answers/${getMatch1Id}/${getMatchId}`,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: [
        "get-default-answers",
        "get-default-questions",
        "get-all-defualt-matches",
      ],
    }),
    addDefaultAnswer: builder.mutation({
      query: (data) => ({
        url: `/add-default-answer`,
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-default-answers"],
    }),
    removeDefaultAnswer: builder.mutation({
      query: (id) => ({
        url: `/delete-default-answer/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-default-answers"],
    }),
    editDefaultAnswer: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-default-answer-rate/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-default-answers"],
    }),
  }),
});

export const {
  useGetAllSetDefaltMatchQuery,
  useAddSetDefaultMatchMutation,
  useRemoveSetDefaultMatchMutation,
  useEditSetDefaultMatchMutation,
  useGetDefaultQuestionQuery,
  useAddDefaultQuestionMutation,
  useRemoveDefaultQuestionMutation,
  useEditDefaultQuestionMutation,
  useGetDefaultAnswerQuery,
  useAddDefaultAnswerMutation,
  useRemoveDefaultAnswerMutation,
  useEditDefaultAnswerMutation,
} = setdefaultmatchApi;
