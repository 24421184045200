import React from "react";
import { useState } from "react";
import ProfileInfo from "./ProfileInfo/ProfileInfo";
import PasswordSetting from "./PasswordSetting/PasswordSetting";
import ChangeClub from "./ChangeClub/ChangeClub";
import { useGetUserQuery } from "../../../features/api/loginApi";

const PersonalProfile = () => {
  const [isProfileInfoClicked, setIsProfileInfoClicked] = useState(true);
  const [isPasswordSettingClicked, setIsPasswordSettingClicked] =
    useState(false);
  const [isChangeClubClicked, setIsChangeClubClicked] = useState(false);

  const handleClick = (type) => {
    if (type === "ProfileInfo") {
      setIsProfileInfoClicked(true);
      setIsPasswordSettingClicked(false);
      setIsChangeClubClicked(false);
    } else if (type === "PasswordSetting") {
      setIsProfileInfoClicked(false);
      setIsPasswordSettingClicked(true);
      setIsChangeClubClicked(false);
    } else if (type === "ChangeClub") {
      setIsProfileInfoClicked(false);
      setIsPasswordSettingClicked(false);
      setIsChangeClubClicked(true);
    }
  };

  const accessToken = localStorage.getItem("accessToken");
  const { data, error, isLoading } = useGetUserQuery(accessToken, {
    skip: !accessToken,
    refetchOnMountOrArgChange: true,
  });
  const user = data?.user;

  return (
    <div>
      <div>
        <h1 className="text-xl font-bold">Profile Settings</h1>
      </div>
      <div className=" my-5 border border-emerald-950">
        <div className="p-5 ">
          <div className="grid grid-cols-2 lg:flex">
            <div
              className={`lg:w-48 text-center p-2 border-green-800 rounded-t-md  ${
                isProfileInfoClicked ? "bg-green-800 border" : "hover:border"
              }`}
              onClick={() => handleClick("ProfileInfo")}
            >
              <h1 className="text-sm font-semibold">Profile Information</h1>
            </div>
            <div
              className={`lg:w-48 text-center p-2 border-green-800 rounded-t-md  ${
                isPasswordSettingClicked
                  ? "bg-green-800 border "
                  : "hover:border"
              }`}
              onClick={() => handleClick("PasswordSetting")}
            >
              <h1 className="text-sm font-semibold">Password Setting</h1>
            </div>
            <div
              className={`lg:w-48 text-center p-2 border-green-800 rounded-t-md  ${
                isChangeClubClicked ? "bg-green-800 border" : "hover:border"
              }`}
              onClick={() => handleClick("ChangeClub")}
            >
              <h1 className="text-sm font-semibold">Change Club</h1>
            </div>
          </div>
          <div className="border border-green-800"></div>

          <div className=" my-3">
            <div className={isProfileInfoClicked ? "block" : "hidden"}>
              <ProfileInfo user={user} />
            </div>

            <div className={isPasswordSettingClicked ? "block" : "hidden"}>
              <PasswordSetting user={user} />
            </div>
            <div className={isChangeClubClicked ? "block" : "hidden"}>
              <ChangeClub user={user} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalProfile;
