import React from "react";
import { useGetMyBalanceTransferQuery } from "../../../features/api/mybetsApi";

const BalanceTransferHistory = () => {
  const { data, isLoading, error } = useGetMyBalanceTransferQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const transfers = data?.transfers;

  if (isLoading) {
    return (
      <div className="p-4">
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4">
        <p>Error loading data. Please try again later.</p>
      </div>
    );
  }

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-4">Balance Transfer History</h1>

      {/* Mobile View */}
      <div className="my-5 text-sm lg:hidden">
        {transfers.length === 0 ? (
          <p>No transfers found.</p>
        ) : (
          transfers.map((transfer, idx) => (
            <div key={transfer?.id} className="border-b py-4">
              <div className="text-green-400 text-lg font-semibold">
                Balance Transfer {transfers.length - idx}
              </div>
              <div className="flex flex-col">
                <div className="flex justify-between py-1">
                  <strong>To userId:</strong>
                  <span>{transfer?.to_userId}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>Amount:</strong>
                  <span>{transfer?.amount}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>Notes:</strong>
                  <span>{transfer?.notes}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>Date & Time:</strong>
                  <span>{new Date(transfer?.time).toLocaleString()}</span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Desktop View */}
      <div className="hidden lg:block overflow-x-auto mt-5 border border-emerald-950 rounded">
        <table className="min-w-full divide-y divide-emerald-950">
          <thead className="bg-gray-800 text-emerald-400 uppercase text-xs font-semibold">
            <tr>
              <th className="px-6 py-3 text-left">SN</th>
              <th className="px-6 py-3 text-left">To userId</th>
              <th className="px-6 py-3 text-left">Amount</th>
              <th className="px-6 py-3 text-left">Notes</th>
              <th className="px-6 py-3 text-left">Date & Time</th>
            </tr>
          </thead>
          <tbody>
            {(transfers || []).map((transfer) => (
              <tr key={transfer?.id} className="bg-gray-900 text-white">
                <td className="px-6 py-4 text-sm font-medium">{transfer?.id}</td>
                <td className="px-6 py-4 text-sm font-medium">{transfer?.to_userId}</td>
                <td className="px-6 py-4 text-sm font-medium">{transfer?.amount}</td>
                <td className="px-6 py-4 text-sm font-medium">{transfer?.notes}</td>
                <td className="px-6 py-4 text-sm font-medium">{new Date(transfer?.time).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BalanceTransferHistory;
