import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";
import {
  useAddWithdrawNumberMutation,
  useGetWithdrawNumberQuery,
  useRemoveWithdrawNumberMutation,
} from "../../../features/api/withdrawnumberApi";
import {
  useAddPaymentMethodMutation,
  useGetPaymentMethodQuery,
  useRemovePaymentMethodMutation,
} from "../../../features/api/paymentmethodApi";
import {
  useAddDepositNumberMutation,
  useGetDepositNumberQuery,
  useRemoveDepositNumberMutation,
} from "../../../features/api/depositnumberApi";
import { useGetadmindashboardAllCounterQuery } from "../../../features/api/bettingmatchApi";
import { Link } from "react-router-dom";

const DashboardAdmin = () => {
  const {
    data: withdrawNum,
    error,
    isLoading,
  } = useGetWithdrawNumberQuery({
    force: true,
  });
  const w_numbers = withdrawNum?.w_numbers;
  const [removeWithdrawNumber] = useRemoveWithdrawNumberMutation();
  const [addNewWithdrawNumber] = useAddWithdrawNumberMutation();
  const handleNewWithdraw = async () => {
    const { value: text } = await Swal.fire({
      title: "Phone Number",
      input: "number",
      // inputLabel: 'Phone Number',
      inputPlaceholder: "Enter Phone Number",
      inputAttributes: {
        "aria-label": "Enter Phone Number",
      },
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      showCancelButton: true,
    });

    if (text) {
      try {
        const response = await addNewWithdrawNumber({
          number: text,
        }).unwrap();
        if (response) {
          const message = response.message;

          Swal.fire({
            icon: "success",
            text: message,
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          text: "Failed to add the number",
        });
      }
    }
  };

  const handleDeleteWithdraw = (id) => {
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await removeWithdrawNumber(id).unwrap();
          if (response) {
            Swal.fire({
              icon: "success",
              text: response?.message,
            });
          }
        } catch (error) {
          console.log(error);
          Swal.fire({
            icon: "error",
            text: "Failed to delete",
          });
        }
      }
    });
  };

  const payment_methods = useGetPaymentMethodQuery({
    force: true,
  })?.data?.payment_methods;

  const deposit_receiving_numbers = useGetDepositNumberQuery({
    force: true,
  })?.data?.deposit_receiving_numbers;

  const [removeDepositNumber] = useRemoveDepositNumberMutation();
  const [addDepositNumber] = useAddDepositNumberMutation();
  const handleNewDeposit = async () => {
    const paymentMethodsOptions = payment_methods
      ?.filter((ty) => ty?.type == "dp")
      ?.map((method) => `<option value="${method.id}">${method.name}</option>`)
      .join("");

    const { value: formValues } = await Swal.fire({
      title: `<p class="swalp">Enter Payment Information</p>`,
      html: `<input id="swal-input1" placeholder="Phone Number" class="swal2-input swal2-input-border">
           <select id="swal-input2" class="swal2-select swal2-input-border">${paymentMethodsOptions}</select>`,
      // focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
        ];
      },
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      width: "23rem",
      padding: "10px 0px",
    });

    if (formValues) {
      const [phoneNumber, selectedOption] = formValues;
      try {
        const response = await addDepositNumber({
          method_id: selectedOption,
          number: phoneNumber,
        }).unwrap();
        if (response) {
          const message = response?.data?.message;
          Swal.fire({
            icon: "success",
            text: message,
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          text: "Failed to add the payment method",
        });
      }
    }
  };

  const handleDeleteDeposit = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await removeDepositNumber(id).unwrap();
          if (response) {
            Swal.fire({
              icon: "success",
              text: response?.message,
            });
          }
        } catch (error) {
          console.log(error);
          Swal.fire({
            icon: "error",
            text: "Failed to delete",
          });
        }
      }
    });
  };

  const [removePaymentMethod] = useRemovePaymentMethodMutation();
  const [addPaymentMethod] = useAddPaymentMethodMutation();
  const handleNewDepositMethod = async (balue) => {
    const { value: formValues } = await Swal.fire({
      text: "Multiple inputs",
      html:
        '<input id="swal-input1" placeholder="Method" class="swal2-input">' +
        '<input id="swal-input2" placeholder="Converting Rate" class="swal2-input">' +
        '<input type="file" id="swal-input3" accept="image/*" class="swal2-file">',
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
          document.getElementById("swal-input3").files[0],
        ];
      },
      showCancelButton: true,
      cancelButtonColor: "#d33",
      width: "25rem",
    });

    if (formValues) {
      const [name, rate, image] = formValues;
      try {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("rate", parseFloat(rate));
        formData.append("type", balue == "dp" ? "dp" : "wd");
        formData.append("img", image);

        const response = await addPaymentMethod(formData);
        if (response?.data) {
          const message = response?.data?.message;
          Swal.fire({
            icon: "success",
            text: message,
          });
        } else if (response?.error) {
          const message = response?.error?.data?.error;
          Swal.fire({
            icon: "error",
            text: message,
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          text: "Failed to add the payment method",
        });
      }
    }
  };
  const allcounters = useGetadmindashboardAllCounterQuery({
    force: true,
  })?.data;

  // console.log(allcounters?.user_total_balance);
  const handleDeleteDepositMethod = (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await removePaymentMethod(id).unwrap();
          if (response) {
            Swal.fire({
              icon: "success",
              text: response?.message,
            });
          }
        } catch (error) {
          console.log(error);
          Swal.fire({
            icon: "error",
            text: "Failed to delete",
          });
        }
      }
    });
  };

  const [userName, setUserName] = useState("");

  return (
    <div className="mt-12 px-5 ">
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-7 py-5">
        <div className="flex items-center bg-white rounded h-20">
          <div className="flex items-center justify-center bg-cyan-500 rounded-l w-20 h-20">
            <i className="fa-solid fa-circle-user text-5xl  "></i>
          </div>
          <div className=" text-black py-4 px-5">
            <h1 className="text-sm font-semibold">Total User Balance</h1>
            <h2 className="text-base mt-1">
              {allcounters?.user_total_balance}
            </h2>
          </div>
        </div>
        <div className="flex items-center bg-white rounded h-20">
          <div className="flex items-center justify-center bg-cyan-500 rounded-l w-20 h-20">
            <i className="fa-solid fa-user-secret text-4xl  "></i>
          </div>
          <div className=" text-black py-4 px-5">
            <h1 className="text-sm font-semibold">Total Club Balance</h1>
            <h2 className="text-base mt-1">
              {allcounters?.club_total_balance}
            </h2>
          </div>
        </div>
        <div className="flex items-center bg-white rounded h-20">
          <div className="flex items-center justify-center bg-cyan-500 rounded-l w-20 h-20">
            <i className="fa-solid fa-address-card text-4xl  "></i>
          </div>
          <div className=" text-black py-4 px-5">
            <h1 className="text-sm font-semibold">Total Users</h1>
            <h2 className="text-base mt-1">{allcounters?.total_users}</h2>
          </div>
        </div>
        <div className="flex items-center bg-white rounded h-20">
          <div className="flex items-center justify-center bg-cyan-500 rounded-l w-20 h-20">
            <i className="fa-solid fa-bangladeshi-taka-sign text-4xl  "></i>
          </div>
          <div className=" text-black py-4 px-5">
            <h1 className="text-sm font-semibold">Total Deposit</h1>
            <h2 className="text-base mt-1">{allcounters?.total_deposit}</h2>
          </div>
        </div>
        <div className="flex items-center bg-white rounded h-20">
          <div className="flex items-center justify-center bg-cyan-500 rounded-l w-20 h-20">
            <i className="fa-solid fa-bangladeshi-taka-sign text-4xl  "></i>
          </div>
          <div className=" text-black py-4 px-5">
            <h1 className="text-sm font-semibold">Total Withdraw</h1>
            <h2 className="text-base mt-1">{allcounters?.total_withdraw}</h2>
          </div>
        </div>
      </div>

      {/*/ All Category /*/}

      <div className="text-black border rounded mb-10">
        <div className="border-b p-3 bg-gray-100">
          <h1 className="text-xl font-semibold">All Category</h1>
        </div>
        <div className="p-5 bg-white">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
            <div className=" border rounded h-[max-content]">
              <div className="border-b text-base font-semibold p-3">
                <h1>Withdraw Number</h1>
              </div>
              {w_numbers?.map((num) => {
                return (
                  <div
                    key={num?.id}
                    className="flex justify-between p-3 border-b"
                  >
                    <h1 className="text-base">{num?.w_number}</h1>
                    <button
                      className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded"
                      onClick={() => handleDeleteWithdraw(num?.id)}
                    >
                      delete
                    </button>
                  </div>
                );
              })}

              <button
                className="mx-auto w-full bg-teal-600 text-white font-semibold p-2 rounded-b"
                onClick={handleNewWithdraw}
              >
                {" "}
                Add New{" "}
              </button>
            </div>
            <div className=" border rounded h-[max-content]">
              <div className="border-b text-base font-semibold p-3">
                <h1>Deposit Number</h1>
              </div>
              {deposit_receiving_numbers?.map((deposit) => {
                const findMethod = payment_methods?.find(
                  (method) => method?.id === +deposit?.method_id
                );
                return (
                  <div className="flex justify-between p-3 border-b">
                    <h1 className="text-base">
                      {deposit?.number} ({findMethod?.name})
                    </h1>
                    <button
                      className="bg-cyan-500 text-white font-semibold px-2 py-1 rounded"
                      onClick={() => handleDeleteDeposit(deposit?.id)}
                    >
                      delete
                    </button>
                  </div>
                );
              })}

              <button
                className="mx-auto w-full bg-teal-600 text-white font-semibold p-2 rounded-b"
                onClick={handleNewDeposit}
              >
                {" "}
                Add New{" "}
              </button>
            </div>
            <div className=" border rounded h-[max-content]">
              <div className="border-b text-base font-semibold p-3">
                <h1>Deposit Method</h1>
              </div>
              {payment_methods
                ?.filter((item) => item?.type == "dp")
                ?.map((method) => {
                  return (
                    <div
                      key={method?.id}
                      className="flex justify-between items-center p-3 border-b"
                    >
                      <img
                        src={`https://rakibvai.munihaelectronics.com/backend/storage/app/public/${method?.img}`}
                        className="w-10 h-6"
                      />
                      <h1 className="text-base flex items-center gap-x-1">
                        {method?.name}
                        <span className="bg-cyan-800 text-white text-xs px-1 rounded">
                          {method?.rate}
                        </span>
                      </h1>
                      <button
                        className="bg-red-500 text-white font-semibold w-5 h-5 flex items-center justify-center rounded-full"
                        onClick={() => handleDeleteDepositMethod(method?.id)}
                      >
                        x
                      </button>
                    </div>
                  );
                })}

              <button
                className="mx-auto w-full bg-teal-600 text-white font-semibold p-2 rounded-b"
                onClick={() => handleNewDepositMethod("dp")}
              >
                {" "}
                Add New{" "}
              </button>
            </div>
            <div className=" border rounded h-[max-content]">
              <div className="border-b text-base font-semibold p-3">
                <h1>Withdraw Method</h1>
              </div>
              {payment_methods
                ?.filter((item) => item?.type == "wd")
                ?.map((method) => {
                  return (
                    <div
                      key={method?.id}
                      className="flex justify-between items-center p-3 border-b"
                    >
                      <img
                        src={`https://rakibvai.munihaelectronics.com/backend/storage/app/public/${method?.img}`}
                        className="w-10 h-6"
                      />
                      <h1 className="text-base flex items-center gap-x-1">
                        {method?.name}
                        <span className="bg-cyan-800 text-white text-xs px-1 rounded">
                          {method?.rate}
                        </span>
                      </h1>
                      <button
                        className="bg-red-500 text-white font-semibold w-5 h-5 flex items-center justify-center rounded-full"
                        onClick={() => handleDeleteDepositMethod(method?.id)}
                      >
                        x
                      </button>
                    </div>
                  );
                })}

              <button
                className="mx-auto w-full bg-teal-600 text-white font-semibold p-2 rounded-b"
                onClick={() => handleNewDepositMethod("wd")}
              >
                {" "}
                Add New{" "}
              </button>
            </div>
            <div className=" border rounded h-[max-content]">
              <div className="border-b text-base font-semibold p-3">
                <h1>User Action</h1>
              </div>
              <div className="px-2 py-4">
                <input
                  type="text"
                  placeholder="User name"
                  onChange={(e) => setUserName(e.target.value)}
                  className="w-full border border-gray-300 p-2 rounded-sm"
                />
              </div>

              <Link to="/admin/single-user" state={userName}>
                <button className="mx-auto w-full bg-teal-600 text-white font-semibold p-2 rounded-b">
                  Now Go...
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;
