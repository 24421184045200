import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { useEditQuestionLimitMutation } from "../../../../features/api/getquestionApi";

const QuestionLimitModal = ({ datas }) => {
  const [visible, setVisible] = useState(false);
  const [matchScore, setMatchScore] = useState("");

  const [editQuestionLimit] = useEditQuestionLimitMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const sendData = {
        id: datas?.id,
        limitedAmount: matchScore || datas?.limitedAmount,
      };
      const response = await editQuestionLimit(sendData);
      if (response?.data) {
        setVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="card flex justify-content-center ">
      <button
        onClick={() => setVisible(true)}
        className="bg-emerald-700 font-bold text-white p-2 rounded-sm"
      >
        {" "}
        Limit({datas?.limitedAmount || 0})
      </button>
      <Dialog
        header="Edit Limit"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{
          width: "20vw",
          backgroundColor: "white",
          color: "black",
          padding: "10px",
        }}
        breakpoints={{ "960px": "50vw", "641px": "80vw" }}
      >
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              paddingTop: "10px",
              marginTop: "10px",
              borderTop: "1px solid gray",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <p>{datas?.title}</p>
            </div>
            <input
              type="text"
              onChange={(e) => setMatchScore(e.target.value)}
              defaultValue={datas?.limitedAmount || 0}
              style={{
                border: "1px solid gray",
                width: "100%",
                height: "30px",
                padding: "5px",
              }}
            />
            <button
              style={{
                backgroundColor: "green",
                color: "white",
                padding: "6px 0",
                borderRadius: "3px",
              }}
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default QuestionLimitModal;
