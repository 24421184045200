import React from "react";
import { useGetAllMyBetsQuery } from "../../../features/api/mybetsApi";

const BetHistory = () => {
  const { data, error, isLoading } = useGetAllMyBetsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  // Handle loading and error states
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading bet history: {error.message}</p>;

  const bets = data?.bets || [];

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-4">Bet History</h1>

      {/* Mobile View */}
      <div className="my-5 text-sm lg:hidden">
        {bets.length === 0 ? (
          <p>No bets found.</p>
        ) : (
          bets.map((record, idx) => {
            const {
              match,
              question,
              answer,
              amount,
              return_rate,
              return_amount,
              win_loss,
              date,
            } = record;
            return (
              <div key={idx} className="border-b py-4">
                <div className="text-green-400 text-lg font-semibold">
                  Bet {idx + 1}
                </div>
                <div className="flex flex-col">
                  <div className="flex justify-between py-1">
                    <strong>Match:</strong>
                    <span>{match}</span>
                  </div>
                  <div className="flex justify-between py-1">
                    <strong>Question:</strong>
                    <span>{question}</span>
                  </div>
                  <div className="flex justify-between py-1">
                    <strong>Answer:</strong>
                    <span>{answer}</span>
                  </div>
                  <div className="flex justify-between py-1">
                    <strong>Amount:</strong>
                    <span>{amount}</span>
                  </div>
                  <div className="flex justify-between py-1">
                    <strong>Return Rate:</strong>
                    <span>{return_rate}</span>
                  </div>
                  <div className="flex justify-between py-1">
                    <strong>Return Amount (Won):</strong>
                    <span>{return_amount}</span>
                  </div>
                  <div className="flex justify-between py-1">
                    <strong>Win/Loss:</strong>
                    <span>
                      {win_loss === 1 ? (
                        <p className="bg-green-700 text-white text-center py-1 rounded">
                          Win
                        </p>
                      ) : win_loss === 2 ? (
                        <p className="bg-red-700 text-white text-center py-1 rounded">
                          Loss
                        </p>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between py-1">
                    <strong>Date:</strong>
                    <span>{date}</span>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      {/* Desktop View */}
      <div className="overflow-x-auto mt-5 border border-emerald-950 rounded hidden lg:block">
        <table className="min-w-full divide-y divide-emerald-950">
          <thead className="bg-gray-800 text-emerald-400 uppercase text-xs font-semibold">
            <tr>
              <th className="px-6 py-3 text-left">SN</th>
              <th className="px-6 py-3 text-left">Match</th>
              <th className="px-6 py-3 text-left">Question</th>
              <th className="px-6 py-3 text-left">Answer</th>
              <th className="px-6 py-3 text-left">Amount</th>
              <th className="px-6 py-3 text-left">Return Rate</th>
              <th className="px-6 py-3 text-left">Return Amount (Won)</th>
              <th className="px-6 py-3 text-left">Win/Loss</th>
              <th className="px-6 py-3 text-left">Date</th>
            </tr>
          </thead>
          <tbody>
            {bets.length === 0 ? (
              <tr>
                <td colSpan="9" className="text-center py-4">
                  No bets found.
                </td>
              </tr>
            ) : (
              bets.map((record, idx) => {
                const {
                  match,
                  question,
                  answer,
                  amount,
                  return_rate,
                  return_amount,
                  win_loss,
                  date,
                } = record;
                return (
                  <tr key={idx} className="bg-gray-900 text-white">
                    <td className="px-6 py-4 text-sm font-medium">{idx + 1}</td>
                    <td className="px-6 py-4 text-sm font-medium">{match}</td>
                    <td className="px-6 py-4 text-sm font-medium">{question}</td>
                    <td className="px-6 py-4 text-sm font-medium">{answer}</td>
                    <td className="px-6 py-4 text-sm font-medium">{amount}</td>
                    <td className="px-6 py-4 text-sm font-medium">{return_rate}</td>
                    <td className="px-6 py-4 text-sm font-medium">{return_amount}</td>
                    <td className="px-6 py-4 text-sm font-medium">
                      {win_loss === 1 ? (
                        <p className="bg-green-700 text-white text-center py-1 rounded">
                          Win
                        </p>
                      ) : win_loss === 2 ? (
                        <p className="bg-red-700 text-white text-center py-1 rounded">
                          Loss
                        </p>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </td>
                    <td className="px-6 py-4 text-sm font-medium">{date}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BetHistory;
