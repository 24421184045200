import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { BiSolidCricketBall } from "react-icons/bi";
import { GoArrowBoth } from "react-icons/go";
import { useRemoveBettingMatchMutation } from "../../../../features/api/bettingmatchApi";
import { toast } from "react-toastify";
import HiddenMatchModal from "./HiddenMatchModal";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import AccordionAction1Modal from "./AccordionAction1Modal";
import {
  useEditQuestionAriaVisibilityMutation,
  useEditQuestionVisibilityMutation,
} from "../../../../features/api/getquestionApi";

import PopupMenu from "./PopupMenu";
import DefaultAddItemModal from "./DefaultAddItemModal";
import SetDefaultActionModal from "./SetDefaultActionModal";
import {
  useEditDefaultQuestionMutation,
  useEditSetDefaultMatchMutation,
  useGetAllSetDefaltMatchQuery,
  useGetDefaultAnswerQuery,
  useGetDefaultQuestionQuery,
  useRemoveDefaultAnswerMutation,
} from "../../../../features/api/setdefaultmatchApi";
import DefaultAnswerRateModal from "./DefaultAnswerRateModal";
import ball from "../../../../Assets/photos/ball.png";
import FootballImg from "../../../../Assets/photos/football.png";
import TableTennisImg from "../../../../Assets/photos/table-tennis.png";

const SetDefaultMatch = () => {
  const [openClubInfoModal, setOpenClubInfoModal] = useState(false);
  const handleAddItemModal = (data) => {
    setOpenClubInfoModal(true);
  };
  const handleCloseClubInfoModal = () => setOpenClubInfoModal(false);

  const [openHiddenMatchModal, setOpenHiddenMatchModal] = useState(false);
  const handleHiddenMatchModal = (data) => {
    setOpenHiddenMatchModal(true);
  };
  const handleCloseHiddenMatchModal = () => setOpenHiddenMatchModal(false);

  const [sendToAction, setSendToAction] = useState(null);
  const [openActionModal, setOpenActionModal] = useState(false);
  const handleActionModal = (data) => {
    setOpenActionModal(true);
    setSendToAction(data);
  };
  const handleCloseActionModal = () => setOpenActionModal(false);

  const [sendToAccordionAction1, setSendToAccordionAction1] = useState(null);
  const [openAccordionAction1Modal, setOpenAccordionAction1Modal] =
    useState(false);
  const handleAccordionAction1Modal = (data) => {
    setOpenAccordionAction1Modal(true);
    setSendToAccordionAction1(data);
  };
  const handleCloseAccordionAction1Modal = () =>
    setOpenAccordionAction1Modal(false);

  const [openPopupMenu, setOpenPopupMenu] = useState(false);
  const [recvAnsData, setRcvAnsData] = useState(null);
  const handleAnswerPopupMenu = (data) => {
    console.log(data);
    setOpenPopupMenu(true);
    setRcvAnsData(data);
  };
  const handleUserPopupMenu = () => {
    setOpenPopupMenu(true);
  };
  const handleClosePopupMenu = () => setOpenPopupMenu(false);

  const { data, isLoading } = useGetAllSetDefaltMatchQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const [getMatchId, setGetMatchId] = useState(null);

  const { data: getDefaultQuestion, isLoading: getDefaultQuestionLoading } =
    useGetDefaultQuestionQuery(getMatchId, {
      refetchOnMountOrArgChange: true,
    });

  const [getMatch1Id, setGetMatch1Id] = useState(null);

  const { data: getDefaultAnswer, isLoading: getDefaultAnswerLoading } =
    useGetDefaultAnswerQuery(
      { getMatch1Id, getMatchId },
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const [removeDefaultAnswer] = useRemoveDefaultAnswerMutation();
  const handleDefaultAnswerDelete = async (id) => {
    try {
      const confirm = window.confirm(
        "Are you sure you want to delete this item?"
      );

      if (confirm) {
        const response = await removeDefaultAnswer(id);
        if (response?.data) {
          toast.success(response?.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [expanded2, setExpanded2] = useState(false);

  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded2(isExpanded ? panel : false);
  };

  const [editSetDefaultMatch] = useEditSetDefaultMatchMutation();
  const [editDefaultQuestion] = useEditDefaultQuestionMutation();

  const [editQuestionVisibility] = useEditQuestionVisibilityMutation();
  const [editQuestionAriaVisibility] = useEditQuestionAriaVisibilityMutation();

  const handleUpdateDefaultAria = async (item) => {
    try {
      const sendData = {
        id: item?.id,
        aria_hide_show: item?.aria_hide_show == "1" ? "0" : "1",
      };
      const response = await editSetDefaultMatch(sendData);
      if (response?.data) {
        // toast.success(response?.data?.message);
        setExpanded(sendData.aria_hide_show === "0" ? item?.id : false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleQuestionActiveStatus = async (item, val) => {
    try {
      if (val === 1) {
        const sendData1 = {
          id: item?.id,
          aria_hide_show: item?.aria_hide_show == "1" ? "0" : "1",
        };
        const response = await editDefaultQuestion(sendData1);
        if (response?.data) {
          // toast.success(response?.data?.message);
          setExpanded2(sendData1.aria_hide_show === "0" ? item?.id : false);
        }
      } else if (val === 2) {
        const sendData2 = {
          id: item?.id,
          hide: item?.hide == "1" ? "0" : "1",
        };
        const response = await editQuestionVisibility(sendData2);
        if (response?.data) {
          toast.success(response?.data?.message);
        }
      } else if (val === 3) {
        const sendData3 = {
          id: item?.id,
          ariaHide: item?.ariaHide == "1" ? "0" : "1",
        };
        const response = await editQuestionAriaVisibility(sendData3);
        if (response?.data) {
          toast.success(response?.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [bettingRemove] = useRemoveBettingMatchMutation();
  const itemRemove = async (id) => {
    const confirm = window.confirm("Are you sure?");
    if (confirm) {
      try {
        const response = await bettingRemove(id);
        if (response?.data) {
          toast.success(response?.data?.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div className="mt-16">
      <div className="m-5 bg-white rounded text-black p-4">
        <div className="flex gap-x-1">
          <button
            onClick={() => handleAddItemModal()}
            className="p-2 bg-cyan-700 text-white rounded font-bold flex items-center gap-x-1"
          >
            <IoMdAdd size={22} /> <span>Add Item</span>
          </button>
        </div>
        <div className="my-6">
          <p className="mb-2 text-orange-500 font-bold">Default Match</p>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            <div className="">
              {data?.map((item) => {
                return (
                  <div
                    key={item?.id}
                    onClick={() => {
                      setGetMatchId(item?.id);
                    }}
                    className=""
                  >
                    <Accordion
                      expanded={expanded === item?.id}
                      onChange={
                        item?.aria_hide_show === "1"
                          ? handleChange(item?.id)
                          : null
                      }
                      // onChange={handleChange(item?.id)}
                      className="p-2 bg-emerald-900 text-white border-b"
                    >
                      <div className="flex flex-wrap items-center gap-x-1">
                        <AccordionSummary
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="flex items-center">
                            {item?.g_type == 1 ? (
                              <img
                                alt="Ball"
                                src={FootballImg}
                                className=" h-[35px] w-[35px] my-2"
                              />
                            ) : item?.g_type == 2 ? (
                              <img
                                alt="Ball"
                                src={ball}
                                className=" h-[35px] w-[35px] my-2"
                              />
                            ) : (
                              <img
                                alt="Ball"
                                src={TableTennisImg}
                                className=" h-[35px] w-[35px] my-2"
                              />
                            )}
                            <span className="font-bold">{item?.team_a}</span>
                            <GoArrowBoth size={22} />
                            <span className="font-bold">{item?.team_b}</span>
                          </Typography>
                        </AccordionSummary>

                        <button
                          onClick={() => handleActionModal(item)}
                          className="bg-cyan-700 font-bold text-white p-2 rounded-sm"
                        >
                          Action
                        </button>
                        <button onClick={() => handleUpdateDefaultAria(item)}>
                          {item?.aria_hide_show == "0" ? (
                            <span className="bg-red-700 font-bold text-white p-2 rounded-sm">
                              Aria Open
                            </span>
                          ) : (
                            <span className="bg-cyan-700 font-bold text-white p-2 rounded-sm">
                              Aria Hide
                            </span>
                          )}
                        </button>
                      </div>
                      {getDefaultQuestionLoading ? (
                        <p>Loading...</p>
                      ) : (
                        getDefaultQuestion?.map((ques) => {
                          return (
                            <div
                              onClick={() => setGetMatch1Id(ques?.id)}
                              className="py-0.5"
                              key={ques?.id}
                            >
                              <Accordion
                                expanded={expanded2 === ques?.id}
                                onChange={
                                  ques?.aria_hide_show === "1"
                                    ? handleChange2(ques?.id)
                                    : null
                                }
                                // onChange={handleChange2(ques?.id)}
                                className=" bg-gray-200"
                              >
                                <div className="flex flex-wrap items-center gap-x-1">
                                  <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography>{ques?.title}</Typography>
                                  </AccordionSummary>
                                  <button
                                    onClick={() =>
                                      handleAccordionAction1Modal(ques)
                                    }
                                    className="bg-emerald-700 text-white p-2 rounded-sm"
                                  >
                                    Action
                                  </button>
                                  <button
                                    onClick={() =>
                                      handleQuestionActiveStatus(ques, 1)
                                    }
                                    // className="bg-emerald-700 text-white p-2 rounded-sm"
                                  >
                                    {ques?.aria_hide_show == "0" ? (
                                      <span className="bg-emerald-700 font-bold text-white p-2 rounded-sm">
                                        Aria Hide
                                      </span>
                                    ) : (
                                      <span className="bg-red-700 font-bold text-white p-2 rounded-sm">
                                        Area Open
                                      </span>
                                    )}
                                  </button>
                                </div>
                                <AccordionDetails className="bg-gray-500 flex items-center gap-x-2 text-white rounded-sm">
                                  <div className="w-full overflow-auto">
                                    <table className="w-full border-collapse">
                                      <thead>
                                        <tr>
                                          <th className="border p-1">Answer</th>
                                          <th className="border p-1">
                                            Bet Rate
                                          </th>
                                          <th className="border p-1">Action</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {getDefaultAnswerLoading ? (
                                          <p>Loading...</p>
                                        ) : (
                                          getDefaultAnswer?.map((d, i) => {
                                            return (
                                              <tr key={d?.id}>
                                                <td className="border p-1 text-center">
                                                  {d?.title}
                                                </td>
                                                <td className="border p-1">
                                                  <DefaultAnswerRateModal
                                                    passData={d}
                                                  />
                                                </td>
                                                <td className="border p-1 font-normal">
                                                  <div>
                                                    <button
                                                      onClick={() =>
                                                        handleDefaultAnswerDelete(
                                                          d?.id
                                                        )
                                                      }
                                                      className="py-1 px-3 rounded-sm hover:bg-red-900 bg-red-700 text-white block mx-auto"
                                                    >
                                                      Delete
                                                    </button>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          );
                        })
                      )}
                    </Accordion>
                  </div>
                );
              })}
            </div>
          )}
          </div>
      </div>
      <DefaultAddItemModal
        openModal={openClubInfoModal}
        closeModal={handleCloseClubInfoModal}
      />
      <HiddenMatchModal
        openModal={openHiddenMatchModal}
        closeModal={handleCloseHiddenMatchModal}
      />
      <SetDefaultActionModal
        data={sendToAction}
        openModal={openActionModal}
        closeModal={handleCloseActionModal}
      />
      <AccordionAction1Modal
        data={sendToAccordionAction1}
        openModal={openAccordionAction1Modal}
        closeModal={handleCloseAccordionAction1Modal}
      />
      <PopupMenu
        openPopUp={openPopupMenu}
        closePopUp={handleClosePopupMenu}
        datas={recvAnsData}
      />
    </div>
  );
};

export default SetDefaultMatch;
