import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";
import { useAddClubMutation, useSignupMutation } from "../../../features/api/loginApi";
import { toast } from "react-toastify";
import { useGetUsersQuery } from "../../../features/api/usersApi";

const AddClubForm = ({ closeForm, openForm }) => {
  useEffect(() => {
    if (openForm) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openForm]);

  // const FormClose = (e) => {
  //     if (e.target.id === 'FormBackground')
  //         closeForm()
  // }

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [commRate, setCommRate] = useState("");
  const [totalCommRate, setTotalCommRate] = useState("");
  const [everCommRate, setEverCommRate] = useState("");

  const { data: users } = useGetUsersQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const filterClub = users?.users?.filter((user) => user?.role === "club");

  let matched = false;
  users?.users?.forEach((u) => {
    if (u?.userId === userName) {
      matched = true;
    }
  });

  const [addClub]=useAddClubMutation()
  const handleSubmit = async (e) => {
    e.preventDefault();
    const newClub = {
      name:name,
      userId: userName,
      role: "club",
      mobileNumber,
      club_commission_rate: commRate,
      club_commission_over_under_rate: totalCommRate,
      club_commission_every_over_under_rate: everCommRate,
      password,
    };
    console.log(newClub)

    if (matched) {
      toast.warning("ভাই, এই নামে একজন আছে মাপ করেন");
    } else {
      try {
        const response = await addClub(newClub).unwrap();
        console.log(response);
        if (response) {
          closeForm();
          toast.success("Create Successfully done...");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  if (!openForm) return null;

  return (
    <div
      id="FormBackground"
      // onClick={FormClose}
      className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50"
    >
      <div className="w-11/12 sm:4/5 md:3/5 lg:w-2/5 p-5 rounded bg-white text-black">
        <div className="flex items-center justify-between ">
          <h1 className="text-xl font-bold">
            {" "}
            <span className="pr-3 text-green-400">|</span>Add Club
          </h1>
          <p
            className="text-lg text-gray-500 cursor-pointer"
            onClick={closeForm}
          >
            <FaTimes />
          </p>
        </div>
        <div className="">
          <form onSubmit={handleSubmit} className="mt-5">
            <div className="flex items-center justify-between w-full my-4 ">
              <label htmlFor="name" className="text-sm font-bold ">
                Name <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="name"
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter full name"
                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
              />
            </div>
            <div className="flex items-center justify-between w-full my-4 ">
              <label htmlFor="username" className="text-sm font-bold ">
                User Name <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="username"
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Enter user name"
                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
              />
            </div>
            {matched === true && (
              <label className="text-red-500">Already exist</label>
            )}
            <div className="flex items-center justify-between w-full my-4 ">
              <label htmlFor="mobileNumber" className="lg:text-sm font-bold ">
                Mobile Number <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="mobileNumber"
                onChange={(e) => setMobileNumber(e.target.value)}
                placeholder="Enter mobile number"
                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
              />
            </div>
            <div className="flex items-center justify-between w-full my-4 ">
              <label htmlFor="CommissionRate" className="lg:text-sm font-bold ">
                Commission Rate <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="CommissionRate"
                onChange={(e) => setCommRate(e.target.value)}
                placeholder="Commission Rate"
                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
              />
            </div>
            <div className="flex items-center justify-between w-full my-4 ">
              <label
                htmlFor="TotalOver/UnderCommissionRate"
                className="lg:text-sm font-bold "
              >
                Total Over/Under <br />
                Commission Rate <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="TotalOver/UnderCommissionRate"
                onChange={(e) => setTotalCommRate(e.target.value)}
                placeholder="Total Over/Under Commission Rate"
                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
              />
            </div>
            <div className="flex items-center justify-between w-full my-4 ">
              <label
                htmlFor="EveryOver/UnderCommissionRate"
                className="lg:text-sm font-bold "
              >
                Every Over/Under <br />
                Commission Rate <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="EveryOver/UnderCommissionRate"
                onChange={(e) => setEverCommRate(e.target.value)}
                placeholder="Every Over/Under Commission Rate"
                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
              />
            </div>
            <div className="relative flex items-center justify-between w-full my-4 ">
              <label htmlFor="Password" className="text-sm font-bold">
                Password <span className="text-red-400">*</span>
              </label>
              <div className="relative  w-2/3 " id="Password">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="p-2 rounded w-full text-sm border-2 focus:outline-teal-600 "
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div
                  className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 text-xl cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="bg-green-800 text-white text-sm w-full font-bold p-2 mt-3 rounded"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddClubForm;
