import React from "react";

const DistanceDisplay = ({ item, handleDistanceClick }) => {
  const distance = parseInt(item?.distance);

  const runs = [];
  for (let i = -3; i <= 3; i++) {
    runs.push(distance + i);
  }

  return (
    <div className="mb-1 flex flex-wrap gap-1">
      <button className="bg-[#BF360C] font-bold text-lg text-white px-3 py-2 m- rounded-sm">
        D
      </button>
      {runs?.map((runValue, index) => (
        <button
          key={index}
          onClick={() => handleDistanceClick(runValue, item?.id)}
          className={` ${
            runValue === distance ? "bg-[#558B2F]" : "bg-[#795548]"
          } text-white px-2 rounded-sm text-lg`}
          disabled={runValue < 0}
        >
          {runValue}
        </button>
      ))}
    </div>
  );
};

export default DistanceDisplay;
