import React from "react";
import { useGetMyMembersBetsQuery } from "../../../features/api/mybetsApi";
import { FaSpinner } from 'react-icons/fa'; // Importing the spinner icon

const ClubHistory = () => {
  const { data, isLoading } = useGetMyMembersBetsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const getData = data?.bets;

  const renderStatusButton = (win_loss) => {
    switch (win_loss) {
      case '0':
      return (
        <div className="flex items-center bg-yellow-400 px-4 py-2 rounded text-yellow-800">
          <FaSpinner className="animate-spin w-5 h-5 mr-2 text-yellow-800" /> {/* Loading icon */}
          
        </div>
      );
      case '1':
        return (
          <button className="bg-green-500 text-white px-4 py-2 rounded">
            Win
          </button>
        );
      case '2':
        return (
          <button className="bg-red-500 text-white px-4 py-2 rounded">
            Lose
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <div className="mt-12 p-5">
      <h1 className="text-lg my-4">Club History</h1>
      <div className="w-full">
        {/* Desktop Table */}
        <div className="hidden md:block overflow-x-auto">
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="px-4 py-2 text-left">Sl No</th>
                <th className="px-4 py-2 text-left">Date</th>
                <th className="px-4 py-2 text-left">User ID</th>
                <th className="px-4 py-2 text-left">Match</th>
                <th className="px-4 py-2 text-left">Question</th>
                <th className="px-4 py-2 text-left">Answer</th>
                <th className="px-4 py-2 text-left">Amount</th>
                <th className="px-4 py-2 text-left">Return Rate</th>
                <th className="px-4 py-2 text-left">Return Rate (Won)</th>
                <th className="px-4 py-2 text-left">Win / Lose</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="10" className="text-center py-4">
                    Loading...
                  </td>
                </tr>
              ) : (
                getData?.map((bet, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-4 py-2">{index + 1}</td>
                    <td className="px-4 py-2">{bet.date}</td>
                    <td className="px-4 py-2">{bet.userId}</td>
                    <td className="px-4 py-2">{bet.match}</td>
                    <td className="px-4 py-2">{bet.question}</td>
                    <td className="px-4 py-2">{bet.answer}</td>
                    <td className="px-4 py-2">{bet.amount}</td>
                    <td className="px-4 py-2">{bet.return_rate}</td>
                    <td className="px-4 py-2">{bet.return_amount}</td>
                    <td className="px-4 py-2">
                      {renderStatusButton(bet.win_loss)}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {/* Mobile version listing */}
        <div className="block md:hidden mt-8">
          {isLoading ? (
            <div className="text-center">Loading...</div>
          ) : (
            getData?.map((bet, index) => (
              <div
                key={index}
                className="border p-4 mb-4 rounded-lg shadow-md"
              >
                <h2 className="font-bold">Bet {index + 1}</h2>
                <p><strong>Date:</strong> {bet.date}</p>
                <p><strong>User ID:</strong> {bet.userId}</p>
                <p><strong>Match:</strong> {bet.match}</p>
                <p><strong>Question:</strong> {bet.question}</p>
                <p><strong>Answer:</strong> {bet.answer}</p>
                <p><strong>Amount:</strong> {bet.amount}</p>
                <p><strong>Return Rate:</strong> {bet.return_rate}</p>
                <p><strong>Return Rate (Won):</strong> {bet.return_amount}</p>
                <p><strong>Win / Lose:</strong> {
                  renderStatusButton(bet.win_loss)
                }</p>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ClubHistory;
