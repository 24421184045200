import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import RunsDisplay from "./RunsDisplay ";
import QuestionwaitingModal from "./QuestionwaitingModal";
import QuestionlimiteditModal from "./QuestionlimiteditModal";
import TestingModal from "./TestingModal";
import LimitModal from "./LimitModal";
import {
  useEditOverUnderQuestionHideMutation,
  useEditOverUnderRunDistanceMutation,
  useEditOverunderStatusMutation,
  useEditOverunderVisibilityMutation,
  useUpdateOverUnderRunMutation,
} from "../../../../features/api/bettingmatchApi";
import DistanceDisplay from "./DistanceDisplay";
import { useGetUsersQuery } from "../../../../features/api/usersApi";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  useEditOverAnswerStatusMutation,
  useEditOverAnswerVisibilityMutation,
  useRemoveOverAnswerByIdMutation,
} from "../../../../features/api/getanswerApi";

function LiveMatchEveryOverUnderDetails({
  item,
  handleActionModal,
  everyBets,
  totalOverMatchId,
}) {
  const totalEveryOverBet = everyBets?.reduce(
    (sum, cur) => sum + Number(cur?.betAmount),
    0
  );

  const [editOverunderStatus] = useEditOverunderStatusMutation();
  const [editOverunderVisibility] = useEditOverunderVisibilityMutation();
  const handleOverUnderStatus = async (val, ques) => {
    try {
      if (val == 1) {
        const sendData1 = {
          id: item?.id,
          showStatus: item?.showStatus === "1" ? "0" : "1",
        };
        await editOverunderStatus(sendData1);
      } else if (val == 2) {
        const sendData2 = {
          id: item?.id,
          hide: item?.hide === "1" ? "0" : "1",
        };
        await editOverunderVisibility(sendData2);
      } else if (val == 10) {
        const sendData10 = {
          id: ques?.id,
          showStatus: ques?.showStatus === "1" ? "0" : "1",
        };
        // await editOverunderQueStatus(sendData10);
      } else if (val == 11) {
        const sendData11 = {
          id: ques?.id,
          hide: ques?.hide === "1" ? "0" : "1",
        };
        // await editOverunderQueVisibility(sendData11);
      } else if (val == 12) {
        const sendData12 = {
          id: ques?.id,
          ariaHide: ques?.ariaHide === "1" ? "0" : "1",
        };
        // await editOverunderQueAreaVisibility(sendData12);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [editOverUnderQuestionHide] = useEditOverUnderQuestionHideMutation();
  const handleQuestionHide = async () => {
    try {
      const sendData = {
        id: item?.id,
        qhide: 0,
      };
      await editOverUnderQuestionHide(sendData);
    } catch (error) {
      console.log(error);
    }
  };

  const accessToken = localStorage.getItem("accessToken");
  const user = useGetUsersQuery(accessToken, {
    refetchOnMountOrArgChange: true,
  })?.data?.users?.find((d) => d?.id == item?.user);

  const [updateOverUnderRun] = useUpdateOverUnderRunMutation();
  const handleRunClick = async (runValue, getMId) => {
    try {
      const sendUpData = {
        over_under_title_id: getMId,
        run_value: runValue,
      };
      const response = await updateOverUnderRun(sendUpData);
      if (response?.data) {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [editOverUnderRunDistance] = useEditOverUnderRunDistanceMutation();
  const handleDistanceClick = async (runValue, getMId) => {
    try {
      const sendUpData = {
        over_under_title_id: getMId,
        distance_value: runValue,
      };
      const response = await editOverUnderRunDistance(sendUpData);
      if (response?.data) {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [totalWin, setTotalWin] = useState(null);

  const handleWin = async () => {
    if (totalWin) {
      try {
        const sendWinData = {
          match: item?.matchid,
          overunderid: item?.id,
          over_under_type: item?.over_under_type,
          winrun: totalWin,
        };
        const response = await axios.post(
          "https://rakibvai.munihaelectronics.com/backend/public/api/submit-overunder-win",
          sendWinData,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        // console.log(response);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [editOverAnswerStatus] = useEditOverAnswerStatusMutation();
  const [editOverAnswerVisibility] = useEditOverAnswerVisibilityMutation();
  const [removeOverAnswerById] = useRemoveOverAnswerByIdMutation();
  const handleAnswerActiveStatus = async (d, val) => {
    try {
      if (val === 1) {
        const sendData1 = {
          id: d?.id,
          showStatus: d?.showStatus == "1" ? "0" : "1",
        };
        await editOverAnswerStatus(sendData1);
      } else if (val === 2) {
        const sendData2 = {
          id: d?.id,
          hide: d?.hide == "1" ? "0" : "1",
        };
        await editOverAnswerVisibility(sendData2);
      } else if (val === 3) {
        await removeOverAnswerById(d?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [dropdowns, setDropdowns] = useState({});
  const toggleDropdown = (id) => {
    setDropdowns((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleRestart = async (id) => {
    try {
      const sendData = {
        match: item?.matchid,
        que: id,
        question_type: "every",
      };
      const response = await axios.post(
        "https://rakibvai.munihaelectronics.com/backend/public/api/restart-win",
        sendData,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const [expanded, setExpanded] = useState(item?.ariaHide === "0");

  useEffect(() => {
    setExpanded(item?.ariaHide === "0");
  }, [item?.ariaHide]);

  const handleAccordionChange = (isExpanded) => {
    if (item?.ariaHide !== "1") {
      setExpanded(isExpanded);
    }
  };
  return (
    <Accordion
      expanded={expanded}
      onChange={(event, isExpanded) => handleAccordionChange(isExpanded)}
      className="mt-1 bg-gray-200"
    >
      <div className="bg-gray-500">
        <AccordionSummary
          aria-controls={`panel${item?.id}-content`}
          id={`panel${item?.id}-header`}
        >
          <p className="text-sm my-">{item?.title} </p>
        </AccordionSummary>
        <div
          className="mb- flex flex-wrap items-center gap-1 pl-1"
          style={{ paddingBottom: "5px" }}
        >
          <button
            onClick={() => handleActionModal(item)}
            className="bg-cyan-700 font-bold text-white p-2 rounded-sm"
          >
            Action
          </button>
          <button onClick={() => handleOverUnderStatus(1)}>
            {item?.showStatus === "0" ? (
              <span className="bg-red-700 font-bold text-white p-2 rounded-sm">
                Active
              </span>
            ) : (
              <span className="bg-green-700 font-bold text-white p-2 rounded-sm">
                Stop
              </span>
            )}
          </button>
          <button onClick={() => handleOverUnderStatus(2)}>
            {item?.hide === "0" ? (
              <span className="bg-red-700 font-bold text-white p-2 rounded-sm">
                Show
              </span>
            ) : (
              <span className="bg-green-700 font-bold text-white p-2 rounded-sm">
                Hide
              </span>
            )}
          </button>
          <button>
            {item?.ariaHide === "0" ? (
              <span className="bg-green-700 font-bold text-white p-2 rounded-sm">
                Area Hide
              </span>
            ) : (
              <span className="bg-red-700 font-bold text-white p-2 rounded-sm">
                Area Show
              </span>
            )}
          </button>
          <button
            onClick={handleQuestionHide}
            className="bg-green-700 font-bold text-white p-2 rounded-sm"
          >
            Q-Hide
          </button>
          <span className="font-semibold">|| operator ({user?.name})</span>
          <p className="bg-red-500 rounded-sm mr-2 text-white font-bold px-2">
            {everyBets?.length}
          </p>{" "}
          <p className="bg-cyan-600 rounded-sm text-white font-bold px-2">
            {totalEveryOverBet}
          </p>
        </div>
      </div>

      <AccordionDetails className="bg-[#78909C]">
        <RunsDisplay
          key={item?.id}
          item={item}
          handleRunClick={handleRunClick}
        />
        <DistanceDisplay
          key={item?.id}
          item={item}
          handleDistanceClick={handleDistanceClick}
        />

        <div className="mb-1">
          <button className="bg-[#BF360C] font-bold px-3 text-white p-2 mr-1 rounded-sm">
            W
          </button>
          <input
            className="shadow-lg"
            type="number"
            value={totalWin}
            onChange={(e) => setTotalWin(Number(e.target.value))}
            style={{
              border: "1px solid gray",
              width: "40%",
              height: "35px",
              padding: "5px",
            }}
            placeholder="Enter win Run"
          />
          <button
            onClick={() => handleWin()}
            className="bg-[#558B2F] font-bold px-3 text-white p-2 ml-1 rounded-sm"
          >
            Win
          </button>
        </div>
        {/* win section */}

        {item?.subtitles?.map((subtitle) => {
          const filterSingleEveryOverBet = everyBets?.filter(
            (oBet) =>
              oBet?.matchId == subtitle?.bettingId &&
              oBet?.questionId == subtitle?.id
          );
          const totalSingleEveryOverBet = filterSingleEveryOverBet?.reduce(
            (sum, cur) => sum + Number(cur?.betAmount),
            0
          );
          return (
            <Accordion key={subtitle?.id} defaultExpanded={true}>
              <AccordionSummary
                className="bg-[#CFD8DC] "
                aria-controls={`panel${item?.id}-content`}
                id={`panel${item?.id}-header`}
              >
                <div className="flex flex-col md:flex-row gap-2">
                  <h1 className="text-sm">{subtitle?.title}</h1>
                  <div className="flex flex-wrap items-center gap-x-1 gap-y-2">
                    <button
                      onClick={() => handleActionModal(item)}
                      className="bg-cyan-700 font-bold text-white p-2 rounded-sm"
                    >
                      Action
                    </button>
                    <QuestionlimiteditModal datas={subtitle} />
                    <QuestionwaitingModal datas={subtitle} />
                    <button>
                      {subtitle?.showStatus === "0" ? (
                        <span className="bg-emerald-700 font-bold text-white p-2 rounded-sm  uppercase">
                          Active
                        </span>
                      ) : (
                        <span className="bg-red-700 font-bold text-white p-2 rounded-sm uppercase">
                          Stop
                        </span>
                      )}
                    </button>
                    <button>
                      {subtitle?.hide === "0" ? (
                        <span className="bg-emerald-700 font-bold text-white p-2 rounded-sm uppercase">
                          Show
                        </span>
                      ) : (
                        <span className="bg-red-700 font-bold text-white p-2 rounded-sm uppercase">
                          Hide
                        </span>
                      )}
                    </button>
                    <button>
                      {subtitle?.ariaHide === "0" ? (
                        <span className="bg-emerald-700 font-bold text-white p-2 rounded-sm">
                          Area Hide
                        </span>
                      ) : (
                        <span className="bg-red-700 font-bold text-white p-2 rounded-sm">
                          Area Show
                        </span>
                      )}
                    </button>
                    <p className="bg-red-500 rounded-sm mr-2 text-white font-bold px-2">
                      {filterSingleEveryOverBet?.length}
                    </p>{" "}
                    <p className="bg-cyan-600 rounded-sm text-white font-bold px-2">
                      {totalSingleEveryOverBet}
                    </p>
                  </div>
                </div>
              </AccordionSummary>

              <AccordionDetails className="bg-[#78909C]">
                <div>
                  <div className="bg-[#78909C] shadow-lg flex items-center gap-x-2 text-white rounded-sm">
                    <div className="w-full px-4 mt-1 overflow-auto">
                      <table className="w-full border-collapse">
                        <thead>
                          <tr>
                            <th className="border p-2">Answer</th>
                            <th className="border p-2">Bet Rate</th>
                            <th className="border p-2">Place Bet</th>
                            <th className="border p-2">Betting Amount</th>
                            <th className="border p-2">Return Amount</th>
                            <th className="border p-2">Action</th>
                            <th className="border p-2">Limit</th>
                          </tr>
                        </thead>

                        <tbody>
                          {subtitle?.options.map((option) => {
                            const filterAnswer = everyBets?.filter(
                              (ad) =>
                                ad?.matchId == totalOverMatchId &&
                                ad?.questionId == option?.bettingSubTitleId &&
                                ad?.answerId == option?.id
                            );
                            const betResult = filterAnswer?.find((af) => af);
                            const totalAnsBetAmt = filterAnswer?.reduce(
                              (sum, cur) => sum + Number(cur?.betAmount),
                              0
                            );
                            const totalAnsReturnBetAmt = filterAnswer?.reduce(
                              (sum, cur) => sum + Number(cur?.returnAmount),
                              0
                            );
                            return (
                              <tr key={option?.id}>
                                <td className="border p-2 text-center">
                                  {option?.title}
                                </td>
                                <td className="border p-2">
                                  <TestingModal passData={option} />
                                </td>
                                <td className="border p-2 text-center">
                                  {" "}
                                  {filterAnswer?.length}
                                </td>
                                <td className="border p-2 text-center">
                                  {totalAnsBetAmt}
                                </td>
                                <td className="border p-2 text-center">
                                  {totalAnsReturnBetAmt}
                                </td>
                                <td className="border p-2 font-normal relative">
                                  {/* <button className="py-1 px-3 rounded-sm hover:bg-emerald-900 bg-emerald-700 text-white">
                                    Win
                                  </button> */}
                                  {betResult?.betStatus == 0 ? (
                                    <></>
                                  ) : betResult?.betStatus == 1 ? (
                                    <button className="float-left p-1 rounded-sm hover:bg-emerald-900 bg-emerald-700 text-white">
                                      wined
                                    </button>
                                  ) : betResult?.betStatus == 2 ? (
                                    <button className="float-left p-1 rounded-sm hover:bg-emerald-900 bg-red-700 text-white">
                                      Lose
                                    </button>
                                  ) : null}
                                  <button
                                    onClick={() => toggleDropdown(option.id)}
                                    className="py-1 px-3 mx-auto block rounded-sm hover:bg-emerald-900 bg-emerald-700 text-white"
                                  >
                                    Action
                                  </button>
                                  {dropdowns[option?.id] && (
                                    <div className="absolute top-full right-0 mt-5 bg-white text-black rounded shadow-lg z-50">
                                      <ul>
                                        <Link
                                          to={
                                            "/admin/betting-panel/user-actions"
                                          }
                                          state={option}
                                        >
                                          <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">
                                            User Action
                                          </li>
                                        </Link>

                                        <li
                                          onClick={() =>
                                            handleAnswerActiveStatus(option, 1)
                                          }
                                          className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                                        >
                                          {option?.showStatus == 0 ? (
                                            <span className="text-red-500 font-bold">
                                              Open
                                            </span>
                                          ) : (
                                            <span className="text-green-500 font-bold">
                                              Stop
                                            </span>
                                          )}
                                        </li>
                                        <li
                                          onClick={() =>
                                            handleAnswerActiveStatus(option, 2)
                                          }
                                          className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                                        >
                                          {option?.hide == 0 ? (
                                            <span className="text-red-500 font-bold">
                                              Show
                                            </span>
                                          ) : (
                                            <span className="text-green-500 font-bold">
                                              Hide
                                            </span>
                                          )}
                                        </li>
                                        <li
                                          onClick={() =>
                                            handleAnswerActiveStatus(option, 3)
                                          }
                                          className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                                        >
                                          Delete
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </td>
                                <td className="border p-2">
                                  <LimitModal passData={option} />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th className=""></th>
                            <th className=""></th>
                            <th className="border p-2">
                              <button
                                onClick={() => handleRestart(subtitle?.id)}
                                className="bg-red-700 hover:bg-red-800 text-white px-4 py-1 rounded-sm"
                              >
                                Restart
                              </button>
                            </th>
                            <th className="border p-2">
                              Total = {totalSingleEveryOverBet}
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}

export default LiveMatchEveryOverUnderDetails;
