import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { FaAngleDown, FaList } from "react-icons/fa";
import { useGetAlldwnotificationsQuery, useGetUsersQuery } from "../../../../features/api/usersApi";
import { toast } from "react-toastify";
import { useGetPaymentMethodQuery } from "../../../../features/api/paymentmethodApi";
import { formatDateTime } from "../../../../utils/formatDateTime";


const UserDepositInbox = () => {
  const [searchText1, setSearchText1] = useState("");
  const [searchText2, setSearchText2] = useState("");

  const accessToken = localStorage.getItem("accessToken");

  const users = useGetUsersQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.users;

  const { data = [], isLoading } = useGetAlldwnotificationsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const depositHistory = data?.dwnotifications;
  

  const filterDepositForZeroOne = depositHistory?.filter(
    (depos) =>
      (depos?.deposit != null && depos?.action == 0) || depos?.deposit != null && depos?.action == 1
  );

  const filterDepositForTwo = depositHistory?.filter(
    (depos) => depos?.action == 2
  );
  

  const depositPaymentMethods = useGetPaymentMethodQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.payment_methods;

  const handleDepositAction = async (e, id) => {
    e.preventDefault();
    const status = +e.target.value;
    const confirm = window.confirm("Do you change the action?");
    if (confirm) {
      try {
        const response = await axios.post(
          `https://rakibvai.munihaelectronics.com/backend/public/api/deposit-action/${id}/${status}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response) {
          setTimeout(() => {
            toast.success("Successfully change the action...!");
            window.location.reload();
          }, 0.5);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [handleShow, setHandleShow] = useState(false);
  const [active, setActive] = useState(null);
  const handleDropdown = (i) => {
    setActive(i);
    setHandleShow(!handleShow);
  };

  const columns = [
    {
      name: "Sl No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "User Id",
      selector: (row) => row.userId,
      sortable: true,
    },
    {
      name: "User Balance",
      selector: (row) => {
        const getUserBalance = users?.find((u) => u?.userId == row?.userId);
        return <div>{getUserBalance?.balance}</div>;
      },
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.deposit,
      sortable: true,
    },
    {
      name: "From",
      selector: (row) => row.from_number,
      sortable: true,
    },
    {
      name: "To",
      selector: (row) => row.to_number,
      sortable: true,
    },
    {
      name: "Method",
      selector: (row) => {
        const name = depositPaymentMethods?.find(
          (m) => m?.id == row?.payment_method
        );
        return <p>{name?.name}</p>;
      },
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => {
        return <div>{formatDateTime(row.created_at)}</div>;
      },
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <select
            className="p-2 bg-cyan-600 text-white font-semibold rounded"
            value={row?.action} // Set the value to the current action
            onChange={(e) => handleDepositAction(e, row?.id)}
          >
            <option value={0}>Requested</option>
            <option value={1}>Processing</option>
            <option value={2}>Processed</option>
            <option value={3}>Cancel</option>
          </select>
        </div>
      ),
      sortable: true,
      width: "150px",
    },
  ];
  const columns2 = [
    {
      name: "Sl No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "User Id",
      selector: (row) => row.userId,
      sortable: true,
    },
    {
      name: "User Balance",
      selector: (row) => {
        const getUserBalance = users?.find((u) => u?.userId == row?.userId);
        return <div>{getUserBalance?.balance}</div>;
      },
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.deposit,
      sortable: true,
    },
    {
      name: "From",
      selector: (row) => row.from_number,
      sortable: true,
    },
    {
      name: "To",
      selector: (row) => row.to_number,
      sortable: true,
    },
    {
      name: "Method",
      selector: (row) => {
        const name = depositPaymentMethods?.find(
          (m) => m?.id == row?.payment_method
        );
        return <p>{name?.name}</p>;
      },
      sortable: true,
    },

    {
      name: "Date",
      selector: (row) => {
        return <div>{formatDateTime(row.created_at)}</div>;
      },
      sortable: true,
      width: "200px",
    },

    {
      name: "User Previous Balance",
      selector: (row) => row.amount,
      sortable: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        color: "white",
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: "teal",
      },
    },
  };
  const customFilter = (rows, searchText) => {
    if (!Array.isArray(rows)) {
        return []; // Return an empty array if rows is not an array
    }

    return rows.filter(row => {
        return Object.values(row).some(value =>
            String(value).toLowerCase().includes(searchText.toLowerCase())
        );
    });
};

// Check if data is loading or not available
if (isLoading) {
    return <div>Loading...</div>;
}

  return (
    <div className="mt-10">
      <div className="bg-white text-black p-5 flex gap-2 ">
        <FaList className="text-xl mt-2"></FaList>
        <h1 className="text-2xl font-semibold">User deposit inbox</h1>
      </div>
      <div className="m-5 bg-white rounded ">
        <div className="flex items-center px-5 py-4 ">
          <label
            htmlFor="search"
            className="text-black text-sm font-semibold p-1"
          >
            Search:
          </label>
          <input
            type="text"
            id="search"
            placeholder="search"
            className=" text-black text-sm border-2 px-2 py-1 rounded focus:outline-teal-600"
            value={searchText1}
            onChange={(e) => setSearchText1(e.target.value)}
          />
        </div>
        <div className="px-5 z-10">
          <DataTable
            columns={columns}
            data={customFilter(filterDepositForZeroOne, searchText1)}
            customStyles={customStyles}
            sortIcon={<FaAngleDown className="m-1" />}
            defaultSortAsc={true}
            paginationRowsPerPageOptions={[50, 100, 150]}
            paginationPerPage={100}
            pagination
            highlightOnHover
          />
        </div>
      </div>
      <div className="m-5 bg-white rounded ">
        <div className="flex items-center px-5 py-4 ">
          <label
            htmlFor="search"
            className="text-black text-sm font-semibold p-1"
          >
            Search:
          </label>
          <input
            type="text"
            id="search"
            placeholder=""
            className=" text-black text-sm border-2 px-2 py-1 rounded focus:outline-teal-600"
            value={searchText2}
            onChange={(e) => setSearchText2(e.target.value)}
          />
        </div>
        <div className="px-5">
          <DataTable
            columns={columns2}
            data={customFilter(filterDepositForTwo, searchText2)}
            customStyles={customStyles}
            sortIcon={<FaAngleDown className="m-1" />}
            defaultSortAsc={true}
            paginationRowsPerPageOptions={[50, 100, 150]}
            paginationPerPage={100}
            pagination
            highlightOnHover
          />
        </div>
      </div>
    </div>
  );
};

export default UserDepositInbox;
