import { apiSlice } from "./apiSlice";

const getanswerApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAnswer: builder.query({
      query: ({ getMatch1Id, getMatchId }) => ({
        url: `/get-answer/${getMatch1Id}/${getMatchId}`,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["get-answer"],
    }),
    getLiveAnswer: builder.query({
      query: ({ questionId, getMatchId }) => ({
        url: `/get-live-match-question-answer/${questionId}/${getMatchId}`,
      }),
      providesTags: ["get-answer"],
    }),
    addNewAnswer: builder.mutation({
      query: (data) => ({
        url: "/add-answer",
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["get-answer"],
    }),
    addNewCopyQuesAns: builder.mutation({
      query: (data) => ({
        url: "/copy-question-and-answers",
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["get-answer", "get-all-matches"],
    }),
    addSetDefaultAnswer: builder.mutation({
      query: (data) => ({
        url: "/add-default-answer",
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: data,
      }),
      invalidatesTags: [
        "get-answer",
        "get-default-answers",
        "get-default-questions",
        "get-all-defualt-matches",
      ],
    }),
    removeAnswer: builder.mutation({
      query: (id) => ({
        url: `/delete-withdraw-sending-number/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["get-answer"],
    }),
    editAnswerRate: builder.mutation({
      query: (data) => ({
        url: `/edit-answer`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["get-answer"],
    }),
    editOverUnderAnswerRate: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/edit-over-under-answer/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["get-answer"],
    }),
    editAnswerLimit: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-answer-limit-amount/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-answer"],
    }),
    editOverAnswerLimit: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-over-under-answer-limit-amount/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-answer"],
    }),
    editAnswerStatus: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-answer-status/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-answer"],
    }),
    editOverAnswerStatus: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-over-under-answer-status/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-answer"],
    }),
    editAnswerVisibility: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-answer-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-answer"],
    }),
    editOverAnswerVisibility: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-over-under-answer-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-answer"],
    }),
    removeAnswerById: builder.mutation({
      query: (id) => ({
        url: `/delete-answer/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-answer"],
    }),
    removeOverAnswerById: builder.mutation({
      query: (id) => ({
        url: `/update-over-under-answer-delete/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-answer"],
    }),
  }),
});

export const {
  useGetAllAnswerQuery,
  useGetLiveAnswerQuery,
  useAddNewAnswerMutation,
  useAddSetDefaultAnswerMutation,
  useAddNewCopyQuesAnsMutation,
  useRemoveAnswerMutation,
  useEditAnswerRateMutation,
  useEditOverUnderAnswerRateMutation,
  useEditAnswerLimitMutation,
  useEditOverAnswerLimitMutation,
  useEditAnswerStatusMutation,
  useEditOverAnswerStatusMutation,
  useEditAnswerVisibilityMutation,
  useEditOverAnswerVisibilityMutation,
  useRemoveAnswerByIdMutation,
  useRemoveOverAnswerByIdMutation,
} = getanswerApi;
