import { apiSlice } from "./apiSlice";

const paymentmethodApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentMethod: builder.query({
      query: () => ({
        url: "/get-payment-methods-list",
      }),
      providesTags: ["get-payment-methods-list"],
    }),
    addPaymentMethod: builder.mutation({
      query: (data) => ({
        url: "/add-payment-methods",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["get-payment-methods-list"],
    }),
    removePaymentMethod: builder.mutation({
      query: (id) => ({
        url: `/delete-payment-methods/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["get-payment-methods-list"],
    }),
    editPaymentMethod: builder.mutation({
      query: (editData) => ({
        url: `affiliate`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["get-payment-methods-list"],
    }),
  }),
});

export const {
  useGetPaymentMethodQuery,
  useAddPaymentMethodMutation,
  useRemovePaymentMethodMutation,
  useEditPaymentMethodMutation,
} = paymentmethodApi;
