import React, { useState } from "react";

import ball from "../../../../Assets/photos/ball.png";
import FootballImg from "../../../../Assets/photos/football.png";
import TableTennisImg from "../../../../Assets/photos/table-tennis.png";

import PlaceBet from "../../../../components/Modal/PlaceBet/PlaceBet";
import { formatTime } from "../../../../components/SharedDateFormat";
import PlaceBetModel from "../../AllGame/PlaceBetModel.js";
import PlaceBetModal2 from "../../AllGame/PlaceBetModal2.js";

const AccordionPanel = ({ mainA, subA, title, over, subTitle, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border border-emerald-950 rounded my-2">
      <div
        className="flex justify-between items-center px-4 py-2 cursor-pointer"
        onClick={toggleAccordion}
      >
        {mainA == 1 && (
          <div className="flex items-center gap-4 py-1 cursor-pointer">
            {title?.gameType == 1 ? (
              <img
                alt="Ball"
                src={FootballImg}
                className=" h-[35px] w-[35px] my-2"
              />
            ) : title?.gameType == 2 ? (
              <img alt="Ball" src={ball} className=" h-[35px] w-[35px] my-2" />
            ) : (
              <img
                alt="Ball"
                src={TableTennisImg}
                className=" h-[35px] w-[35px] my-2"
              />
            )}
            <div>
              <h1 className="text-base font-semibold">
                {title?.A_team} <span className="text-yellow-400">VS</span>{" "}
                {title?.B_team}
              </h1>
              <p>
                {title?.title}, {formatTime(title?.date)}
              </p>
              <div>
                <span className=" font-semibold text-emerald-500">Score: </span>
                <span className="text-[10px] font-semibold text-[#C6FF00]">
                  {title?.livescore}
                </span>
              </div>
            </div>
          </div>
        )}
        {subA == 2 && (
          <p className="font-medium text-sm">
            <i className="fa-regular fa-star mr-2 " /> {subTitle}
          </p>
        )}
        {subA == 2 && (
          <span className="text-lg">
            {isOpen ? (
              <i class="fa fa-angle-up" aria-hidden="true"></i>
            ) : (
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            )}
          </span>
        )}
      </div>
      {isOpen && (
        <div className="bg-[#16444e] text-[#ffff00] rounded-none">
          {children}
        </div>
      )}
    </div>
  );
};

const FootballCard = ({ live, overUnderDetails, refetch, mStatus }) => {
  const match = live?.match;
  const overUnder = overUnderDetails?.filter((d) => d?.over_under_type == 1);

  const everyOverUnder = overUnderDetails?.filter(
    (d) => d?.over_under_type == 2
  );
  const inningsTotal = overUnderDetails?.filter((d) => d?.over_under_type == 3);

  const [openPlaceBetModal, setOpenPlaceBetModal] = useState(false);
  const [answers, setAnswers] = useState("");
  const [questions, setQuestions] = useState("");

  const handlePlaceBetModal = (answer, question) => {
    setOpenPlaceBetModal(true);
    setAnswers(answer);
    setQuestions(question);
  };
  const handleClosePlaceBetModal = () => setOpenPlaceBetModal(false);

  return (
    <div className="mb-2 shadow">
      {/* {match?.over_under_area == "1" &&
        overUnder?.length > 0 &&
        overUnder
          ?.filter((d) => d?.qhide == "1")
          ?.map((item, idx) => (
            <p key={idx}>
              {item?.subtitles?.map((sub) => {
                return <p key={sub?.id}>{sub?.title}</p>;
              })}
            </p>
          ))} */}

      {/* accordion start status  */}
      {match?.hide == "0" && (
        <div className="nested-accordion ">
          <AccordionPanel title={match} mainA={1}>
            {live?.questions?.map((item) => {
              return (
                <div key={item?.question?.id}>
                  {item?.question?.hide == "1" && (
                    <AccordionPanel subTitle={item?.question?.title} subA={2}>
                      {match?.showStatus == "0" ? (
                        <p className="bg-black text-red-500 text-center py-1 text-lg font-medium">
                          Bet Suspended
                        </p>
                      ) : (
                        <div className="flex gap-2 items-center text-white">
                          {item?.question?.showStatus == "0" ? (
                            <p className="bg-black text-red-500 w-full text-center py-1 text-lg font-medium">
                              Bet Suspended
                            </p>
                          ) : (
                            item?.options
                              ?.filter((d) => d?.hide == 1)
                              ?.map((answer) => (
                                <PlaceBetModel
                                  key={answer?.id}
                                  match={match}
                                  question={item?.question}
                                  answer={answer}
                                  refetch={refetch}
                                  mStatus={mStatus}
                                />
                              ))
                          )}
                        </div>
                      )}
                    </AccordionPanel>
                  )}
                </div>
              );
            })}

            {overUnderDetails?.map((item) => {
              return item?.subtitles?.map((sub) => {
                return (
                  <div key={sub?.id}>
                    {item?.hide == "1" && (
                      <AccordionPanel subTitle={sub?.title} subA={2}>
                        {match?.showStatus == "0" ? (
                          <p className="bg-black text-red-500 text-center py-1 text-lg font-medium">
                            Bet Suspended
                          </p>
                        ) : (
                          <div className="flex gap-2 items-center text-white">
                            {item?.showStatus == "0" ? (
                              <p className="bg-black text-red-500 w-full text-center py-1 text-lg font-medium">
                                Bet Suspended
                              </p>
                            ) : (
                              sub?.options
                                ?.filter((d) => d?.hide == 1)
                                ?.map((option) => {
                                  return (
                                    <PlaceBetModal2
                                      key={option?.id}
                                      match={match}
                                      question={sub}
                                      answer={option}
                                      item={item}
                                      refetch={refetch}
                                      mStatus={mStatus}
                                    />
                                  );
                                })
                            )}
                          </div>
                        )}
                      </AccordionPanel>
                    )}
                  </div>
                );
              });
            })}
          </AccordionPanel>
        </div>
      )}
      {/* accordion End  */}

      <PlaceBet
        icon={ball}
        openModal={openPlaceBetModal}
        closeModal={handleClosePlaceBetModal}
        datas={match}
        answers={answers}
        questions={questions}
        refetch={refetch}
      />
    </div>
  );
};

export default FootballCard;
