import React, { useEffect, useState } from "react";
import { useActionData, useLocation } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import axios from "axios";
import RefundModal from "./RefundModal";

function UserActions() {
  const { state } = useLocation();
  console.log(state)

  const [userActionDatas, setUserActionsDatas] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://rakibvai.munihaelectronics.com/backend/public/api/bets/search?answer_id=${state?.id}&question_id=${state?.bettingSubTitleId}&match_id=${state?.betting_titles_id}`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        setUserActionsDatas(response?.data?.bets);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const [selectedItems, setSelectedItems] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedItems([]);
    } else {
      setSelectedItems(userActionDatas?.map((item) => item));
    }
    setAllSelected(!allSelected);
  };

  const handleSelectItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems?.filter((itm) => itm?.id !== item?.id));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  return (
    <div className="mt-10 bg-[#E5E5E5] text-black h-screen w-full">
      <div className="bg-white p-4 text-xl font-bold flex items-center gap-x-2">
        <span>
          <IoMenu size={22} />
        </span>{" "}
        <p>Data Table</p>
      </div>

      <div className="bg-white m-5 p-4">
        <div className="mb-4 flex  gap-4 items-center">
          <RefundModal datas={selectedItems} />
          <div className="flex items-center gap-2 text-xl">
            <input
              type="checkbox"
              className="ml-2 text-xl"
              checked={allSelected}
              onChange={handleSelectAll}
            />{" "}
            All select
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full border text-center">
            <thead>
              <tr>
                <th className="border p-2">User Id</th>
                <th className="border p-2">Answer Title</th>
                <th className="border p-2">Amount</th>
                <th className="border p-2">Rate</th>
                <th className="border p-2">Time</th>
                <th className="border p-2">Action Completed</th>
                <th className="border p-2">Select</th>
              </tr>
            </thead>
            <tbody>
              {userActionDatas?.length == 0 ? (
                <p className="text-xl font-bold my-20 text-red-600">
                  Loading...
                </p>
              ) : (
                userActionDatas?.map((item) => (
                  <tr key={item?.id}>
                    <td className="border p-2">{item?.userId}</td>
                    <td className="border p-2">{item?.answerTitle}</td>
                    <td className="border p-2">{item?.betAmount}</td>
                    <td className="border p-2">{item?.answerRate}</td>
                    <td className="border p-2">{item?.created_at}</td>
                    <td className="border p-2 text-red-600">
                      {item?.persentage || 0}% Completed
                    </td>
                    <td className="border p-2">
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(item)}
                        onChange={() => handleSelectItem(item)}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default UserActions;
