import { apiSlice } from "./apiSlice";

const bettingmatchApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCombinedMatches: builder.query({
      query: () => ({
        url: "/frontend-combined-api",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["get-all-matches"],
    }),
    getBettingMatch: builder.query({
      query: () => ({
        url: "/get-all-matches",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["get-all-matches"],
    }),
    getCompanyAcc: builder.query({
      query: () => ({
        url: "/company-accounts",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["get-all-matches"],
    }),
    updateCompanyCapital: builder.mutation({
      query: (data) => ({
        url: `/update-company-capital`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    getNotice: builder.query({
      query: () => ({
        url: "/get-notice",
      }),
      providesTags: ["get-all-matches"],
    }),
    updateNotice: builder.mutation({
      query: (data) => ({
        url: `/update-notice`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    getadmindashboardAllCounter: builder.query({
      query: () => ({
        url: "/adminDashboardAllCounter",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["get-all-counter"],
    }),
    getBetTransaction: builder.query({
      query: () => ({
        url: "/bet-transactions",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["get-all-matches"],
    }),
    getCloseMatchHistory: builder.query({
      query: () => ({
        url: "/get-all-closed-matches",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["get-all-matches"],
    }),
    updateClosetoOpenMatch: builder.mutation({
      query: (id) => ({
        url: `/closed-to-open-match/${id}`,
        method: "PUT",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    getAllLiveMatch: builder.query({
      query: () => ({
        url: "/get-all-live-matches-details",
      }),
      providesTags: ["get-all-matches", "get-answer", "get-question"],
    }),
    getAllMatchData: builder.query({
      query: () => ({
        url: "/combined-api",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["get-all-matches", "get-answer", "get-question"],
    }),
    addBettingMatch: builder.mutation({
      query: (data) => ({
        url: `/add-match`,
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    addSubmitWin: builder.mutation({
      query: (data) => ({
        url: `/submit-win`,
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),

    addOverUnder: builder.mutation({
      query: (data) => ({
        url: `/add-total-over-under-betting-question-and-answers`,
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),

    addEveryOverUnder: builder.mutation({
      query: (data) => ({
        url: `/add-every-over-under-betting-question-and-answers`,
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    addInningsOverUnder: builder.mutation({
      query: (data) => ({
        url: `/add-innings-over-under-betting-question-and-answers`,
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),

    getOverunderdetails: builder.query({
      query: (id) => ({
        url: `/get-over-under-betting-details/${id}`,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["get-all-matches"],
    }),

    updateOverUnderRun: builder.mutation({
      query: (data) => ({
        url: `/update-over-under-run`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),

    editOverunderStatus: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-over-under-status/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),

    editOverunderVisibility: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-over-under-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),

    editOverunderQuewaitingTime: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-over-under-question-waiting-time/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),

    editOverunderQueStatus: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-over-under-question-status/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editOverunderQueVisibility: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-over-under-question-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),

    editOverunderQueAreaVisibility: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-over-under-question-area-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),

    editOverunderQueLimitamount: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-over-under-question-limit-amount/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),

    removeBettingMatch: builder.mutation({
      query: (id) => ({
        url: `/delete-match/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    removeCloseTheMatch: builder.mutation({
      query: (id) => ({
        url: `/closed-match/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editBettingMatch: builder.mutation({
      query: (data) => ({
        url: `/rules/1/minimum-balance`,
        method: "PATCH",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editLiveMatchScore: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-match-live-score/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editLiveMatchLimit: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-match-limit-amount/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editLiveMatchWait: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-match-waiting-time/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editLiveMatchStopStatus: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-match-status/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editLiveMatchHideShow: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-match-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editLiveMatchAriaHideShow: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-match-area-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editLiveMatchHideFromPanel: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-match-hide-from-panel/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editUpcomingToLive: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-match-upcomming-to-live/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editOverunderAreaVisibility: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-match-total-over-area-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editEveryOverunderAreaVisibility: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-match-every-over-area-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editInningsAreaVisibility: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-match-innings-over-area-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editOverUnderAriaVisibility: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-match-total-over-area-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editEveryOverUnderAriaVisibility: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-match-every-over-area-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editInningsAriaVisibility: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-match-innings-over-area-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editOverUnderQuestionHide: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-over-under-qhide/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editOverUnderRunDistance: builder.mutation({
      query: (data) => ({
        url: `/update-over-under-run-distance`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    editOverUnderGap: builder.mutation({
      query: (data) => ({
        url: `/update-over-under-gap`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
    updateMatch: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-match/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-all-matches"],
    }),
  }),
});

export const {
  useGetCombinedMatchesQuery,
  useGetAllMatchDataQuery,
  useGetOverunderdetailsQuery,
  useUpdateOverUnderRunMutation,
  useGetBettingMatchQuery,
  useGetCompanyAccQuery,
  useUpdateCompanyCapitalMutation,
  useGetNoticeQuery,
  useUpdateNoticeMutation,
  useGetadmindashboardAllCounterQuery,
  useGetBetTransactionQuery,
  useGetAllLiveMatchQuery,
  useGetCloseMatchHistoryQuery,
  useUpdateClosetoOpenMatchMutation,
  useAddBettingMatchMutation,
  useAddSubmitWinMutation,
  useAddOverUnderMutation,
  useAddEveryOverUnderMutation,
  useAddInningsOverUnderMutation,
  useRemoveBettingMatchMutation,
  useRemoveCloseTheMatchMutation,
  useEditBettingMatchMutation,
  useEditLiveMatchScoreMutation,
  useEditLiveMatchLimitMutation,
  useEditLiveMatchWaitMutation,
  useEditLiveMatchStopStatusMutation,
  useEditLiveMatchHideShowMutation,
  useEditLiveMatchAriaHideShowMutation,
  useEditLiveMatchHideFromPanelMutation,
  useEditOverunderStatusMutation,
  useEditOverunderVisibilityMutation,
  useEditOverunderQuewaitingTimeMutation,
  useEditOverunderQueStatusMutation,
  useEditOverunderQueVisibilityMutation,
  useEditOverunderQueAreaVisibilityMutation,
  useEditOverunderQueLimitamountMutation,
  useEditUpcomingToLiveMutation,
  useEditOverunderAreaVisibilityMutation,
  useEditEveryOverunderAreaVisibilityMutation,
  useEditInningsAreaVisibilityMutation,
  useEditOverUnderAriaVisibilityMutation,
  useEditEveryOverUnderAriaVisibilityMutation,
  useEditInningsAriaVisibilityMutation,
  useEditOverUnderQuestionHideMutation,
  useEditOverUnderRunDistanceMutation,
  useEditOverUnderGapMutation,
  useUpdateMatchMutation,
} = bettingmatchApi;
