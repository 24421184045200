import React, { useState } from "react";
import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import AddQuestionModal from "./AddQuestionModal";
import AccordionAnswer1Modal from "./AccordionAnswer1Modal";
import UpdateQuestion from "./UpdateQuestion";
import { useRemoveDefaultQuestionMutation } from "../../../../features/api/setdefaultmatchApi";
import { toast } from "react-toastify";
import DefaultQuestionAnserModal from "./DefaultQuestionAnserModal";
import axios from "axios";
import { useAddNewCopyQuesAnsMutation } from "../../../../features/api/getanswerApi";

const BettingDHiddenModal2 = ({ data, closeModal, openModal }) => {
  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  const [removeDefaultQuestion] = useRemoveDefaultQuestionMutation();

  const handleDeleteDefaultQuestion = async () => {
    try {
      const confirm = window.confirm("Are you sure you want to delete");
      if (confirm) {
        const response = await removeDefaultQuestion(data?.id);
        if (response?.data) {
          toast.success(response?.data?.message);
          closeModal();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [addNewCopyQuesAns] = useAddNewCopyQuesAnsMutation();
  const handlecopy = async (item) => {
    try {
      // const response = await axios.post(
      //   `https://rakibvai.munihaelectronics.com/backend/public/api/copy-question-and-answers`,
      //   {
      //     question_id: Number(item?.id),
      //     match_id: Number(data?.matchId),
      //   },
      //   {
      //     headers: {
      //       authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      //     },
      //   }
      // );
      const response = await addNewCopyQuesAns({
        question_id: Number(item?.id),
        match_id: Number(data?.matchId),
      });

      if (!response?.error) {
        // closeModal();
        toast.success(response?.data?.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if (!openModal) return null;
  return (
    <div
      id="ModalBackground"
      // onClick={ModalClose}
      className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50"
    >
      <div className="w-11/12 sm:4/5 md:3/5 lg:w-2/5 p-5 rounded bg-white text-black">
        <div className="flex justify-end">
          <p
            className="text-lg text-gray-500 cursor-pointer"
            onClick={closeModal}
          >
            <FaTimes />
          </p>
        </div>
        <div className="p-2">
          <div>
            <div className="border grid grid-cols-12">
              <span className="col-span-1 border-r text-center py-2 font-bold">
                SN.
              </span>
              <span className="col-span-9 py-2 ml-2 font-bold">
                Live Matches
              </span>
              <span className="col-span-2 py-2 border-l text-center font-bold">
                Actions
              </span>
            </div>
            <div>
              {data?.data?.map((item, index) => {
                return (
                  <div key={item?.id}>
                    <div className="border grid grid-cols-12">
                      <span className="grid justify-center items-center col-span-1 border-r text-center py-2 font-bold">
                        {index + 1}
                      </span>
                      <span className="grid items-center col-span-8 md:col-span-9 py-2 ml-2 font-bold">
                        {item?.title}
                      </span>
                      <span className="grid justify-center items-center col-span-3 md:col-span-2 py-2 border-l text-center font-bold">
                        <button
                          onClick={() => handlecopy(item)}
                          className="bg-green-700 text-white font-bold p-1 md:p-2 rounded-sm"
                        >
                          To Pannel
                        </button>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BettingDHiddenModal2;
