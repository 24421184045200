import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FaAngleDown, FaList } from "react-icons/fa";
import UserToUserBalanceTransferForm from "../../../../components/ModalAdmin/UserToUserBalanceTransferForm/UserToUserBalanceTransferForm";
import {
  useEditUserMutation,
  useGetUsersQuery,
  useRemoveUserMutation,
} from "../../../../features/api/usersApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomerinfoModal from "../../../../components/ModalAdmin/CustomerinfoModal/CustomerinfoModal";

const SingleUser = () => {
  const { state } = useLocation();

  const [openClubInfoModal, setOpenClubInfoModal] = useState(false);
  const handleClubInfoModal = () => {
    setOpenClubInfoModal(true);
  };
  const handleCloseClubInfoModal = () => setOpenClubInfoModal(false);

  const { data: users } = useGetUsersQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const customer = users?.users?.filter((user) => user?.role === "customer");
  const findCosterm = customer?.find((user) => user?.userId == state);
  // console.log(findCosterm);

  const [customUser, setCustomUser] = useState(false);

  const totalBalance = customer?.reduce((sum, item) => sum + +item?.balance, 0);

  const [openUserToUserBTForm, setOpenUserToUserBTForm] = useState(false);
  const handleUserToUserBTForm = () => setOpenUserToUserBTForm(true);
  const handleCloseUserToUserBTForm = () => setOpenUserToUserBTForm(false);
  const [searchText, setSearchText] = useState("");

  function dateFormat(dateString) {
    const date = new Date(dateString);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hour = date.getHours();
    let min = date.getMinutes();
    let time = hour + ":" + min;
    let getDate = day + "-" + month + "-" + year;
    return getDate;
  }
  function timeFormat(dateString) {
    const date = new Date(dateString);
    let hour = date.getHours();
    let min = date.getMinutes();
    let time = hour + ":" + min;
    return time;
  }

  const [updateUser] = useEditUserMutation();
  const handleUpdate = async (row) => {
    const confirm = window.confirm("Are you sure to update?");
    if (confirm) {
      try {
        const data = {
          id: findCosterm?.id,
          status: findCosterm?.status == 0 ? 1 : 0,
        };
        console.log(data);
        const res = await updateUser(data);
        console.log(res);
        if (res?.error) {
          toast.error("Error..!!!");
        } else {
          toast.success(res?.data?.user);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [removeUser] = useRemoveUserMutation();
  const handleDelete = async () => {
    const confirm = window.confirm("Are you sure?");
    if (confirm) {
      try {
        const res = await removeUser(findCosterm?.id);
        console.log(res);
        if (res) {
          toast.success("Successfully Remove..!!!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  let error = "";
  const navigate = useNavigate();
  if (findCosterm == undefined) {
    error = "User Not Found";
    setTimeout(() => {
      navigate("/admin");
    }, 1000);
  }

  return (
    <div className="mt-10">
      <div className="bg-white text-black p-5">
        <div className="  flex gap-2 ">
          <FaList className="text-xl mt-2"></FaList>
          <h1 className="text-2xl font-semibold">
            User ( {findCosterm?.userId} )
          </h1>
        </div>
      </div>
      <div className=" text-black rounded px-5">
        {findCosterm == undefined ? (
          <p className="text-red-600 text-center text-xl font-bold">{error}</p>
        ) : (
          <div className="flex flex-col gap-3 items-start bg-white shadow-md w-[max-content] mt-2 p-2 rounded-sm mx-auto text-lg">
            <p>Name: {findCosterm?.name}</p>
            <p>userName: {findCosterm?.userId}</p>
            <p>Club Name: {findCosterm?.clubId}</p>
            <p>Balance: {findCosterm?.balance}</p>
            <p>Join Date: {findCosterm?.created_at}</p>
            <p>Mobile: {findCosterm?.mobileNumber}</p>
            <div className="flex justify-between w-full">
              <button className="bg-green-700 text-sm text-white font-bold shadow-lg px-2 py-1 rounded-sm">
                Visit
              </button>
              <button
                onClick={() => handleClubInfoModal()}
                className="bg-green-600 text-sm text-white font-bold shadow-lg px-2 py-1 rounded-sm"
              >
                View
              </button>
              <button
                onClick={() => handleUpdate()}
                className="bg-green-500 text-sm text-white font-bold shadow-lg px-2 py-1 rounded-sm"
              >
                Active
              </button>
              <button
                onClick={() => handleDelete()}
                className="bg-red-500 text-sm text-white font-bold shadow-lg px-2 py-1 rounded-sm"
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
      <UserToUserBalanceTransferForm
        openForm={openUserToUserBTForm}
        closeForm={handleCloseUserToUserBTForm}
      ></UserToUserBalanceTransferForm>
      <CustomerinfoModal
        data={findCosterm}
        openModal={openClubInfoModal}
        closeModal={handleCloseClubInfoModal}
      />
    </div>
  );
};

export default SingleUser;
