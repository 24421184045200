import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import {
  useEditAnswerLimitMutation,
  useEditOverAnswerLimitMutation,
} from "../../../../features/api/getanswerApi";
import { toast } from "react-toastify";

const LimitModal = ({ passData, val }) => {
  const [visible, setVisible] = useState(false);
  const [setInput, setInputData] = useState(null);
  const [editAnswerLimit] = useEditAnswerLimitMutation();
  const [editOverAnswerLimit] = useEditOverAnswerLimitMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (val == 1) {
        const sendData = {
          id: passData?.id,
          limitedAmount: setInput || passData?.limitedAmount,
        };
        const response = await editAnswerLimit(sendData);

        if (response?.data) {
          toast.success(response?.data?.message);
          setVisible(false);
        }
      } else if (val == 2) {
        const sendData = {
          id: passData?.id,
          limitedAmount: setInput || passData?.limitedAmount,
        };
        const response = await editOverAnswerLimit(sendData);

        if (response?.data) {
          toast.success(response?.data?.message);
          setVisible(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="card flex justify-content-center ">
      <button
        onClick={() => setVisible(true)}
        className="p-1 mx-auto rounded-sm w-[max-content] hover:bg-emerald-900 bg-emerald-700 text-white"
      >
        Limit {passData?.limitedAmount || 0}
      </button>
      <Dialog
        header="Limit Answer"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{
          width: "20vw",
          backgroundColor: "white",
          color: "black",
          padding: "10px",
        }}
        breakpoints={{ "960px": "50vw", "641px": "80vw" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            paddingTop: "10px",
            marginTop: "10px",
            borderTop: "1px solid gray",
          }}
        >
          <h1 style={{ fontSize: "15px" }}>Answer: {passData?.title}</h1>
          <input
            type="text"
            defaultValue={passData?.limitedAmount || 0}
            onChange={(e) => setInputData(e.target.value)}
            style={{
              border: "1px solid gray",
              width: "100%",
              height: "30px",
              padding: "5px",
            }}
          />
          <button
            onClick={handleSubmit}
            style={{
              backgroundColor: "green",
              color: "white",
              padding: "6px 0",
              borderRadius: "3px",
            }}
          >
            Submit
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default LimitModal;
