import React from "react";
import { useState } from "react";
import { FaKey, FaLock, FaUser } from "react-icons/fa";
import logo from "../../../Assets/logo.png";
import { toast } from "react-toastify";
import { useLoginMutation } from "../../../features/api/loginApi";
import { useLocation, useNavigate } from "react-router-dom";

const ClubLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [loginUser] = useLoginMutation();
  const location = useLocation();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      userId,
      password,
    };
    try {
      const response = await loginUser(data).unwrap();
      const accessToken = response?.token;

      //   const from = location.state?.path || "/user/dashboard";
      const from = location.state?.path;
      if (response?.role === "club") {
        localStorage.setItem("accessToken", accessToken);
        toast.success("Login successful");
        navigate("/club");
      } else {
        toast.warning("Unauthorized Access");
      }
    } catch (error) {
      console.log("error from login", error?.data?.message);
      toast.error(error?.data?.message);
    }
  };

  return (
    <div className="h-screen w-screen bg-teal-600">
      <div className="h-1/2 bg-gray-200">
        <div className="pt-20">
          <div className="mx-auto w-80 lg:w-96 bg-gray-800 text-white rounded">
            <div className="p-5 lg:p-10 ">
              <div className="">
                <figure className="flex justify-center mb-4 text-xl">
                  <img src={logo} alt="" />
                </figure>
                <h1 className="text-xl font-bold text-center">CLUB SIGN IN</h1>
              </div>
              <div className="mt-5">
                <form onSubmit={handleSubmit}>
                  <div className="relative w-full my-4">
                    <input
                      type="text"
                      id="username"
                      onChange={(e) => setUserId(e.target.value)}
                      placeholder="Enter Username"
                      className="p-2 mt-2 rounded w-full text-black text-sm border-2 bg-gray-200 focus:outline-teal-600"
                    />
                    <div className="absolute top-1/2 right-0 mr-2 mt-1 transform -translate-y-1/2 text-gray-800 text-base cursor-pointer">
                      <FaUser />
                    </div>
                  </div>

                  <div className="relative w-full my-4">
                    <div className="relative" id="Password">
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        className="p-2 mt-2 rounded w-full text-black text-sm border-2 bg-gray-200 focus:outline-teal-600  "
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div
                        className="absolute top-1/2 right-0 mr-2 mt-1 transform -translate-y-1/2 text-gray-800 text-base cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaKey /> : <FaLock />}
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="bg-teal-500 hover:bg-teal-700 w-full text-sm font-semibold text-white py-2 mt-3 rounded"
                    style={{
                      background: "linear-gradient(1800deg, #37103a, #08545B)",
                    }}
                  >
                    LOG IN
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClubLogin;
