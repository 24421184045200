import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import axios from "axios";
import { useGetDepositNumberQuery } from "../../../features/api/depositnumberApi";
import { useGetPaymentMethodQuery } from "../../../features/api/paymentmethodApi";

const Deposit = ({ closeModal, openModal }) => {
  // const [depositMethods, setDepositMethods] = useState([]);
  // const [depositPaymentMethods, setDepositPaymentMethods] = useState([]);
  const [activeMethod, setActiveMethod] = useState(null);
  const [activeDepositMethod, setActiveDepositMethod] = useState(null);
  const [copied, setCopied] = useState(false);
  const [errors, setErrors] = useState(null);

  const depositMethods = useGetDepositNumberQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.deposit_receiving_numbers;

  const depositPaymentMethods = useGetPaymentMethodQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.payment_methods?.filter((tp) => tp?.type == "dp");

  const [inputAmount, setInputAmount] = useState("");
  const [inputNumber, setInputNumber] = useState("");
  const validateInput = () => {
    if (inputAmount.length > 5) {
      setErrors("Amount cannot exceed 5 digits.");
      return false;
    }
    if (inputNumber.length > 11) {
      setErrors("From Number cannot exceed 11 digits.");
      return false;
    }
    if (inputAmount === "" || inputNumber === "") {
      setErrors("Please fill in all the fields.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
  e.preventDefault();

  if (!validateInput()) return;

  const saveNew = {
    deposit: inputAmount,
    to_number: activeDepositMethod?.number,
    payment_method_id: activeDepositMethod?.method_id,
    from_number: inputNumber,
  };

  try {
    const response = await axios.post(
      "https://rakibvai.munihaelectronics.com/backend/public/api/add-deposit",
      saveNew,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (response) {
      setErrors(null);
      toast.success(response?.data?.message);
      closeModal();
    }
  } catch (error) {
    console.log(error?.response?.data?.message);
    setErrors(error?.response?.data?.message);
  }
};

  const handleDepositMethod = (matched, data) => {
    setActiveMethod(data);
    setActiveDepositMethod(matched);
    setCopied(false);
  };

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  const modalClose = (e) => {
    if (e.target.id === "modalBackground") closeModal();
  };

  if (!openModal) return null;

  return (
    <div
      id="modalBackground"
      onClick={modalClose}
      className="fixed inset-0 bg-black bg-opacity-40 snap-alw flex justify-center items-center"
    >
      <div
        className="w-11/12 sm:4/5 md:3/5 lg:w-2/5 p-5 rounded"
        style={{ background: "linear-gradient(360deg, #37103a, #08545B)" }}
      >
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">
            {" "}
            <span className="pr-3 text-green-400">|</span>Make Deposit
          </h1>

          <p
            className="text-lg text-gray-500"
            onClick={() => {
              closeModal();
              setCopied(false);
              setErrors(null);
            }}
          >
            <FaTimes />
          </p>
        </div>
        <div>
          <form onSubmit={handleSubmit} className="mt-5">
            <div className="w-full my-2  ">
              {errors && <p className="text-red-500 text-sm py-1">{errors}</p>}
              <label for="method" className="font-semibold">
                Select Method <span className="text-red-400">*</span>
              </label>
              <div className="flex items-center gap-x-4">
                {depositPaymentMethods?.map((data) => {
                  const matched = depositMethods?.find(
                    (d) => d?.method_id == data?.id
                  );
                  return (
                    <div key={data?.id} className="">
                      <div className="flex  gap-2 w-full my-2">
                        <img
                          src={`https://rakibvai.munihaelectronics.com/backend/storage/app/public/${data?.img}`}
                          alt="Nagad Logo"
                          className={`w-24 h-12  p-2 rounded ${
                            activeMethod?.id == matched?.method_id
                              ? " bg-white "
                              : "bg-gray-600 opacity-60"
                          }`}
                          onClick={() => handleDepositMethod(matched, data)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              {activeMethod && (
                <div className="flex gap-2 text-sm font-bold">
                  <p className="p-2 bg-green-600 rounded w-full">
                    {" "}
                    {activeDepositMethod?.number} ({activeMethod?.name})
                  </p>
                  <CopyToClipboard
                    text={activeDepositMethod?.number}
                    onCopy={() => setCopied(true)}
                  >
                    <i className="fa-regular cursor-pointer fa-copy py-2 px-3 text-sm  bg-gray-600 rounded"></i>
                  </CopyToClipboard>
                </div>
              )}
              {copied && (
                <span className="copied-message float-right">Copied!</span>
              )}
            </div>
            <div className="w-full my-2">
              <label for="Amount" className="font-semibold">
                {" "}
                Amount <span className="text-red-400">*</span>
              </label>
              <input
                type="number"
                id="Amount"
                placeholder="Amount"
                value={inputAmount}
                onChange={(e) => setInputAmount(e.target.value)}
                className="p-2 rounded w-full text-sm text-white bg-gray-950 focus:outline-none"
              />
            </div>
            <div className="w-full my-2">
              <label for="FromNumber" className="font-semibold">
                {" "}
                From <span className="text-red-400">*</span>
              </label>
              <input
                type="number"
                id="FromNumber"
                placeholder="From Number"
                value={inputNumber}
                onChange={(e) => setInputNumber(e.target.value)}
                className="p-2 rounded w-full text-sm text-white bg-gray-950 focus:outline-none"
              />
            </div>

            <button
              type="submit"
              className="bg-green-800 text-sm w-full font-bold p-3 mt-3 rounded"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
