import React, { useEffect, useState } from "react";
import { useGetCombinedMatchesQuery } from "../../../features/api/bettingmatchApi";
import ReloadPusher from "../../../components/ReloadPusher";
import { DNA } from "react-loader-spinner";
import TableTennisCard from "./TableTennisCard/TableTennisCard";

const TableTennis = () => {
  const bgGradientColor = {
    background: "linear-gradient(90deg, #08545B, #37103a)",
  };

  const {
    data: combined,
    isLoading: combinedLoading,
    refetch,
  } = useGetCombinedMatchesQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const [liveMatches, setLiveMatches] = useState([]);
  const [upComingMatches, setUpComingMatches] = useState([]);

  useEffect(() => {
    if (!combinedLoading && combined) {
      setLiveMatches(combined.live_matches);
      setUpComingMatches(combined.upcoming_matches);
    }
  }, [combined, combinedLoading]);

  const [getData, setGetData] = useState({});
  useEffect(() => {
    if (Object.keys(getData).length > 0) {
      refetch();
    }
  }, [getData, refetch]);

  return (
    <div>
      <div className="text-center" style={bgGradientColor}>
        <h1 className="w-full py-1 text-xl font-semibold">Live Match</h1>
      </div>
      {combinedLoading ? (
        <DNA
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{
            textAlign: "center",
            display: "block",
            width: "100%",
            margin: "auto",
          }}
          wrapperClass="dna-wrapper"
        />
      ) : (
        liveMatches
          ?.filter((crick) => crick?.match?.gameType == 3)
          ?.map((live, idx) => {
            const one = live?.over_under_details?.find((d) => d);
            return (
              <TableTennisCard
                key={idx}
                live={live}
                overUnderDetails={live["over_under_details"]}
                subtitles={one?.subtitles}
                refetch={refetch}
                mStatus="live"
              />
            );
          })
      )}

      <div className="text-center mt-5" style={bgGradientColor}>
        <h1 className="w-full py-1 text-xl font-semibold">Upcoming Match</h1>
      </div>
      {combinedLoading ? (
        <DNA
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperStyle={{
            textAlign: "center",
            display: "block",
            width: "100%",
            margin: "auto",
          }}
          wrapperClass="dna-wrapper"
        />
      ) : (
        upComingMatches
          ?.filter((crick) => crick?.match?.gameType == 3)
          ?.map((live, idx) => {
            const one = live?.over_under_details?.find((d) => d);
            return (
              <TableTennisCard
                key={idx}
                live={live}
                overUnderDetails={live["over_under_details"]}
                subtitles={one?.subtitles}
                refetch={refetch}
                mStatus="upcoming"
              />
            );
          })
      )}
      {/* <TableTennisCard /> */}
      {/* <FootballCard /> */}

      {/* Reload by Pusher  */}
      <ReloadPusher setGetData={setGetData} />
    </div>
  );
};

export default TableTennis;
