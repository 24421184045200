import React, { useState } from "react";
import LimitModal from "./LimitModal";
import TestingModal from "./TestingModal";
import {
  useEditAnswerStatusMutation,
  useEditAnswerVisibilityMutation,
  useRemoveAnswerByIdMutation,
} from "../../../../features/api/getanswerApi";
import {
  useAddSubmitWinMutation,
  useGetAllMatchDataQuery,
} from "../../../../features/api/bettingmatchApi";
import { useGetAllAdminBetsQuery } from "../../../../features/api/mybetsApi";
import axios from "axios";
import { Link } from "react-router-dom";

function LiveMatchAnswer({ answers, filterQuesBet, questionId, matchId }) {
  const totalAmount = filterQuesBet?.reduce(
    (sum, val) => sum + Number(val?.betAmount),
    0
  );

  console.log(answers);

  const [editAnswerStatus] = useEditAnswerStatusMutation();
  const [editAnswerVisibility] = useEditAnswerVisibilityMutation();
  const [removeAnswerById] = useRemoveAnswerByIdMutation();
  const [dropdowns, setDropdowns] = useState({});

  const handleAnswerActiveStatus = async (d, val) => {
    try {
      if (val === 1) {
        const sendData1 = {
          id: d?.id,
          showStatus: d?.showStatus == "1" ? "0" : "1",
        };
        await editAnswerStatus(sendData1);
      } else if (val === 2) {
        const sendData2 = {
          id: d?.id,
          hide: d?.hide == "1" ? "0" : "1",
        };
        await editAnswerVisibility(sendData2);
      } else if (val === 3) {
        await removeAnswerById(d?.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { data: bettingData, refetch } = useGetAllMatchDataQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const { data, refetch: betReftch } = useGetAllAdminBetsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const [addSubmitWin] = useAddSubmitWinMutation();
  const handleSubmitWin = async (rcvData) => {
    refetch();
    betReftch();
    try {
      const res = await addSubmitWin({
        match: rcvData?.betting_titles_id,
        que: rcvData?.bettingSubTitleId,
        ans: rcvData?.id,
        question_type: "normal",
      });
      if (res?.data) {
        refetch();
        betReftch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRestart = async () => {
    refetch();
    betReftch();
    try {
      const sendData = {
        match: matchId,
        que: questionId,
        question_type: "normal",
      };
      const response = await axios.post(
        "https://rakibvai.munihaelectronics.com/backend/public/api/restart-win",
        sendData,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      // console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleDropdown = (id) => {
    setDropdowns((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <div>
      <div className="bg-[#78909C] shadow-lg flex items-center gap-x-0 text-white rounded-sm">
        <div className="w-full px-0 mt-0 overflow-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border p-1 text-sm">Answer</th>
                <th className="border p-1 text-sm">Bet Rate</th>
                <th className="border p-1 text-sm">Place Bet</th>
                <th className="border p-1 text-sm">Betting Amount</th>
                <th className="border p-1 text-sm">Return Amount</th>
                <th className="border p-1 text-sm">Action</th>
                <th className="border p-1 text-sm">Limit</th>
              </tr>
            </thead>
            <tbody>
              {answers?.map((d, i) => {
                const filterAnswer = filterQuesBet?.filter(
                  (ad) =>
                    ad?.matchId == d?.betting_titles_id &&
                    ad?.questionId == d?.bettingSubTitleId &&
                    ad?.answerId == d?.id
                );
                const betResult = filterAnswer?.find((af) => af);
                const totalAnsBetAmt = filterAnswer?.reduce(
                  (sum, cur) => sum + Number(cur?.betAmount),
                  0
                );
                const totalAnsReturnBetAmt = filterAnswer?.reduce(
                  (sum, cur) => sum + Number(cur?.returnAmount),
                  0
                );
                return (
                  <tr key={d?.id}>
                    <td className="border p-1 text-center text-sm">
                      {d?.title}
                    </td>
                    <td className="border p-1">
                      <TestingModal passData={d} status={1} />
                    </td>
                    <td className="border p-1 text-center">
                      {filterAnswer?.length}
                    </td>
                    <td className="border p-1 text-center">{totalAnsBetAmt}</td>
                    <td className="border p-1 text-center">
                      {totalAnsReturnBetAmt}
                    </td>
                    <td className="border p-1 font-normal relative">
                      {betResult?.betStatus == 0 ? (
                        <button
                        onClick={() => {
                          // Show the confirmation dialog
                          const isConfirmed = window.confirm('Are you sure you want to submit?');
                      
                          // If the user confirms, call handleSubmitWin
                          if (isConfirmed) {
                            handleSubmitWin(d);
                          }
                        }}
                        style={{ backgroundColor: "#0891B2" }}
                        className="float-left p-1 rounded-sm hover:bg-blue-900 text-white"
                      >
                        Win
                      </button>
                      ) : betResult?.betStatus == 1 ? (
                        <button className="float-left p-1 rounded-sm hover:bg-emerald-900 bg-emerald-700 text-white">
                          wined
                        </button>
                      ) : betResult?.betStatus == 2 ? (
                        <button className="float-left p-1 rounded-sm hover:bg-emerald-900 bg-red-700 text-white">
                          Lose
                        </button>
                      ) : null}
                      <button
                        className="float-right p-1 rounded-sm flex items-center hover:bg-emerald-900 bg-emerald-700 text-white"
                        onClick={() => toggleDropdown(d.id)}
                      >
                        Action
                        <svg
                          className="ml-2 h-4 w-3"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 3a1 1 0 01.894.553l5 10a1 1 0 01-1.788.894L10 6.618l-4.106 8.829a1 1 0 01-1.788-.894l5-10A1 1 0 0110 3z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      {dropdowns[d?.id] && (
                        <div className="absolute top-full right-0 mt-5 bg-white text-black rounded shadow-lg z-50">
                          <ul>
                            <Link
                              to={"/admin/betting-panel/user-actions"}
                              state={d}
                            >
                              <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">
                                User Action
                              </li>
                            </Link>
                            <li
                              onClick={() => handleAnswerActiveStatus(d, 1)}
                              className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                            >
                              {d?.showStatus == 0 ? (
                                <span className="text-red-500 font-bold">
                                  Open
                                </span>
                              ) : (
                                <span className="text-green-500 font-bold">
                                  Stop
                                </span>
                              )}
                            </li>
                            <li
                              onClick={() => handleAnswerActiveStatus(d, 2)}
                              className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                            >
                              {d?.hide == 0 ? (
                                <span className="text-red-500 font-bold">
                                  Show
                                </span>
                              ) : (
                                <span className="text-green-500 font-bold">
                                  Hide
                                </span>
                              )}
                            </li>
                            <li
                              onClick={() => handleAnswerActiveStatus(d, 3)}
                              className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
                            >
                              Delete
                            </li>
                          </ul>
                        </div>
                      )}
                    </td>
                    <td className="border p-1">
                      <LimitModal passData={d} val={1} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th className=""></th>
                <th className=""></th>
                <th className="border p-1">
                  <button
                  
                    onClick={() => {
                      // Show the confirmation dialog
                      const isConfirmed = window.confirm('Are you sure you want to Restart?');
                  
                      // If the user confirms, call handleSubmitWin
                      if (isConfirmed) {
                        handleRestart();
                      }
                    }}
                    className="bg-red-700 hover:bg-red-800 text-white px-4 py-1 rounded-sm"
                  >
                    Restart
                  </button>
                </th>
                <th className="border p-1">Total = {totalAmount}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

export default LiveMatchAnswer;
