import { apiSlice } from "./apiSlice";

const loginApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (userData) => ({
        url: "/register",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["getallusers"],
    }),
    addClub: builder.mutation({
      query: (newClub) => ({
        url: "/add-club",
        method: "POST",
        body: newClub,
      }),
      invalidatesTags: ["getallusers"],
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["getallusers"],
    }),
    getUser: builder.query({
      query: (accessToken) => ({
        url: "/me",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        invalidatesTags: ["me"],
      }),
    }),
    addWithdraw: builder.mutation({
      query: (newWithdraw) => ({
        url: "/add-withdraw",
        method: "POST",
        body: newWithdraw,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["me"],
    }),
    editClub: builder.mutation({
      query: ({ clubId, ...editData }) => ({
        url: `/update-club/${clubId}`,
        method: "PUT",
        body: editData,
      }),
      invalidatesTags: ["getallusers"],
    }),
    editCustomer: builder.mutation({
      query: ({ id, ...editData }) => ({
        url: `/update-customer/${id}`,
        method: "PUT",
        body: editData,
      }),
      invalidatesTags: ["getallusers"],
    }),
  }),
});

export const {
  useSignupMutation,
  useAddClubMutation,
  useLoginMutation,
  useGetUserQuery,
  useAddWithdrawMutation,
  useEditClubMutation,
  useEditCustomerMutation,
} = loginApi;
