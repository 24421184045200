import React from "react";
import Header from "../../components/Header/Header";
import { Link, Outlet } from "react-router-dom";
import { useState } from "react";
import BottomAppBar from "../../components/BottomAppBar/BottomAppBar";

const Dashboard = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  return (
    <div>
      <Header></Header>
      <div>
        <div className="relative flex">
          <div className={`px-5 mx-auto w-full`}>
            <div className={`flex justify-end lg:hidden`}>
              <i
                className="fa-solid fa-list p-3 text-lg"
                onClick={toggleSidebar}
              ></i>
            </div>
            <Outlet></Outlet>
          </div>
        </div>
      </div>
      <BottomAppBar></BottomAppBar>
    </div>
  );
};

export default Dashboard;
