import React from "react";
import { useGetMyTransactionsQuery } from "../../../features/api/mybetsApi";
import { formatDateTime } from "../../../utils/formatDateTime";

const TransactionHistory = () => {
  const { data } = useGetMyTransactionsQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-4">Transaction History</h1>

      {/* Mobile View */}
      <div className="my-5 text-sm lg:hidden">
        {data?.transaction?.length === 0 ? (
          <p>Loading...</p>
        ) : (
          data?.transaction?.map((record, idx) => (
            <div key={idx} className="border-b py-4">
              <div className="text-red-400 text-lg font-semibold">
                Transaction {idx + 1}
              </div>
              <div className="flex flex-col">
                <div className="flex justify-between py-1">
                  <strong>Date & Time:</strong>
                  <span>{formatDateTime(record.time)}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>Description:</strong>
                  <span>{record.description}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>Debit(Out):</strong>
                  <span>{record.debit}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>Credit(In):</strong>
                  <span>{record.credit}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>Balance:</strong>
                  <span>{record.total}</span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Desktop View */}
      <div className="hidden lg:block overflow-x-auto mt-5 border border-emerald-950 rounded">
        <table className="min-w-full divide-y divide-emerald-950">
          <thead className="bg-gray-800 text-emerald-400 uppercase text-xs font-semibold">
            <tr>
              <th className="px-6 py-3 text-left">SN</th>
              <th className="px-6 py-3 text-left">Date & Time</th>
              <th className="px-6 py-3 text-left">Description</th>
              <th className="px-6 py-3 text-left">Debit(Out)</th>
              <th className="px-6 py-3 text-left">Credit(In)</th>
              <th className="px-6 py-3 text-left">Balance</th>
            </tr>
          </thead>
          <tbody className="bg-gray-900 text-white">
            {data?.transaction?.length === 0 ? (
              <tr>
                <td colSpan="6" className="text-center py-4">
                  No transactions found.
                </td>
              </tr>
            ) : (
              data?.transaction?.map((record, idx) => (
                <tr key={idx} className="text-sm">
                  <td className="px-6 py-4">{idx + 1}</td>
                  <td className="px-6 py-4">
                    {formatDateTime(record.time)}
                  </td>
                  <td className="px-6 py-4">{record.description}</td>
                  <td className="px-6 py-4">{record.debit}</td>
                  <td className="px-6 py-4">{record.credit}</td>
                  <td className="px-6 py-4">{record.total}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransactionHistory;
