import React from 'react';
import { Link } from 'react-router-dom';
import { useGetUserQuery } from '../../../features/api/loginApi';

const ClubPopUp = ({ closePopUp, openPopUp }) => {
    const PopUpClose = (e) => {
        if (e.target.id === "PopUpBackground") closePopUp();
    };

    const accessToken = localStorage.getItem("accessToken");
    const { data } = useGetUserQuery(accessToken, {
        skip: !accessToken,
        refetchOnMountOrArgChange: true,
    });
    const logOut = () => {
        localStorage.removeItem("accessToken");
        setTimeout(() => {
            window.location.reload();
        }, 0.5);
    };

    if (!openPopUp) return null;
    return (
        <div
            id="PopUpBackground"
            onClick={PopUpClose}
            className="fixed inset-0 z-50  "
        >
            <div className=" relative">
                <div className="absolute top-16 right-0 mr-5 lg:mr-7 text-gary-100 bg-gray-950 drop-shadow-2xl rounded">
                    <div className="m-2 ">
                        <ul className="text-base font-semibold text-center">
                            <Link to="/club/profile">
                                <li className="my-1 p-2 cursor-pointer transition duration-75 ease-in-out rounded">
                                    {data?.user?.userId}
                                </li>
                            </Link>
                            <hr className='my-1' />
                            <li
                                onClick={logOut}
                                className="flex items-center p-2 my-1 cursor-pointer transition duration-75 ease-in-out rounded"
                            >
                                <i className="fa-solid fa-power-off mr-3" />
                                <span className='text-base'>Logout</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClubPopUp;