import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FaAngleDown, FaList } from "react-icons/fa";
import {
  useGetCloseMatchHistoryQuery,
  useUpdateClosetoOpenMatchMutation,
} from "../../../../features/api/bettingmatchApi";
import ball from "../../../../Assets/photos/ball.png";
import FootballImg from "../../../../Assets/photos/football.png";
import TableTennisImg from "../../../../Assets/photos/table-tennis.png";
import { formatDateTime } from "../../../../utils/formatDateTime";
import { toast } from "react-toastify";

const ClosedMatchHistory = () => {
  const { data, isLoading } = useGetCloseMatchHistoryQuery(null, {
    refetchOnMountOrArgChange: true,
  });

  const [searchText, setSearchText] = useState("");
  const [updateClosetoOpenMatch] = useUpdateClosetoOpenMatchMutation();

  const handleClosetoOpenMatch = async (id) => {
    try {
      const response = await updateClosetoOpenMatch(id);
      if (!response?.error) {
        toast.success("Match has been opened successfully");
      }
    } catch (error) {
      toast.error("An error occurred while opening the match");
    }
  };

  const columns = [
    {
      name: "SN",
      selector: (row, idx) => idx + 1,
      sortable: true,
      width: '10%', // Auto width for responsiveness
    },
    {
      name: "Live Match",
      selector: (row) => (
        <div className="flex items-center space-x-2">
          <div className="flex items-center space-x-2">
            {/* Display the appropriate image based on gameType */}
            {row?.gameType === '1' ? (
              <img
                alt="Football"
                src={FootballImg}
                className="h-6 w-6"
              />
            ) : row?.gameType === '2' ? (
              <img
                alt="Ball"
                src={ball}
                className="h-6 w-6"
              />
            ) : row?.gameType === '3' ? (
              <img
                alt="Table Tennis"
                src={TableTennisImg}
                className="h-6 w-6"
              />
            ) : null}
            
            {/* Text Information */}
            <div className="flex flex-col">
              <div className="flex items-center space-x-1">
                <p className="text-sm">{row?.A_team}</p>
                <span className="text-sm">Vs</span>
                <p className="text-sm">{row?.B_team}</p>
              </div>
              <div className="flex items-center space-x-1 text-xs">
                <p>{row?.title}</p> 
                <span>||</span>
                <p>{formatDateTime(row?.date)}</p>
              </div>
            </div>
          </div>
        </div>
      ),
      sortable: true,
      width: '40%', // Adjust as needed
    },
    {
      name: "Closed Time",
      selector: (row) => formatDateTime(row.close_time),
      sortable: true,
      width: 'auto', // Adjust as needed
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <button
            className="px-3 py-1 bg-cyan-600 text-white font-semibold rounded"
            onClick={() => handleClosetoOpenMatch(row.id)}
          >
            TO
          </button>
        </div>
      ),
      sortable: true,
      width: 'auto', // Auto width for responsiveness
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        color: "white",
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: "teal",
      },
    },
    rows: {
      style: {
        fontSize: '14px', // Adjust as needed
      },
    },
    cells: {
      style: {
        padding: '8px', // Adjust padding for better spacing
      },
    },
  };

  const customFilter = (rows, searchText) => {
    return rows?.filter((row) => {
      return Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      );
    });
  };

  return (
    <div className="mt-10">
      <div className="bg-white text-black p-5 flex items-center space-x-2">
        <FaList className="text-xl" />
        <h1 className="text-2xl font-semibold">Closed Match History</h1>
      </div>
      <div className="lg:m-5 bg-white rounded shadow-md">
        <div className="flex items-center px-5 py-4 space-x-2">
          <label
            htmlFor="search"
            className="text-black text-sm font-semibold"
          >
            Search:
          </label>
          <input
            type="text"
            id="search"
            placeholder="Search..."
            className="text-black text-sm border-2 px-2 py-1 rounded focus:outline-none focus:ring-2 focus:ring-teal-600"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="overflow-x-auto lg:px-5">
          <DataTable
            columns={columns}
            data={customFilter(data, searchText)}
            customStyles={customStyles}
            sortIcon={<FaAngleDown className="m-1" />}
            defaultSortAsc={true}
            paginationRowsPerPageOptions={[50, 100, 150]}
            paginationPerPage={100}
            pagination
            highlightOnHover
          />
        </div>
      </div>
    </div>
  );
};

export default ClosedMatchHistory;
