export const formatTime = (inputTime) => {
  const date = new Date(inputTime);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear().toString().substr(-2);
  let hour = date.getHours();
  const minute = ("0" + date.getMinutes()).slice(-2);
  const ampm = hour >= 12 ? "pm" : "am";
  hour = hour % 12;
  hour = hour ? hour : 12;

  const formattedTime = `${day} ${month} ${year} ${hour}:${minute} ${ampm}`;

  return formattedTime;
};
