import React, { useEffect, useState } from "react";
import axios from "axios";
import { formatDateTime } from "../../../utils/formatDateTime";

const WithdrawHistory = () => {
  const accessToken = localStorage.getItem("accessToken");
  const [withdrawHistory, setWithdrawHistory] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://rakibvai.munihaelectronics.com/backend/public/api/withdraws",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          setWithdrawHistory(response.data?.withdraws || []);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accessToken]);

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-4">Withdraw History</h1>

      {/* Mobile View */}
      <div className="my-5 text-sm lg:hidden">
        {withdrawHistory.length === 0 ? (
          <p>Loading...</p>
        ) : (
          withdrawHistory.map((his, idx) => (
            <div key={his.id} className="border-b py-4">
              <div className="text-red-400 text-lg font-semibold">
                Withdraw {withdrawHistory.length - idx}
              </div>
              <div className="flex flex-col">
                <div className="flex justify-between py-1">
                  <strong>To:</strong>
                  <span>{his.to_number}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>From:</strong>
                  <span>{his.from_number}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>Amount:</strong>
                  <span>{his.withdraw}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>Rate:</strong>
                  <span>{his.rate}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>Method:</strong>
                  <span>{his.payment_method}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>Requested At:</strong>
                  <span>{formatDateTime(his.created_at)}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>Action At:</strong>
                  <span>{formatDateTime(his.updated_at)}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>Action:</strong>
                  <span>
                    {his.action === '0' ? (
                      <p className="bg-yellow-600 px-2 py-1 text-center rounded text-white">
                        Pending
                      </p>
                    ) : his.action === '1' ? (
                      <p className="bg-blue-500 px-2 py-1 text-center rounded text-white">
                        Processing
                      </p>
                    ) : his.action === '2' ? (
                      <p className="bg-green-500 px-2 py-1 text-center rounded text-white">
                        Approved
                      </p>
                    ) : his.action === '3' ? (
                      <p className="bg-red-500 px-2 py-1 text-center rounded text-white">
                        Cancelled
                      </p>
                    ) : null}
                  </span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Desktop View */}
      <div className="hidden lg:block overflow-x-auto mt-5 border border-emerald-950 rounded">
        <table className="min-w-full divide-y divide-emerald-950">
          <thead className="bg-gray-800 text-emerald-400 uppercase text-xs font-semibold">
            <tr>
              <th className="px-6 py-3 text-left">SN</th>
              <th className="px-6 py-3 text-left">To</th>
              <th className="px-6 py-3 text-left">From</th>
              <th className="px-6 py-3 text-left">Amount</th>
              <th className="px-6 py-3 text-left">Rate</th>
              <th className="px-6 py-3 text-left">Method</th>
              <th className="px-6 py-3 text-left">Requested At</th>
              <th className="px-6 py-3 text-left">Action At</th>
              <th className="px-6 py-3 text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            {withdrawHistory.length === 0 ? (
              <tr>
                <td colSpan="9" className="text-center py-4">
                  No withdrawals found.
                </td>
              </tr>
            ) : (
              withdrawHistory.map((his, idx) => (
                <tr key={his.id} className="bg-gray-900 text-white">
                  <td className="px-6 py-4 text-sm font-medium">
                    {idx + 1}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {his.to_number}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {his.from_number}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {his.withdraw}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {his.rate}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {his.payment_method}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {formatDateTime(his.created_at)}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {formatDateTime(his.updated_at)}
                  </td>
                  <td className="px-6 py-4 text-sm font-medium">
                    {his.action === '0' ? (
                      <p className="bg-yellow-600 px-2 py-1 text-center rounded text-white">
                        Pending
                      </p>
                    ) : his.action === '1' ? (
                      <p className="bg-blue-500 px-2 py-1 text-center rounded text-white">
                        Processing
                      </p>
                    ) : his.action === '2' ? (
                      <p className="bg-green-500 px-2 py-1 text-center rounded text-white">
                        Approved
                      </p>
                    ) : his.action === '3' ? (
                      <p className="bg-red-500 px-2 py-1 text-center rounded text-white">
                        Cancelled
                      </p>
                    ) : null}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WithdrawHistory;
