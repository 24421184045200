import React, { useEffect } from "react";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useGetUsersQuery } from "../../../../features/api/usersApi";

const ChangeClub = ({ user }) => {
  const { data, error, isLoading } = useGetUsersQuery({
    refetchOnMountOrArgChange: true,
  });
  const users = data?.users;

  const [clubId, setSelectClub] = useState("");
  const findUserByClubId=users?.find(user=>user?.userId===clubId)
  //   const [clubs, setClubs] = useState([]);
  //   useEffect(() => {
  //     fetch("https://rakibvai.munihaelectronics.com/backend/public/api/getagents")
  //       .then((res) => res.json())
  //       .then((data) => setClubs(data?.agents));
  //   }, []);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="px-5">
      <from>
        <div className="w-full ">
          <label className="text-sm font-semibold">Select Club</label>
          <select
            onChange={(e) => setSelectClub(e.target.value)}
            name="SelectClub"
            className="p-2 rounded w-full text-sm text-white bg-gray-950 focus:outline-none"
          >
            <option selected disabled>
              {user?.clubId}
            </option>
            {users?.map((user) => (
              <option value={user?.clubId}>{user?.clubId} </option>
            ))}
          </select>
        </div>

        <div className="relative w-full my-2">
          <label htmlFor="ConfirmPassword" className="text-sm font-semibold">
            Confirm Password
          </label>
          <div className="relative" id="ConfirmPassword">
            <input
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
              className="p-2 rounded w-full text-sm text-white bg-gray-950 "
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div
              className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 text-white text-xl cursor-pointer"
              onClick={toggleConfirmPasswordVisibility}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="bg-green-800 w-full text-sm py-2 my-3 rounded"
        >
          Update Club
        </button>
      </from>
    </div>
  );
};

export default ChangeClub;
