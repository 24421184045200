import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Home from "../../Pages/Home/Home";
import Main from "../../Layout/Main/Main";
import UserDashboard from "../../Layout/UserDashboard/UserDashboard";
import BetHistory from "../../Pages/Dashboard/BetHistory/BetHistory";
import WithdrawHistory from "../../Pages/Dashboard/WithdrawHistory/WithdrawHistory";
import DepositHistory from "../../Pages/Dashboard/DepositHistory/DepositHistory";
import TransactionHistory from "../../Pages/Dashboard/TransactionHistory/TransactionHistory";
import BalanceTransferHistory from "../../Pages/Dashboard/BalanceTransferHistory/BalanceTransferHistory";
import Sponsors from "../../Pages/Dashboard/Sponsors/Sponsors";
import PersonalProfile from "../../Pages/Dashboard/PersonalProfile/PersonalProfile";
import AdminDashboard from "../../Layout/AdminDashboard/AdminDashboard";
import DashboardAdmin from "../../Pages/AdminDashboard/DashboardAdmin/DashboardAdmin";
import AdminLogin from "../../Pages/Login/AdminLogin/AdminLogin";
import AdminProfile from "../../Pages/AdminDashboard/AdminProfile/AdminProfile";
import ClubLogin from "../../Pages/Login/ClubLogin/ClubLogin";
import RedirectAuth from "../PrivateRoute/RedirectAuth";
import AdminAuth from "../PrivateRoute/AdminAuth";
import UserAuth from "../PrivateRoute/UserAuth";
import ThirdAdminBalanceTransfer from "../../Pages/AdminDashboard/ThirdAdminBalanceTransfer/ThirdAdminBalanceTransfer";
import ClubDashboard from "../../Layout/ClubDashboard/ClubDashboard";
import AllClub from "../../Pages/AdminDashboard/UserInformation/AllClub/AllClub";
import AllUser from "../../Pages/AdminDashboard/UserInformation/AllUser/AllUser";
import UserBalanceTransferHistory from "../../Pages/AdminDashboard/UserBalanceTransferHistory/UserBalanceTransferHistory";
import WithdrawNumberHistory from "../../Pages/AdminDashboard/MobileNumberHistory/WIthdrawNumberHistory/WithdrawNumberHistory";
import DepositNumberHistory from "../../Pages/AdminDashboard/MobileNumberHistory/DepositNumberHistory/DepositNumberHistory";
import NoticeBoard from "../../Pages/AdminDashboard/NoticeBoard/NoticeBoard";
import UserWithdrawInbox from "../../Pages/AdminDashboard/AllInbox/UserWithdrawInbox/UserWithdrawInbox";
import UserDepositInbox from "../../Pages/AdminDashboard/AllInbox/UserDepositInbox/UserDepositInbox";
import ClubWithdrawInbox from "../../Pages/AdminDashboard/AllInbox/ClubWithdrawInbox/ClubWithdrawInbox";
import ClubAuth from "../PrivateRoute/ClubAuth";
import ClosedMatchHistory from "../../Pages/AdminDashboard/AllBetting/ClosedMatchHistory/ClosedMatchHistory";
import BettingTransaction from "../../Pages/AdminDashboard/AllBetting/BettingTransaction/BettingTransaction";
import Settings from "../../Pages/AdminDashboard/Settings/Settings";
import AdminTransactionHistory from "../../Pages/AdminDashboard/AdminTransactionHistory/AdminTransactionHistory";
import ClubProfile from "../../Pages/ClubDashboard/ClubProfile/ClubProfile";
import BettingPanel from "../../Pages/AdminDashboard/AllBetting/BettingPanel/BettingPanel";
import DummyPage from "../../Pages/DummyPage";
import SetDefaultMatch from "../../Pages/AdminDashboard/AllBetting/BettingPanel/SetDefaultMatch";
import UserActions from "../../Pages/AdminDashboard/AllBetting/BettingPanel/UserActions";
import AllUserWithdraw from "../../Pages/AdminDashboard/UserInformation/AllUserWithdraw";
import SingleUser from "../../Pages/AdminDashboard/UserInformation/AllUser/SingleUser";
import ClubMembers from "../../Pages/ClubDashboard/ClubProfile/ClubMembers";
import ClubHistory from "../../Pages/ClubDashboard/ClubHistory/ClubHistory";
import ClubTransaction from "../../Pages/ClubDashboard/ClubTransaction";
import ClubWithdrawHistory from "../../Pages/ClubDashboard/ClubWithdrawHistory";
import ClubDashboardHome from "../../Layout/ClubDashboard/ClubDashboardHome";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    children: [
      {
        path: "/",
        element: <Home />,
      },
    ],
  },
  {
    path: "/user",
    element: (
      <UserAuth allowedRoles={["customer"]}>
        <UserDashboard />
      </UserAuth>
    ),
    children: [
      {
        path: "/user/bet-history",
        element: <BetHistory />,
      },
      {
        path: "/user/deposit-history",
        element: <DepositHistory />,
      },
      {
        path: "/user/withdraw-history",
        element: <WithdrawHistory />,
      },
      {
        path: "/user/transaction-history",
        element: <TransactionHistory />,
      },
      {
        path: "/user/balance-transfer-history",
        element: <BalanceTransferHistory />,
      },
      {
        path: "/user/sponsors",
        element: <Sponsors />,
      },
      {
        path: "/user/profile",
        element: (
          <UserAuth allowedRoles={["customer"]}>
            <PersonalProfile />
          </UserAuth>
        ),
      },
    ],
  },
  {
    path: "/dummy",
    element: <DummyPage />,
  },
  {
    path: "/admin/login",
    element: <AdminLogin />,
  },
  {
    path: "/admin",
    element: (
      <AdminAuth allowedRoles={["superadmin"]}>
        <AdminDashboard />
      </AdminAuth>
    ),
    children: [
      {
        path: "/admin",
        element: (
          <AdminAuth allowedRoles={["superadmin"]}>
            <DashboardAdmin />
          </AdminAuth>
        ),
      },
      {
        path: "/admin/notice-board",
        element: <NoticeBoard />,
      },
      {
        path: "/admin/user-withdraw-inbox",
        element: <UserWithdrawInbox />,
      },
      {
        path: "/admin/betting-panel",
        element: <BettingPanel />,
      },
      {
        path: "/admin/betting-panel/user-actions",
        element: <UserActions />,
      },
      {
        path: "/admin/betting-panel/set-default-match",
        element: <SetDefaultMatch />,
      },
      {
        path: "/admin/betting-transaction",
        element: <BettingTransaction />,
      },
      {
        path: "/admin/closed-match-history",
        element: <ClosedMatchHistory />,
      },
      {
        path: "/admin/user-deposit-inbox",
        element: <UserDepositInbox />,
      },
      {
        path: "/admin/club-withdraw-inbox",
        element: <ClubWithdrawInbox />,
      },
      {
        path: "/admin/settings",
        element: <Settings />,
      },
      {
        path: "/admin/profile",
        element: <AdminProfile />,
      },
      {
        path: "/admin/withdraw-numbers",
        element: <WithdrawNumberHistory />,
      },
      {
        path: "/admin/deposit-numbers",
        element: <DepositNumberHistory />,
      },
      {
        path: "/admin/all-club",
        element: <AllClub />,
      },
      {
        path: "/admin/all-user",
        element: <AllUser />,
      },
      {
        path: "/admin/single-user",
        element: <SingleUser />,
      },
      {
        path: "/admin/all-user-withdraw",
        element: <AllUserWithdraw />,
      },
      {
        path: "/admin/all-user-withdraw",
        element: <UserBalanceTransferHistory />,
      },
      {
        path: "/admin/third-admin-balance-transfer",
        element: <ThirdAdminBalanceTransfer />,
      },
      {
        path: "/admin/transaction-history",
        element: <AdminTransactionHistory />,
      },
      {
        path: "/admin/user-balance-transfer",
        element: <BalanceTransferHistory />,
      },
    ],
  },
  {
    path: "/club/login",
    element: <ClubLogin />,
  },
  {
    path: "/club",
    element: (
      <ClubAuth allowedRoles={["club"]}>
        <ClubDashboard />
      </ClubAuth>
    ),
    children: [
      {
        path: "/club",
        element: <ClubDashboardHome />,
      },
      {
        path: "/club/profile",
        element: <ClubProfile />,
      },
      {
        path: "/club/club-members",
        element: <ClubMembers />,
      },
      {
        path: "/club/club-history",
        element: <ClubHistory />,
      },
      {
        path: "/club/club-transactions",
        element: <ClubTransaction />,
      },
      {
        path: "/club/club-withdraw-history",
        element: <ClubWithdrawHistory />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <h1 className="flex h-screen items-center justify-center">
        Not found 404
      </h1>
    ),
  },
]);

export default router;
