import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import Swal from "sweetalert2";
import { useEditRuleMutation } from "../../../features/api/rulesApi";

const UpdateUserRules = ({ rule, closeModal, openModal }) => {
  const [editUserRule] = useEditRuleMutation();
  const [value, setValue] = useState("");

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  const ModalClose = (e) => {
    if (e.target.id === "ModalBackground") closeModal();
  };

  if (!openModal) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (rule === "minimum balance") {
      const sData = { minimum_balance: value };
      //   axios
      //     .patch(
      //       "https://rakibvai.munihaelectronics.com/backend/public/api/rules/1/minimum-balance",
      //       sData,
      //       {
      //         headers: {
      //           authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      //         },
      //       }
      //     )
      //     .then((response) => {
      //       Swal.fire({
      //         text: `Minimum Balance Updated`,
      //         icon: "success",
      //       }).then(() => {
      //         window.location.reload();
      //       });
      //     });
      const response = await editUserRule(sData);
      if (response) {
        Swal.fire({
          text: `Minimum Balance Updated`,
          icon: "success",
        });
      }
    } else if (rule === "waiting time") {
      axios
        .patch(
          "https://rakibvai.munihaelectronics.com/backend/public/api/rules/1/waiting-time",
          {
            waiting_time: value,
          },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          Swal.fire({
            icon: "success",
            text: "Waiting Time Updated",
          }).then(() => {
            window.location.reload();
          });
        });
    } else if (rule === "waiting time after deposit") {
      axios
        .patch(
          "https://rakibvai.munihaelectronics.com/backend/public/api/rules/1/waiting-time-after-deposit",
          {
            waiting_time_after_deposit: value,
          },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          Swal.fire({
            icon: "success",
            text: "Waiting time after deposit Updated",
          }).then(() => {
            window.location.reload();
          });
        });
    } else if (rule === "waiting time after first withdraw") {
      axios
        .patch(
          "https://rakibvai.munihaelectronics.com/backend/public/api/rules/1/waiting-time-after-withdraw",
          {
            waiting_time_after_withdraw: value,
          },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          Swal.fire({
            icon: "success",
            text: "Waiting time after first withdraw Updated",
          }).then(() => {
            window.location.reload();
          });
        });
    } else if (rule === "club commission") {
      axios
        .patch(
          "https://rakibvai.munihaelectronics.com/backend/public/api/rules/1/club-commission",
          {
            club_commission: value,
          },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          Swal.fire({
            icon: "success",
            text: "All club commission Updated",
          }).then(() => {
            window.location.reload();
          });
        });
    } else if (rule === "user sponsor") {
      axios
        .patch(
          "https://rakibvai.munihaelectronics.com/backend/public/api/rules/1/user-sponsor",
          {
            user_sponsor: value,
          },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          Swal.fire({
            icon: "success",
            text: "All user sponsor Updated",
          }).then(() => {
            window.location.reload();
          });
        });
    }
  };

  return (
    <div
      id="ModalBackground"
      onClick={ModalClose}
      className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50"
    >
      <div className="w-11/12 sm:4/5 md:3/5 lg:w-2/5 p-5 rounded bg-white text-black">
        <div className="flex items-center justify-between ">
          <h1 className="text-xl font-bold">
            {" "}
            <span className="pr-3 text-green-400">|</span>Update User Rules{" "}
          </h1>
          <p className="text-lg text-gray-500" onClick={closeModal}>
            <FaTimes />
          </p>
        </div>
        <div className="">
          <form onSubmit={handleSubmit} className="mt-5">
            <div className="w-full my-4 ">
              <input
                type="text"
                id="rule"
                placeholder={`Enter ${rule}`}
                className="p-2 rounded w-full text-sm border-2 focus:outline-teal-600"
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="bg-green-800 text-white text-sm w-full font-bold p-2 mt-3 rounded"
            >
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateUserRules;
