import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash, FaTimes } from 'react-icons/fa';
import { useUpdateCompanyCapitalMutation } from '../../../features/api/bettingmatchApi';
import { toast } from 'react-toastify';

const BalanceUpdate = ({ closeModal, openModal, data }) => {

    useEffect(() => {
        if (openModal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [openModal]);

    const ModalClose = (e) => {
        if (e.target.id === 'ModalBackground')
            closeModal()
    }
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("");

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [capitalBalance, setCapitalBalance] = useState("")
    const [updateCompanyCapital] = useUpdateCompanyCapitalMutation();
    const handelUpdateSubmit = async (e) => {
        e.preventDefault();
        const data = {
            capital: capitalBalance
        };
        try {
           const response =  await updateCompanyCapital(data);
           console.log(response);
            if (!response?.error) {
                toast.success("Balance Updated Successfully!");
                closeModal();
            }
            
        } catch (error) {
            console.error(error);
        }
    };
   

    if (!openModal) return null;
    return (
        <div
            id='ModalBackground'
            onClick={ModalClose}
            className='fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50'
        >
            <div
                className='w-11/12 sm:4/5 md:3/5 lg:w-2/5 p-5 rounded bg-white text-black'
            >
                <div className='flex items-center justify-between '>
                    <h1 className='text-xl font-bold'> <span className='pr-3 text-green-400'>|</span>Update Balance </h1>
                    <p className='text-lg text-gray-500' onClick={closeModal}><FaTimes /></p>
                </div>
                <div className='' >
                    <form onSubmit={handelUpdateSubmit} action="" className='mt-5'>
                        <div className="flex items-center justify-between w-full my-4 ">
                            <label
                                htmlFor="CapitalBalance"
                                className="text-sm font-bold "
                            >
                                Capital Balance
                            </label>
                            <input
                                type="text"
                                onChange={e => setCapitalBalance(Number(e.target.value))}
                                id="CapitalBalance"
                                placeholder="Capital Balance"
                                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
                            />
                        </div>

                        
                        <button type='submit' className='bg-green-800 text-white text-sm w-full font-bold p-2 mt-3 rounded'>Submit</button>
                    </form>
                </div >
            </div >
        </div >
    );
};

export default BalanceUpdate;