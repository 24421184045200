import React from "react";
import userImg from "../../../../Assets/Logo/user-logo.png";

const ProfileInfo = ({ user }) => {
  console.log(user);
  return (
    <div className="">
      <div className="mt-5 flex justify-center gap-4 lg:gap-10 ">
        <div className="p-3 bg-gray-700 rounded-lg flex items-center">
          <img src={userImg} alt="user logo" className="  w-20" />
        </div>
        <table className="">
          <tbody className="text-sm font-semibold">
            <tr>
              <td>User Name</td>
              <td>{user?.userId}</td>
            </tr>
            <tr>
              <td className="w-48">Sponsor Id</td>
              <td>
                <span className="">{user?.sponsorUsername}</span>
              </td>
            </tr>

            <tr>
              <td>Phone Number</td>
              <td>{user?.mobileNumber}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>
                {user?.status === "1" ? (
                  <span className="bg-green-600 px-2 rounded">Active</span>
                ) : (
                  <span className="bg-red-600 px-2 rounded">Deactive</span>
                )}
              </td>
            </tr>
            <tr>
              <td>Club</td>
              <td>{user?.clubId}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* <from>
                <div className='lg:flex gap-4'>
                    <div className='w-full my-2'>
                        <label htmlFor="Username" className='text-sm font-semibold'>Username</label>
                        <input type="text" id="Username" placeholder="" value={'khorshed13'} disabled className="p-2 text-gray-400 rounded w-full text-sm text-white bg-gray-950" />

                    </div>
                    <div className='w-full my-2'>
                        <label htmlFor="PhoneNumber" className='text-sm font-semibold'>Phone Number</label>
                        <input type="text" id="PhoneNumber" placeholder="" value={"01521101855"} disabled className="p-2 text-gray-400 rounded w-full text-sm text-white bg-gray-950" />
                    </div>
                </div>
                <div className='lg:flex gap-4'>
                    <div className='w-full my-2'>
                        <label htmlFor="Referred" className='text-sm font-semibold'>Referred By</label>
                        <input type="text" id="Referred" placeholder="" value={'KA13'} disabled className="p-2 text-gray-400 rounded w-full text-sm text-white bg-gray-950" />

                    </div>
                    <div className='w-full my-2'>
                        <label htmlFor="Club" className='text-sm font-semibold'>Club</label>
                        <input type="text" id="Club" placeholder="" value={"Dark Tech"} disabled className="p-2 text-gray-400 rounded w-full text-sm text-white bg-gray-950" />
                    </div>
                </div>
            </from> */}
    </div>
  );
};

export default ProfileInfo;
