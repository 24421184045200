import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useEditAnswerRateMutation } from "../../../../features/api/getanswerApi";
import { toast } from "react-toastify";
import { selectSectionList } from "../../../../data";
import axios from "axios";
import BettingDHiddenModal2 from "./BettingDHiddenModal2";

const BettingDHiddenModal = ({ passData }) => {
  const [visible, setVisible] = useState(false);
  const [rate, setRate] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [sectionId, setSectionId] = useState("");
  const [sectionList, setSectionList] = useState([]);
  // const copyData = [{ matchId: passData?.id }, { data: sectionList }];
  const copyData = { matchId: passData?.id, data: sectionList };

  const handleClickSection = async (value) => {
    try {
      const response = await axios.get(
        `https://rakibvai.munihaelectronics.com/backend/public/api/get-default-questions-by-section/${value}/${passData?.gameType}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      setSectionList(response?.data);
      if (response?.data) {
        setVisible(false);
        handleAddQuestionModal();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [openAddAnswerModal, setOpenAddQuestionModal] = useState(false);
  const handleAddQuestionModal = (data) => {
    setOpenAddQuestionModal(true);
    // closeModal()
  };
  const handleCloseAddAnswerModal = () => setOpenAddQuestionModal(false);

  const [answerRateEdit] = useEditAnswerRateMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const sendData = {
        answerId: passData?.id,
        editAns: passData?.title,
        editAnsRate: +rate,
        questionIdForEditAns: passData?.bettingSubTitleId,
        matchidForEditAns: passData?.betting_titles_id,
      };
      const response = await answerRateEdit(sendData);
      if (response?.data) {
        toast.success("Success...!!");
        setVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="card flex justify-content-center ">
      <button
        onClick={() => setVisible(true)}
        className="bg-cyan-700 font-semibold text-white p-2 rounded-sm"
      >
        d-hidden
      </button>
      <Dialog
        header=""
        visible={visible}
        onHide={() => setVisible(false)}
        style={{
          width: "20vw",
          backgroundColor: "white",
          color: "black",
          padding: "10px",
          border: "1px solid gray",
          borderRadius: "10px",
        }}
        breakpoints={{ "960px": "50vw", "641px": "80vw" }}
      >
        <div
          style={{
            margin: "20px 0",
            display: "flex",
            flexDirection: "column",
            gap: "2px",
          }}
        >
          {selectSectionList?.map((item) => (
            <button
              style={{
                textAlign: "center",
                backgroundColor: "white",
                // backgroundColor: isHovered ? "red" : "white",
                border: "1px solid gray",
                width: "100%",
                padding: "10px 0",
                hover: "bg-red-700",
                transition: "background-color 0.3s",
              }}
              onClick={() => handleClickSection(item?.id)}
              // onClick={() => setSectionId(item?.id)}
              // onMouseEnter={() => setIsHovered(true)}
              // onMouseLeave={() => setIsHovered(false)}
            >
              {item?.name}
            </button>
          ))}
        </div>
      </Dialog>

      <BettingDHiddenModal2
        data={copyData}
        openModal={openAddAnswerModal}
        closeModal={handleCloseAddAnswerModal}
      />
    </div>
  );
};

export default BettingDHiddenModal;
