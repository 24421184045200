import axios from "axios";
import React from "react";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";

const PasswordSetting = ({ user }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async () => {
    const changePass = {
      current_password: password,
      new_password: newPassword,
    };
    try {
      const response = await axios.post(
        "https://rakibvai.munihaelectronics.com/backend/public/api/change-password",
        changePass,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response?.data) {
        toast.success(response?.data?.message);
        localStorage.removeItem("accessToken");
        setTimeout(() => {
          window.location.reload();
        }, 0.5);
      }
    } catch (error) {
      toast.error(error?.response.data?.message);
    }
  };

  // console.log(user?.password)
  return (
    <div className="px-5">
      <from>
        <div className="relative w-full my-2">
          <label htmlFor="CurrentPassword" className="text-sm font-semibold">
            Current Password
          </label>
          <div className="relative" id="CurrentPassword">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Current Password"
              className="p-2 rounded w-full text-sm text-white bg-gray-950 "
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 text-white text-xl cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
        </div>

        <div className="lg:flex gap-4">
          <div className="relative w-full my-2">
            <label htmlFor="NewPassword" className="text-sm font-semibold">
              New Password
            </label>
            <div className="relative" id="NewPassword">
              <input
                type={showNewPassword ? "text" : "password"}
                placeholder="New Password"
                className="p-2 rounded w-full text-sm text-white bg-gray-950 "
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <div
                className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 text-white text-xl cursor-pointer"
                onClick={toggleNewPasswordVisibility}
              >
                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
          </div>
          <div className="relative w-full my-2">
            <label htmlFor="ConfirmPassword" className="text-sm font-semibold">
              Confirm Password
            </label>
            <div className="relative" id="ConfirmPassword">
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                className="p-2 rounded w-full text-sm text-white bg-gray-950 "
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div
                className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 text-white text-xl cursor-pointer"
                onClick={toggleConfirmPasswordVisibility}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
            {confirmPassword && newPassword !== confirmPassword && (
              <p className="text-red-500 font-bold mt-1">
                Password did not matched
              </p>
            )}
          </div>
        </div>
        <button
          onClick={handleSubmit}
          className="bg-green-800 w-full text-sm py-2 my-3 rounded"
        >
          Update Password
        </button>
      </from>
    </div>
  );
};

export default PasswordSetting;
