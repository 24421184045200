import React, { useEffect, useState } from "react";
import QuestionLimitModal from "./QuestionLimitModal";
import QuestionWaitModal from "./QuestionWaitModal";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import LiveMatchAnswer from "./LiveMatchAnswer";
import {
  useEditNormalQuestionHideMutation,
  useEditQuestionAriaVisibilityMutation,
  useEditQuestionStatusMutation,
  useEditQuestionVisibilityMutation,
} from "../../../../features/api/getquestionApi";
function LiveMatchQuestion({
  ques,
  answers,
  handleAccordionAction1Modal,
  filterQuesBet,
}) {
  const totalQuesBet = filterQuesBet?.reduce(
    (sum, cur) => sum + Number(cur?.betAmount),
    0
  );
  console.log(ques);
  const matchId = ques?.bettingId;
  const questionId = ques?.id;

  const [editNormalQuestionHide] = useEditNormalQuestionHideMutation();
  const handleQuestionHide = async () => {
    try {
      const sendData = {
        id: ques?.id,
        qhide: 0,
      };
      await editNormalQuestionHide(sendData);
    } catch (error) {
      console.log(error);
    }
  };

  const [editQuestionAriaVisibility] = useEditQuestionAriaVisibilityMutation();
  const [editQuestionStatus] = useEditQuestionStatusMutation();
  const [editQuestionVisibility] = useEditQuestionVisibilityMutation();
  const handleQuestionStatus = async (val) => {
    try {
      if (val === 1) {
        const sendData1 = {
          id: ques?.id,
          showStatus: ques?.showStatus === "1" ? "0" : "1",
        };
        await editQuestionStatus(sendData1);
      } else if (val === 2) {
        const sendData2 = {
          id: ques?.id,
          hide: ques?.hide === "1" ? "0" : "1",
        };
        await editQuestionVisibility(sendData2);
      } else if (val === 3) {
        const sendData3 = {
          id: ques?.id,
          ariaHide: ques?.ariaHide === "1" ? "0" : "1",
        };
        await editQuestionAriaVisibility(sendData3);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [expanded, setExpanded] = useState(ques?.ariaHide === "0");

  useEffect(() => {
    setExpanded(ques?.ariaHide === "0");
  }, [ques?.ariaHide]);

  const handleAccordionChange = (isExpanded) => {
    if (ques?.ariaHide !== "1") {
      setExpanded(isExpanded);
    }
  };
  return (
    <div className="py-0.5">
      <Accordion
        expanded={expanded}
        onChange={(event, isExpanded) => handleAccordionChange(isExpanded)}
        className="bg-gray-200"
      >
        <div
          className="bg-[#D7CCC8] flex flex-wrap items-center gap-x-1 rounded-sm"
          style={{ paddingBottom: "5px" }}
        >
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <p className="text-sm">{ques?.title}</p>
          </AccordionSummary>
          <div className="flex flex-wrap gap-1 items-center pl-1">
            <button
              onClick={() => handleAccordionAction1Modal(ques)}
              className="bg-emerald-700 text-white p-2 text-xs md:text-sm rounded-sm"
            >
              Action
            </button>
            <QuestionLimitModal datas={ques} />
            <QuestionWaitModal datas={ques} />
            <button onClick={() => handleQuestionStatus(1)}>
              {ques?.showStatus === "0" ? (
                <span className="bg-red-700 font-bold text-white p-2 text-xs md:text-sm rounded-sm uppercase">
                  Active
                </span>
              ) : (
                <span className="bg-emerald-700 font-bold text-white p-2 text-xs md:text-sm rounded-sm uppercase">
                  Stop
                </span>
              )}
            </button>
            <button onClick={() => handleQuestionStatus(2)}>
              {ques?.hide === "0" ? (
                <span className="bg-red-700 font-bold text-white p-2 text-xs md:text-sm rounded-sm uppercase">
                  Show
                </span>
              ) : (
                <span className="bg-emerald-700 font-bold text-white p-2 text-xs md:text-sm rounded-sm uppercase">
                  Hide
                </span>
              )}
            </button>
            <button onClick={() => handleQuestionStatus(3)}>
              {ques?.ariaHide === "0" ? (
                <span className="bg-emerald-700 font-bold text-white p-2 text-xs md:text-sm rounded-sm">
                  Aria Hide
                </span>
              ) : (
                <span className="bg-red-700 font-bold text-white p-2 text-xs md:text-sm rounded-sm">
                  Area Show
                </span>
              )}
            </button>
            {/* <button className="bg-emerald-700 text-white p-2 text-xs md:text-sm rounded-sm">
              Hide
            </button> */}
            <button
              onClick={handleQuestionHide}
              className="bg-emerald-700 text-white p-2 text-xs md:text-sm rounded-sm"
            >
              Q-Hide
            </button>
            <span className="font-semibold">|| operator (OpaterT10)</span>
            <p className="bg-red-500 rounded-sm mr-2 text-white font-bold px-2">
              {filterQuesBet?.length}
            </p>{" "}
            <p className="bg-cyan-600 rounded-sm text-white font-bold px-2">
              {totalQuesBet}
            </p>
          </div>
        </div>

        <AccordionDetails className="gap-x-2">
          <LiveMatchAnswer
            answers={answers}
            filterQuesBet={filterQuesBet}
            questionId={questionId}
            matchId={matchId}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default LiveMatchQuestion;
