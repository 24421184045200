import React, { useState } from "react";
import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import DefaultQuestionAnserModal from "./DefaultQuestionAnserModal";
import { useRemoveNormalQuestionMutation } from "../../../../features/api/getquestionApi";

const AccordionAction1Modal = ({ data, closeModal, openModal }) => {
  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  const [removeNormalQuestion] = useRemoveNormalQuestionMutation();

  const handleDeleteDefaultQuestion = async () => {
    try {
      const confirm = window.confirm("Are you sure you want to delete");
      if (confirm) {
        const response = await removeNormalQuestion(data?.id);
        if (response?.data) {
          toast.success(response?.data?.message);
          closeModal();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [openAddAnswerModal, setOpenAddQuestionModal] = useState(false);
  const handleAddQuestionModal = (data) => {
    setOpenAddQuestionModal(true);
    // closeModal()
  };
  const handleCloseAddAnswerModal = () => setOpenAddQuestionModal(false);

  if (!openModal) return null;
  return (
    <div
      id="ModalBackground"
      // onClick={ModalClose}
      className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50"
    >
      <div className="w-11/12 sm:4/5 md:3/5 lg:w-1/5 p-5 rounded bg-white text-black">
        <div className="flex justify-end">
          <p
            className="text-lg text-gray-500 cursor-pointer"
            onClick={closeModal}
          >
            <FaTimes />
          </p>
        </div>
        <div className="flex flex-col mt-4 gap-y-2">
          <button
            onClick={() => handleAddQuestionModal()}
            className="bg-white shadow-lg border rounded-sm py-3 text-emerald-600 font-bold"
          >
            Add Answer
          </button>
          <button
            onClick={handleDeleteDefaultQuestion}
            className="bg-white shadow-lg border rounded-sm py-3 text-red-600 font-bold"
          >
            Delete Question
          </button>
        </div>
      </div>
      <DefaultQuestionAnserModal
        answerData={data}
        openModal={openAddAnswerModal}
        closeModal={handleCloseAddAnswerModal}
      />
    </div>
  );
};

export default AccordionAction1Modal;
