import React, { useState, useEffect } from "react";
import "quill/dist/quill.snow.css";
import { useQuill } from "react-quilljs";
import {
  useGetNoticeQuery,
  useUpdateNoticeMutation,
} from "../../../features/api/bettingmatchApi";
import { toast } from "react-toastify";

const NoticeBoard = () => {
  const { data } = useGetNoticeQuery("", {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const notice = data?.notice?.notice;

  const { quill, quillRef } = useQuill();
  const [updateNotice] = useUpdateNoticeMutation();

  useEffect(() => {
    if (quill && notice) {
      quill.clipboard.dangerouslyPasteHTML(`<h1>${notice}</h1>`);
    }
  }, [quill, notice]);

  const handleUpdate = async () => {
    if (quill) {
      const updatedContent = quill.root.innerHTML;
      try {
        await updateNotice({ notice: updatedContent }).unwrap();
        toast.success("Notice updated successfully!");
      } catch (error) {
        console.error("Failed to update notice:", error);
        toast.error("Failed to update notice. Please try again.");
      }
    }
  };

  return (
    <div className="mt-12 p-5 ">
      <div className="bg-white text-black rounded">
        <div className=" flex gap-2 items-center bg-gray-100 px-5 py-3 border-b rounded-t">
          <i className="fa-solid fa-clapperboard text-2xl pt-1" />
          <h1 className="text-2xl font-semibold">Notice Board</h1>
        </div>
        <div className="p-5 w-2/3">
          <h1 className="text-sm font-semibold mb-2">Comment:</h1>
          <div>
            <div ref={quillRef} />
          </div>
          <button
            onClick={handleUpdate}
            className="mt-3 p-1 text-sm text-white font-semibold border rounded w-1/2 bg-green-600"
          >
            Update
          </button>
        </div>
        <div className="px-5 py-4 bg-gray-100 p-5 border-t rounded-b"></div>
      </div>
    </div>
  );
};

export default NoticeBoard;
