// import React, { useEffect, useState } from "react";
// import { FaTimes } from "react-icons/fa";
// import { formatTime } from "../../SharedDateFormat";
// import ball from "../../../Assets/photos/ball.png";
// import FootballImg from "../../../Assets/photos/football.png";
// import TableTennisImg from "../../../Assets/photos/table-tennis.png";
// import { useGetCombinedMatchesQuery } from "../../../features/api/bettingmatchApi";
// import ReloadPusher from "../../ReloadPusher";

// const PlaceBet = ({
//   icon,
//   closeModal,
//   openModal,
//   datas,
//   answers,
//   questions,
//   refetch,
// }) => {
//   const [stake, setStake] = useState(100);
//   const handleStake = (s) => setStake(s);

//   const handleInputChange = (event) => {
//     setStake(event.target.value);
//   };

//   const stakeValues = ["500", "1000", "1500", "2000", "3000"];

//   const [loading, setLoading] = useState(false);
//   const [suspended, setSuspended] = useState(false);

//   const handleSubmit = async () => {
//     console.log("first");
//     refetch();

//     let waitTime;

//     if (questions?.waittingTime > datas?.waittingTime) {
//       waitTime = questions?.waittingTime;
//     } else {
//       waitTime = datas?.waittingTime;
//     }
//     console.log(waitTime);

//     if (waitTime > 0) {
//       setLoading(true);
//       await new Promise((resolve) => setTimeout(resolve, waitTime * 1000));
//       setLoading(false);
//     }
//     if (questions?.showStatus !== "1") {
//       setSuspended(true);
//       return;
//     }
//     console.log("submit");
//   };

//   const [getData, setGetData] = useState({});
//   useEffect(() => {
//     if (Object.keys(getData).length > 0) {
//       refetch();
//     }
//   }, [getData, refetch]);

//   useEffect(() => {
//     if (openModal) {
//       document.body.style.overflow = "hidden";
//     } else {
//       document.body.style.overflow = "unset";
//     }
//   }, [openModal]);

//   const modalClose = (e) => {
//     if (e.target.id === "modalBackground") closeModal();
//   };

//   if (!openModal) return null;

//   return (
//     <div
//       id="modalBackground"
//       onClick={modalClose}
//       className="fixed inset-0 bg-black bg-opacity-40 snap-alw flex justify-center items-center"
//     >
//       <div
//         className="w-11/12 sm:4/5 md:3/5 lg:w-2/5 p-5 rounded"
//         style={{ background: "linear-gradient(360deg, #37103a, #08545B)" }}
//       >
//         <div className="flex items-center justify-between">
//           <h1 className="text-2xl font-bold">
//             {" "}
//             <span className="pr-3 text-green-400">|</span>Place Bet and{" "}
//             {questions?.showStatus}
//           </h1>
//           <p className="text-lg text-gray-500" onClick={closeModal}>
//             <FaTimes />
//           </p>
//         </div>
//         <div>
//           <form className="mt-5">
//             <div className="flex items-center gap-4 py-3 rounded cursor-pointer">
//               {/* <img
//                 alt="Ball"
//                 src={icon}
//                 className=" h-[35px] w-[35px] my-2 shadow-lg"
//               /> */}
//               {datas?.gameType == 1 ? (
//                 <img
//                   alt="Ball"
//                   src={FootballImg}
//                   className=" h-[35px] w-[35px] my-2"
//                 />
//               ) : datas?.gameType == 2 ? (
//                 <img
//                   alt="Ball"
//                   src={ball}
//                   className=" h-[35px] w-[35px] my-2"
//                 />
//               ) : (
//                 <img
//                   alt="Ball"
//                   src={TableTennisImg}
//                   className=" h-[35px] w-[35px] my-2"
//                 />
//               )}
//               <div>
//                 <h1 className="text-base font-semibold">
//                   {datas?.A_team}
//                   <span className="text-yellow-400">VS</span> {datas?.B_team}
//                   <span className="text-xs bg-green-800 px-2 ml-1 rounded">
//                     Status
//                   </span>
//                 </h1>
//                 <p className="font-semibold">
//                   {datas?.title},{" "}
//                   <span className="text-sm"> {formatTime(datas?.date)}</span>
//                 </p>
//               </div>
//             </div>

//             <h1 className="text-sm font-semibold">
//               {" "}
//               <span className="text-green-400 mr-2">{questions?.title}:</span>
//               {answers?.title} {answers?.amount}
//             </h1>
//             <div className="w-3/4 mt-5 text-sm font-semibold">
//               <div className="flex justify-between">
//                 <h1>Bet Rate</h1>
//                 <h1>{answers?.amount}</h1>
//               </div>
//               <div className="flex justify-between">
//                 <h1>Set Stake</h1>
//                 <h1>{stake}</h1>
//               </div>
//               <div className="flex justify-between">
//                 <h1>Winning Return Stake</h1>
//                 <h1>{(stake * answers?.amount).toFixed(2)}</h1>
//               </div>
//             </div>

//             <div className="my-2 flex gap-2 text-center">
//               {stakeValues?.map((value, index) => (
//                 <div
//                   key={index}
//                   className="p-1 w-1/5 border rounded text-sm font-semibold "
//                   onClick={() => handleStake(value)}
//                 >
//                   {value}
//                 </div>
//               ))}
//             </div>

//             <input
//               type="number"
//               name="number"
//               value={stake}
//               onChange={handleInputChange}
//               className="p-2 rounded w-full text-sm text-black"
//             />
//           </form>
//           <button
//             onClick={() => handleSubmit()}
//             disabled={loading}
//             className="bg-green-800 text-sm font-bold p-2 mt-3 w-full rounded"
//           >
//             {loading ? "Loading..." : "Place Your Bet"}
//           </button>
//         </div>
//       </div>
//       {/* Reload by Pusher  */}
//       <ReloadPusher setGetData={setGetData} />
//     </div>
//   );
// };

// export default PlaceBet;

import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import ReloadPusher from "../../ReloadPusher";
import { formatTime } from "../../SharedDateFormat";
import ball from "../../../Assets/photos/ball.png";
import FootballImg from "../../../Assets/photos/football.png";
import TableTennisImg from "../../../Assets/photos/table-tennis.png";
import axios from "axios";

const PlaceBet = ({
  icon,
  closeModal,
  openModal,
  datas,
  answers,
  questions,
  refetch,
}) => {
  const [stake, setStake] = useState(100);
  const [loading, setLoading] = useState(false);
  const [suspended, setSuspended] = useState(false);
  const [getData, setGetData] = useState({});

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  const modalClose = (e) => {
    if (e.target.id === "modalBackground") closeModal();
  };

  const handleStake = (s) => setStake(s);

  const handleInputChange = (event) => {
    setStake(event.target.value);
  };

  const stakeValues = ["500", "1000", "1500", "2000", "3000"];

  const [getTime, setGetTime] = useState(null);
  // console.log(getTime);

  const handleSubmit = async () => {
    refetch();

    const response = await axios.post(
      "https://rakibvai.munihaelectronics.com/backend/public/api/PlaceBett",
      { matchId: datas?.id }
    );
    setGetTime(response?.data?.wait);
    // console.log(response?.data);

    if (response?.data?.wait > 0) {
      setLoading(true);
      await new Promise((resolve) =>
        setTimeout(resolve, response?.data?.wait * 1000)
      );
      setLoading(false);
    }

    // let waitTime = Math.max(questions?.waittingTime, datas?.waittingTime);

    // if (waitTime > 0) {
    //   setLoading(true);
    //   await new Promise((resolve) => setTimeout(resolve, waitTime * 1000));
    //   setLoading(false);
    // }

    // if (questions?.showStatus !== "1") {
    //   setSuspended(true);
    //   return;
    // }

    // console.log("Submit logic here...");
  };

  useEffect(() => {
    if (Object.keys(getData).length > 0) {
      refetch();
    }
  }, [getData, refetch]);

  if (!openModal) return null;

  return (
    <div
      id="modalBackground"
      onClick={modalClose}
      className="fixed inset-0 bg-black bg-opacity-40 snap-alw flex justify-center items-center"
    >
      <div
        className="w-11/12 sm:4/5 md:3/5 lg:w-2/5 p-5 rounded"
        style={{ background: "linear-gradient(360deg, #37103a, #08545B)" }}
      >
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">
            {" "}
            <span className="pr-3 text-green-400">|</span>Place Bet {getTime}{" "}
            and {questions?.showStatus}
          </h1>
          <p className="text-lg text-gray-500" onClick={closeModal}>
            <FaTimes />
          </p>
        </div>
        <div>
          <form className="mt-5">
            <div className="flex items-center gap-4 py-3 rounded cursor-pointer">
              {datas?.gameType == 1 ? (
                <img
                  alt="Ball"
                  src={FootballImg}
                  className="h-[35px] w-[35px] my-2"
                />
              ) : datas?.gameType == 2 ? (
                <img alt="Ball" src={ball} className="h-[35px] w-[35px] my-2" />
              ) : (
                <img
                  alt="Ball"
                  src={TableTennisImg}
                  className="h-[35px] w-[35px] my-2"
                />
              )}
              <div>
                <h1 className="text-base font-semibold">
                  {datas?.A_team}
                  <span className="text-yellow-400">VS</span> {datas?.B_team}
                  <span className="text-xs bg-green-800 px-2 ml-1 rounded">
                    Status
                  </span>
                </h1>
                <p className="font-semibold">
                  {datas?.title},{" "}
                  <span className="text-sm">{formatTime(datas?.date)}</span>
                </p>
              </div>
            </div>

            <h1 className="text-sm font-semibold">
              {" "}
              <span className="text-green-400 mr-2">{questions?.title}:</span>
              {answers?.title} {answers?.amount}
            </h1>
            <div className="w-3/4 mt-5 text-sm font-semibold">
              <div className="flex justify-between">
                <h1>Bet Rate</h1>
                <h1>{answers?.amount}</h1>
              </div>
              <div className="flex justify-between">
                <h1>Set Stake</h1>
                <h1>{stake}</h1>
              </div>
              <div className="flex justify-between">
                <h1>Winning Return Stake</h1>
                <h1>{(stake * answers?.amount).toFixed(2)}</h1>
              </div>
            </div>

            <div className="my-2 flex gap-2 text-center">
              {stakeValues?.map((value, index) => (
                <div
                  key={index}
                  className="p-1 w-1/5 border rounded text-sm font-semibold"
                  onClick={() => handleStake(value)}
                >
                  {value}
                </div>
              ))}
            </div>

            <input
              type="number"
              name="number"
              value={stake}
              onChange={handleInputChange}
              className="p-2 rounded w-full text-sm text-black"
            />
          </form>
          <button
            onClick={() => handleSubmit()}
            disabled={loading}
            className="bg-green-800 text-sm font-bold p-2 mt-3 w-full rounded"
          >
            {loading ? "Loading..." : "Place Your Bet"}
          </button>
        </div>
      </div>
      {/* Reload by Pusher */}
      <ReloadPusher setGetData={setGetData} />
    </div>
  );
};

export default PlaceBet;
