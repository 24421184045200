import React, { useEffect, useRef, useState } from "react";
import HeaderClub from "../../components/HeaderClub/HeaderClub";
import { Link, Outlet } from "react-router-dom";

const ClubDashboard = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const sidebarRef = useRef(null);
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const handleLinkClick = () => {
    setIsSidebarVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);
  return (
    <div>
      <HeaderClub
        toggleSidebar={toggleSidebar}
        isSidebarVisible={isSidebarVisible}
      ></HeaderClub>
      <div className="relative flex text-gray-200 ">
        <div
          ref={sidebarRef}
          className={` ${isSidebarVisible ? "lg:w-1/5 md:w-1/5" : ""}`}
        >
          <div
            className={`w-3/5 fixed bg-[#0c675e] h-screen z-30  ${
              isSidebarVisible ? "lg:w-1/5 md:w-1/5" : "hidden"
            }`}
          >
            <div className="text-[15px] font-semibold mx-5 mt-20 mb-2 ">
              <h1>MAIN NAVIGATION</h1>
            </div>
            <div>
              <ul className="text-[15px]  font-semibold">
                <Link to="/club" onClick={handleLinkClick}>
                  <li className="py-3 border-l-4 hover:border-blue-400 border-[#0c675e] hover:bg-[#3c857e] hover:text-white cursor-pointer transition duration-75 ease-in-out">
                    <i className="fa-solid fa-grip w-12 text-center" />
                    Dashboard
                  </li>
                </Link>
                <Link to="/club/profile" onClick={handleLinkClick}>
                  <li className="py-3 border-l-4 hover:border-blue-400 border-[#0c675e] hover:bg-[#3c857e] hover:text-white cursor-pointer transition duration-75 ease-in-out">
                    <i className="fa-solid fa-user-gear w-12 text-center" />
                    Profile
                  </li>
                </Link>
                <Link to="/club/club-members" onClick={handleLinkClick}>
                  <li className="py-3 border-l-4 hover:border-blue-400 border-[#0c675e] hover:bg-[#3c857e] hover:text-white cursor-pointer transition duration-75 ease-in-out">
                    <i className="fa-solid fa-users w-12 text-center" />
                    Club Member
                  </li>
                </Link>
                <Link to="/club/club-history" onClick={handleLinkClick}>
                  <li className="py-3 border-l-4 hover:border-blue-400 border-[#0c675e] hover:bg-[#3c857e] hover:text-white cursor-pointer transition duration-75 ease-in-out">
                    <i className="fa-solid fa-users-viewfinder w-12 text-center" />
                    Club History
                  </li>
                </Link>
                <Link to="/club/club-withdraw-history" onClick={handleLinkClick}>
                  <li className="py-3 border-l-4 hover:border-blue-400 border-[#0c675e] hover:bg-[#3c857e] hover:text-white cursor-pointer transition duration-75 ease-in-out">
                    <i className="fa-solid fa-bangladeshi-taka-sign w-12 text-center" />
                    Withdraw History
                  </li>
                </Link>
                <Link to="/club/club-transactions" onClick={handleLinkClick}>
                  <li className="py-3 border-l-4 hover:border-blue-400 border-[#0c675e] hover:bg-[#3c857e] hover:text-white cursor-pointer transition duration-75 ease-in-out">
                    <i className="fa-solid fa-chart-column w-12 text-center" />
                    Transaction History
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>

        <div
          className={`w-full min-h-screen bg-[#069e90] ${
            isSidebarVisible ? "lg:w-5/6" : "w-full"
          }`}
        >
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
};

export default ClubDashboard;
