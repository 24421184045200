import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../Assets/logo.png";
import { useState } from 'react';
import ClubPopUp from '../ModalClub/ClubPopUp/ClubPopUp';
import ClubWithdrawModal from '../ModalClub/ClubWithdrawModal/ClubWithdrawModal';

const HeaderClub = ({ toggleSidebar, isSidebarVisible }) => {
    const [openClubPopUp, setOpenClubPopUp] = useState(false);
    const handleClubPopUp = () => setOpenClubPopUp(true);
    const handleCloseClubPopUp = () => setOpenClubPopUp(false);

    const [openClubWithdrawModal, setOpenClubWithdrawModal] = useState(false);
    const handleClubWithdrawModal = () => {
        setOpenClubWithdrawModal(true);
    };
    const handleCloseClubWithdrawModal = () => setOpenClubWithdrawModal(false);
    return (
        <div className="relative ">
            <header className="flex fixed w-full z-40 bg-[#0c675e]">
                <div className={`hidden px-5 py-2 bg-[#09524b] ${isSidebarVisible ? 'lg:w-1/5 md:w-1/5 lg:block' : ''}`} >
                    <div className="flex gap-3 items-center">
                        <Link to="/">
                            {" "}
                            <img alt="Ball65 logo" src={logo} className=" h-[24px] my-2" />
                        </Link>
                        <h1 className="text-sm font-semibold "> CLUB DASHBOARD</h1>
                    </div>
                </div>
                <div className={`px-2 lg:px-5 py-2 ${isSidebarVisible ? 'w-full lg:w-4/5 md:w-4/5' : 'w-full'}`}>
                    <div className="flex items-center justify-between text-lg">
                        <i
                            className={`fa-solid fa-bars p-3 }`}
                            onClick={toggleSidebar}
                        ></i>

                        <div className="lg:hidden " style={{ fontFamily: "Charm" }}>
                            <h1 className="text-lg text-center py-2"> CLUB DASHBOARD</h1>
                        </div>


                        <div className='flex items-center gap-3'>
                            <div className='text-sm  rounded py-1 px-3 bg-[#24766e] hover:bg-[#54948e] cursor-pointer transition duration-75 ease-in-out shadow '
                                onClick={handleClubWithdrawModal}>
                                < i className="fa-solid fa-bangladeshi-taka-sign mr-1" />withdraw
                            </div>
                            <div
                                className={``}
                                onClick={handleClubPopUp}
                            >
                                <i className="fa-solid fa-user-tie p-3  "></i>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <ClubPopUp
                openPopUp={openClubPopUp}
                closePopUp={handleCloseClubPopUp}
            />
            <ClubWithdrawModal
                openModal={openClubWithdrawModal}
                closeModal={handleCloseClubWithdrawModal}
            />
        </div >
    );
};

export default HeaderClub;