import axios from "axios";
import React from "react";
import { useState } from "react";

import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const AdminProfile = () => {
  const location = useLocation();
  const user = location?.state?.user;

  const [name, setName] = useState("");
  const [current_password, setOldPassword] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const editProfile = {
      name: name ? name : user?.name,
      current_password: current_password
        ? current_password
        : user?.current_password,
      new_password: password,
    };
    try {
      const response = await axios.post(
        "https://rakibvai.munihaelectronics.com/backend/public/api/admin-update-profile",
        editProfile,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      console.log(response);
      if (response?.data) {
        toast.success(response?.data?.message);
      }
      if (response?.data && current_password) {
        localStorage.removeItem("accessToken");
        setTimeout(() => {
          window.location.reload();
        }, 0.5);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response.data?.message);
    }
  };

  return (
    <div>
      <div className="mt-12 p-5">
        <div className="mx-auto md:w-5/6 lg:w-3/4 bg-white text-black rounded">
          <div className="p-5 lg:p-10">
            <div className="flex gap-2 border-b pb-5">
              <i className="fa-solid fa-user-pen text-2xl"></i>
              <h1 className="text-2xl font-semibold">Your Profile</h1>
            </div>
            <div className="mt-5">
              <form onSubmit={handleSubmit}>
                <div className="flex items-center justify-between w-full my-4 ">
                  <label
                    htmlFor="name"
                    className="text-sm font-semibold text-gray-500"
                  >
                    Name <span className="text-red-400">*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    defaultValue={user?.name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter the name"
                    className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
                  />
                </div>
                <div className="flex items-center justify-between w-full my-4 ">
                  <label
                    htmlFor="name"
                    className="text-sm font-semibold text-gray-500"
                  >
                    UserId <span className="text-red-400">*</span>
                  </label>
                  <input
                    disabled
                    defaultValue={user?.userId}
                    type="text"
                    className="p-2 cursor-not-allowed rounded w-2/3 text-sm border-2 focus:outline-teal-600"
                  />
                </div>

                <div className="flex items-center justify-between w-full my-4">
                  <label
                    htmlFor="oldPassword"
                    className="text-sm font-semibold text-gray-500"
                  >
                    Old Password <span className="text-red-400">*</span>
                  </label>
                  <input
                    type="password"
                    placeholder="Enter the old password"
                    className="p-2  rounded w-2/3 text-sm border-2 focus:outline-teal-600 "
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </div>
                <div className="flex items-center justify-between w-full my-4">
                  <label
                    htmlFor="newPassword"
                    className="text-sm font-semibold text-gray-500"
                  >
                    New Password <span className="text-red-400">*</span>
                  </label>
                  <input
                    type="password"
                    placeholder="Enter the new password"
                    className="p-2  rounded w-2/3 text-sm border-2 focus:outline-teal-600 "
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <button
                  type="submit"
                  className="bg-teal-500 hover:bg-teal-700 w-full text-sm font-bold text-white py-2 mt-3 rounded"
                >
                  Update My Profile
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProfile;
