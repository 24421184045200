import { apiSlice } from "./apiSlice";

const getquestionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllQuestion: builder.query({
      query: (getMatchId) => ({
        url: `/get-question/${getMatchId}`,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["get-question"],
    }),
    getLiveQuestion: builder.query({
      query: (getMatchId) => ({
        url: `/get-live-match-question/${getMatchId}`,
      }),
      providesTags: ["get-question"],
    }),
    addNewQuestion: builder.mutation({
      query: (data) => ({
        url: "/add-question",
        method: "POST",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["get-question"],
    }),
    // removeQuestion: builder.mutation({
    //   query: (id) => ({
    //     url: `/delete-withdraw-sending-number/${id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["get-question"],
    // }),

    editQuestion: builder.mutation({
      query: (data) => ({
        url: "/edit-question",
        method: "PUT",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: data,
      }),
      invalidatesTags: ["get-question"],
    }),
    editQuestionLimit: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-question-limit-amount/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-question"],
    }),
    editQuestionWait: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-question-waiting-time/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-question"],
    }),
    editQuestionStatus: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-question-status/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-question"],
    }),
    editQuestionVisibility: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-question-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-question"],
    }),
    editQuestionAriaVisibility: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-question-area-visibility/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-question"],
    }),
    editNormalQuestionHide: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/update-normal-qhide/${id}`,
        method: "PUT",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-question"],
    }),
    removeNormalQuestion: builder.mutation({
      query: (id) => ({
        url: `/delete-normal-question/${id}`,
        method: "DELETE",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["get-question", "get-answer", "get-all-matches"],
    }),
  }),
});

export const {
  useGetAllQuestionQuery,
  useGetLiveQuestionQuery,
  useAddNewQuestionMutation,
  // useRemoveQuestionMutation,
  useEditQuestionMutation,
  useEditQuestionLimitMutation,
  useEditQuestionWaitMutation,
  useEditQuestionStatusMutation,
  useEditQuestionVisibilityMutation,
  useEditQuestionAriaVisibilityMutation,
  useEditNormalQuestionHideMutation,
  useRemoveNormalQuestionMutation,
} = getquestionApi;
