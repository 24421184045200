import React, { useEffect } from "react";
import Pusher from "pusher-js";

const ReloadPusher = ({ setGetData }) => {
  useEffect(() => {
    Pusher.logToConsole = true;

    const pusher = new Pusher("d128afcdec80816dd727", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("my-channel");

    const handleDataUpdated = (data) => {
      setGetData(data);
    };

    channel.bind("data-updated", handleDataUpdated);

    return () => {
      channel.unbind("data-updated", handleDataUpdated);
      pusher.unsubscribe("my-channel");
    };
  }, [setGetData]);
  return <div></div>;
};

export default ReloadPusher;
