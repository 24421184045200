import { apiSlice } from "./apiSlice";

const usersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => ({
        url: "/getallusers",
      }),
      providesTags: ["getallusers"],
    }),
    getAllUserWithdraw: builder.query({
      query: () => ({
        url: "/get-customer-withdraw-number-list",
      }),
      providesTags: ["getallusers"],
    }),
    getAlldwnotifications: builder.query({
      query: () => ({
        url: "/dwnotifications",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["get-all-dw-notifications","dwnotifications"],
    }),
    addUser: builder.mutation({
      query: (newCategory) => ({
        url: "/getallusers",
        method: "POST",
        body: newCategory,
      }),
      invalidatesTags: ["getallusers"],
    }),
    addUserWithdrawNumber: builder.mutation({
      query: (data) => ({
        url: "/add-customer-withdraw-number",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["getallusers"],
    }),
    removeUser: builder.mutation({
      query: (id) => ({
        url: `/userdelete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getallusers"],
    }),
    removeUserWithdraw: builder.mutation({
      query: (id) => ({
        url: `/delete-customer-withdraw-number/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getallusers"],
    }),
    editUser: builder.mutation({
      query: ({ id, status }) => ({
        url: `/user/${id}/status/${status}`,
        method: "PUT",
      }),
      invalidatesTags: ["getallusers"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetAllUserWithdrawQuery,
  useGetAlldwnotificationsQuery,
  useAddUserMutation,
  useAddUserWithdrawNumberMutation,
  useRemoveUserMutation,
  useRemoveUserWithdrawMutation,
  useEditUserMutation,
} = usersApi;
