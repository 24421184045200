import { apiSlice } from "./apiSlice";

const mybetsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllMyBets: builder.query({
      query: () => ({
        url: "/mybets",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["mybets"],
    }),
    getAllAdminBets: builder.query({
      query: () => ({
        url: "/admin/all-bets",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["admin/all-bets"],
    }),
    getMyTransactions: builder.query({
      query: () => ({
        url: "/myTransactions",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["myTransactions"],
    }),
    getMyBalanceTransfer: builder.query({
      query: () => ({
        url: "/myBalanceTransfer",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["myBalanceTransfer"],
    }),
    getMySponsors: builder.query({
      query: () => ({
        url: "/mySponsors",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["mySponsors"],
    }),
    getMyMembersBets: builder.query({
      query: () => ({
        url: "/myMembersbets",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["myMembersbets"],
    }),
    getClubTransaction: builder.query({
      query: () => ({
        url: "/clubTransaction",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["clubTransaction"],
    }),
    getAllClubWithdraws: builder.query({
      query: () => ({
        url: "/club-withdraws",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      providesTags: ["club-withdraws"],
    }),
    addTransfer: builder.mutation({
      query: (data) => ({
        url: `/add-transfer`,
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["myBalanceTransfer"],
    }),
    addClubWithdraw: builder.mutation({
      query: (data) => ({
        url: `/add-club-withdraw`,
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["club-withdraws"],
    }),
    addPlaceBet: builder.mutation({
      query: (data) => ({
        url: `/PlaceBet`,
        method: "POST",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: [
        "admin/all-bets",
        "get-all-matches",
        "get-answer",
        "get-question",
      ],
    }),
  }),
});

export const {
  useGetAllMyBetsQuery,
  useGetMyTransactionsQuery,
  useGetAllAdminBetsQuery,
  useGetMyBalanceTransferQuery,
  useGetMySponsorsQuery,
  useGetMyMembersBetsQuery,
  useGetClubTransactionQuery,
  useGetAllClubWithdrawsQuery,
  useAddTransferMutation,
  useAddClubWithdrawMutation,
  useAddPlaceBetMutation,
} = mybetsApi;
