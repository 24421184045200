import { apiSlice } from "./apiSlice";

const rulesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRules: builder.query({
      query: () => ({
        url: "/rules",
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["rules"],
    }),
    editRule: builder.mutation({
      query: (data) => ({
        url: `/rules/1/minimum-balance`,
        method: "PATCH",
        body: data,
        headers: {
          authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
      invalidatesTags: ["rules"],
    }),

    // addUser: builder.mutation({
    //     query: (newCategory) => ({
    //         url: "/getallusers",
    //         method: "POST",
    //         body: newCategory,
    //     }),
    //     invalidatesTags: ["getallusers"],
    // }),
    // removeUser: builder.mutation({
    //     query: (id) => ({
    //         url: `/userdelete/${id}`,
    //         method: "DELETE",
    //     }),
    //     invalidatesTags: ["getallusers"],
    // }),
  }),
});

export const { useGetRulesQuery, useEditRuleMutation } = rulesApi;
