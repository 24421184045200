import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Withdraw from "../Modal/Withdraw/Withdraw";
import Deposit from "../Modal/Deposit/Deposit";
import BalanceTransfer from "../Modal/BalanceTransfer/BalanceTransfer";
import { useGetUserQuery } from "../../features/api/loginApi";

const DashboardPopUp = ({ closeModal, openModal }) => {
  const [isWalletVisible, setIsWalletVisible] = useState(false);

  const toggleWallet = () => {
    setIsWalletVisible(!isWalletVisible);
  };
  const [isHistoryVisible, setIsHistoryVisible] = useState(false);

  const toggleHistory = () => {
    setIsHistoryVisible(!isHistoryVisible);
  };

  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
  const [openDepositModal, setOpenDepositModal] = useState(false);
  const [openBalanceTransferModal, setOpenBalanceTransferModal] =
    useState(false);

  const handleWithdrawModal = () => setOpenWithdrawModal(true);
  const handleCloseWithdrawModal = () => setOpenWithdrawModal(false);

  const handleDepositModal = () => setOpenDepositModal(true);
  const handleCloseDepositModal = () => setOpenDepositModal(false);

  const handleBalanceTransferModal = () => setOpenBalanceTransferModal(true);
  const handleCloseBalanceTransferModal = () =>
    setOpenBalanceTransferModal(false);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  const modalClose = (e) => {
    if (e.target.id === "modalBackground") closeModal();
  };

  const accessToken = localStorage.getItem("accessToken");
  const {
    data,
    error,
    isLoading,
  } = useGetUserQuery(accessToken, {
    skip: !accessToken,
    refetchOnMountOrArgChange: true,
  });

  const logOut = () => {
    localStorage.removeItem("accessToken");
    setTimeout(() => {
      window.location.reload();
    }, 0.5);
  };

  if (!openModal) return null;
  return (
    <div
      id="modalBackground"
      onClick={modalClose}
      className="fixed inset-0 bg-black bg-opacity-40 snap-alw  "
    >
      <div className=" relative">
        <div
          className="absolute top-14 right-0 mr-5 rounded"
          style={{ background: "linear-gradient(1800deg, #37103a, #08545B)" }}
        >
          <div className="m-2">
            <div className="flex gap-2 justify-between p-2 font-semibold mx-auto rounded">
              <h1 className="text-sm ">{data?.user?.userId}</h1>
              <p className="text-red-400">( {data?.user?.balance} )</p>
            </div>
            <ul className="text-sm font-semibold">
              <Link to="/user/profile">
                <li className="p-2 my-1 hover:bg-green-800 cursor-pointer transition duration-75 ease-in-out rounded">
                  <i className="fa-solid fa-user-gear mr-3" />
                  Profile
                </li>
              </Link>

              <Link to="/user/sponsors">
                <li className="p-2 my-1 hover:bg-green-800 cursor-pointer transition duration-75 ease-in-out rounded">
                  <i className="fa-solid fa-users mr-3" />
                  My Sponsor's
                </li>
              </Link>

              <div
                className={`p-2 my-1 hover:bg-green-800 cursor-pointer transition duration-75 ease-in-out rounded flex justify-between ${isWalletVisible ? "bg-gray-800" : ""
                  }`}
                onClick={toggleWallet}
              >
                <div>
                  <i className="fa-solid fa-wallet mr-3" />
                  Wallet
                </div>
                <div>
                  {isHistoryVisible ? (
                    <i className="fa-solid fa-angle-up " />
                  ) : (
                    <i className="fa-solid fa-angle-down " />
                  )}
                </div>
              </div>
              {isWalletVisible && (
                <ul className="ml-4">
                  <li
                    onClick={handleDepositModal}
                    className={`p-2 my-1 hover:bg-green-800 cursor-pointer transition duration-75 ease-in-out rounded ${openDepositModal ? "bg-gray-600" : ""
                      }`}
                  >
                    <i className="fa-solid fa-hand-holding-dollar mr-3" />
                    Deposit
                  </li>
                  <li
                    onClick={handleWithdrawModal}
                    className={`p-2 my-1 hover:bg-green-800 cursor-pointer transition duration-75 ease-in-out rounded ${openWithdrawModal ? "bg-gray-600" : ""
                      }`}
                  >
                    <i className="fa-solid fa-money-bill-trend-up mr-3" />
                    Withdraw
                  </li>
                  <li
                    onClick={handleBalanceTransferModal}
                    className={`p-2 my-1 hover:bg-green-800 cursor-pointer transition duration-75 ease-in-out rounded ${openBalanceTransferModal ? "bg-gray-600" : ""
                      }`}
                  >
                    <i className="fa-solid fa-money-bill-transfer mr-3" />
                    Balance Transfer
                  </li>
                </ul>
              )}

              <div
                className={`p-2 my-1 hover:bg-green-800 cursor-pointer transition duration-75 ease-in-out rounded flex justify-between ${isHistoryVisible ? "bg-gray-800" : ""
                  }`}
                onClick={toggleHistory}
              >
                <div>
                  <i className="fa-solid fa-clock-rotate-left mr-3" />
                  History
                </div>
                <div>
                  {isHistoryVisible ? (
                    <i className="fa-solid fa-angle-up " />
                  ) : (
                    <i className="fa-solid fa-angle-down " />
                  )}
                </div>
              </div>
              {isHistoryVisible && (
                <ul className="ml-4">
                  <Link to="/user/bet-history">
                    <li className="p-2 my-1 hover:bg-green-800 cursor-pointer transition duration-75 ease-in-out rounded">
                      <i className="fa-solid fa-clock-rotate-left mr-3" />
                      Bet History
                    </li>
                  </Link>
                  <Link to="/user/deposit-history">
                    <li className="p-2 my-1 hover:bg-green-800 cursor-pointer transition duration-75 ease-in-out rounded">
                      <i className="fa-solid fa-hand-holding-dollar mr-3" />
                      Deposit History
                    </li>
                  </Link>
                  <Link to="/user/withdraw-history">
                    <li className="p-2 my-1 hover:bg-green-800 cursor-pointer transition duration-75 ease-in-out rounded">
                      <i className="fa-solid fa-money-bill-trend-up mr-3" />
                      Withdraw History
                    </li>
                  </Link>
                  <Link to="/user/transaction-history">
                    <li className="p-2 my-1 hover:bg-green-800 cursor-pointer transition duration-75 ease-in-out rounded">
                      <i className="fa-solid fa-right-left mr-3" />
                      Transaction History
                    </li>
                  </Link>
                  <Link to="/user/balance-transfer-history">
                    <li className="p-2 my-1 hover:bg-green-800 cursor-pointer transition duration-75 ease-in-out rounded">
                      <i className="fa-solid fa-money-bill-transfer mr-3" />
                      Balance Transfer History
                    </li>
                  </Link>
                </ul>
              )}

              <li
                onClick={logOut}
                className="p-2 my-1 hover:bg-green-800 cursor-pointer transition duration-75 ease-in-out rounded"
              >
                <i className="fa-solid fa-right-from-bracket mr-3" />
                Sign Out
              </li>
            </ul>
          </div>
        </div>
        <Withdraw
          openModal={openWithdrawModal}
          closeModal={handleCloseWithdrawModal}
        ></Withdraw>
        <Deposit
          openModal={openDepositModal}
          closeModal={handleCloseDepositModal}
        ></Deposit>
        <BalanceTransfer
          openModal={openBalanceTransferModal}
          closeModal={handleCloseBalanceTransferModal}
        ></BalanceTransfer>
      </div>
    </div>
  );
};

export default DashboardPopUp;
