import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { useLoginMutation } from "../../../features/api/loginApi";
import { useLocation, useNavigate } from "react-router-dom";

const Login = ({ closeModal, openModal }) => {
  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  const modalClose = (e) => {
    if (e.target.id === "modalBackground") closeModal();
  };

  const [userId, setUserId] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [loginUser] = useLoginMutation();
  const location = useLocation();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      userId,
      password,
    };
    try {
      const response = await loginUser(data).unwrap();
      const accessToken = response?.token;

      //   const from = location.state?.path || "/user/dashboard";
      const from = location.state?.path;
      if (response?.role === "customer") {
        localStorage.setItem("accessToken", accessToken);
        closeModal();
        toast.success("Login successful");
        navigate("/");
      } else {
        toast.warning("Unauthorized Access");
      }
    } catch (error) {
      console.log("error from login", error?.data?.message);
      toast.error(error?.data?.message);
    }
  };

  if (!openModal) return null;

  return (
    <div
      id="modalBackground"
      onClick={modalClose}
      className="fixed inset-0 bg-black bg-opacity-40 snap-alw flex justify-center items-center"
    >
      <div
        className="w-11/12 md:3/5 lg:w-2/5 p-5 rounded"
        style={{ background: "linear-gradient(360deg, #37103a, #08545B)" }}
      >
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">
            {" "}
            <span className="pr-3 text-green-400">|</span>Login Here{" "}
          </h1>
          <p className="text-lg text-gray-500" onClick={closeModal}>
            <FaTimes />
          </p>
        </div>
        <div className=" mt-4">
          <form onSubmit={handleSubmit} action="">
            <div className="w-full my-2">
              <label htmlFor="userId" className="text-sm font-semibold">
                User ID <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                onChange={(e) => setUserId(e.target.value)}
                placeholder="Enter User Id"
                className="p-2 rounded w-full text-sm text-white bg-gray-950"
              />
            </div>

            <div className="relative w-full my-2">
              <label htmlFor="Password" className="text-sm font-semibold">
                Password <span className="text-red-400">*</span>
              </label>
              <div className="relative" id="Password">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="p-2 rounded w-full text-sm text-white bg-gray-950 "
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div
                  className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 text-white text-xl cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>

            {/* <div className='mt-4'>
                            <input type="checkbox" id="rememberMe" name="rememberMe" />
                            <label for="rememberMe" className='px-2  text-sm font-semibold'>Remember me</label>
                        </div> */}
            <button
              type="submit"
              className="bg-green-800 w-full text-sm py-2 my-3 rounded"
            >
              Sign In
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
