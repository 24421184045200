import { apiSlice } from "./apiSlice";

const withdrawnumberApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWithdrawNumber: builder.query({
      query: () => ({
        url: "/get-withdraw-sending-numbers-list",
      }),
      providesTags: ["get-withdraw-sending-numbers-list"],
    }),
    addWithdrawNumber: builder.mutation({
      query: (data) => ({
        url: "/add-withdraw-sending-numbers",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["get-withdraw-sending-numbers-list"],
    }),
    removeWithdrawNumber: builder.mutation({
      query: (id) => ({
        url: `/delete-withdraw-sending-number/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["get-withdraw-sending-numbers-list"],
    }),
  }),
});

export const {
  useGetWithdrawNumberQuery,
  useAddWithdrawNumberMutation,
  useRemoveWithdrawNumberMutation,
} = withdrawnumberApi;
