import React, { useState } from "react";

import logo from "../../Assets/logo.png";
import Login from "../../Pages/Login/Login/Login";
import DateTime from "../DateTime/DateTime";
import JoinUs from "../Modal/JoinUs/JoinUs";

import { Link, useNavigate } from "react-router-dom";
import DashboardPopUp from "../DashboardPopUp/DashboardPopUp";
import { useGetUserQuery } from "../../features/api/loginApi";

const Header = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { data: user } = useGetUserQuery(accessToken, {
    skip: !accessToken,
    refetchOnMountOrArgChange: true,
  });

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openJoinUsModal, setOpenJoinUsModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);

  const handleLoginModal = () => setOpenLoginModal(true);
  const handleCloseLoginModal = () => setOpenLoginModal(false);

  const handleJoinUsModal = () => setOpenJoinUsModal(true);
  const handleCloseJoinUsModal = () => setOpenJoinUsModal(false);

  const handleUserModal = () => setOpenUserModal(true);
  const handleCloseUserModal = () => setOpenUserModal(false);

  const bgGradientColor = {
    // background: 'linear-gradient(270deg, #08545B, #37103a)',
    background: "#10323a",
  };

  const navigate = useNavigate();

  return (
    <div className="relative mb-16 z-30">
      <div
        className="border border-emerald-950 fixed top-0 left-0 right-0"
        style={bgGradientColor}
      >
        <div className="mx-auto px-5 ">
          <div className="flex justify-between items-center ">
            <div className="flex gap-3 items-center">
              <Link to="/">
                {" "}
                <img alt="Ball65 logo" src={logo} className=" h-[38px] my-2" />
              </Link>
              {/* <h1 className='text-xl font-bold'>BALL65</h1> */}
            </div>

            <div className="flex gap-1 font-semibold">
              <DateTime />
              {!user ? (
                <div>
                  <button
                    className={`hover:bg-green-800 py-2 w-12 rounded ${
                      openJoinUsModal ? "bg-green-800" : ""
                    }`}
                    onClick={handleJoinUsModal}
                  >
                    Join
                  </button>
                  <button
                    className={`hover:bg-green-800 py-2 w-12 rounded ${
                      openLoginModal ? "bg-green-800" : ""
                    }`}
                    onClick={handleLoginModal}
                  >
                    {" "}
                    Login
                  </button>
                </div>
              ) : user && user?.user?.role === "superadmin" ? (
                navigate("/admin")
              ) : user && user?.user?.role === "club" ? (
                navigate("/club")
              ) : (
                <div className="p-2 flex items-center gap-x-2 py-2 text-red-400 text-center rounded">
                  <p>
                    <i className="fa-solid fa-bangladeshi-taka-sign " />{" "}
                    {user?.user?.balance}
                  </p>
                  <button
                    className={`hover:bg-green-800 hover:text-white px-3  border rounded ${
                      openUserModal
                        ? "bg-green-800 text-white border-white"
                        : "text-green-600 border-gray-800"
                    }`}
                    onClick={handleUserModal}
                  >
                    {" "}
                    <i className="fa-regular fa-user"></i>
                  </button>
                </div>
              )}
            </div>
            <JoinUs
              openModal={openJoinUsModal}
              closeModal={handleCloseJoinUsModal}
              loginModal={handleLoginModal}
            ></JoinUs>

            <Login
              openModal={openLoginModal}
              closeModal={handleCloseLoginModal}
            ></Login>

            <DashboardPopUp
              data={user?.user}
              openModal={openUserModal}
              closeModal={handleCloseUserModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
