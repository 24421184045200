import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import axios from "axios";

const RefundModal = ({ datas }) => {
  const [visible, setVisible] = useState(false);
  const [inputAmt, setInputData] = useState(null);
  const [title, setTitle] = useState("send");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const sendData = {
        refungAmount: inputAmt,
        refundCheck: title,
        user_id: datas?.map((d) => d?.userId),
        bet_id: datas?.map((d) => d?.id),
      };
      try {
        const response = await axios.put(
          `https://rakibvai.munihaelectronics.com/backend/public/api/bets/process-refund`,
          sendData,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        console.log(response);
        if (!response?.error) {
          setVisible(false);
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="card flex justify-content-center ">
      <button
        onClick={() => setVisible(true)}
        className="border px-2 font-medium"
        disabled={datas?.length === 0}
      >
        Refund
      </button>
      <Dialog
        header="Refund"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{
          width: "20vw",
          backgroundColor: "white",
          color: "black",
          padding: "10px",
        }}
        breakpoints={{ "960px": "50vw", "641px": "80vw" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            paddingTop: "10px",
            marginTop: "10px",
            borderTop: "1px solid gray",
          }}
        >
          <h1 style={{ fontSize: "15px" }}>Input 100%</h1>
          <input
            type="text"
            onChange={(e) => setInputData(Number(e.target.value))}
            style={{
              border: "1px solid gray",
              width: "100%",
              height: "30px",
              padding: "5px",
            }}
          />
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div
              onClick={() => setTitle("send")}
              style={{
                display: "flex",
                gap: "6px",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  border: "1px solid gray",
                  backgroundColor: `${title == "send" ? "#000000" : "#ffffff"}`,
                }}
              ></div>
              <p style={{ fontSize: "16px", fontWeight: "bolder" }}>send</p>
            </div>
            <div
              onClick={() => setTitle("return")}
              style={{
                display: "flex",
                gap: "6px",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  border: "1px solid gray",
                  backgroundColor: `${
                    title == "return" ? "#000000" : "#ffffff"
                  }`,
                }}
              ></div>
              <p style={{ fontSize: "16px", fontWeight: "bolder" }}>Return</p>
            </div>
          </div>
          <button
            onClick={handleSubmit}
            style={{
              backgroundColor: "green",
              color: "white",
              padding: "6px 0",
              borderRadius: "3px",
            }}
          >
            Submit
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default RefundModal;
