import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import "./AddInningsModal.css";
import { useGetUserQuery } from "../../../../features/api/loginApi";
import { useAddInningsOverUnderMutation } from "../../../../features/api/bettingmatchApi";
import { toast } from "react-toastify";

const AddInningsModal = ({ data }) => {
  const match_id = data?.id;

  const [visible, setVisible] = useState(false);
  const [enterOver, setEnterover] = useState("");
  const [run, setRun] = useState("");
  const [innings, setInnings] = useState("1");

  const accessToken = localStorage.getItem("accessToken");
  const { data: user } = useGetUserQuery(accessToken, {
    skip: !accessToken,
    refetchOnMountOrArgChange: true,
  });

  const user_id = user?.user?.id;

  const [addInningsOverUnder] = useAddInningsOverUnderMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const sendData = {
        addOver: enterOver,
        addRun: run,
        addinnings: innings,
        match_id_for_o_u: match_id,
        operator_id: user_id,
      };
      const response = await addInningsOverUnder(sendData);
      if (response?.data) {
        toast.success(response?.data?.message);
        setVisible();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(data);
  return (
    <div className="card flex justify-content-center ">
      <button
        onClick={() => {
          setVisible(true);
        }}
        className="bg-white shadow-lg border rounded-sm py-3 text-emerald-600 font-bold w-full"
      >
        Add Innings total
      </button>
      <Dialog
        header="Add Innings Total"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{
          width: "20vw",
          color: "black",
          padding: "10px",
          fontSize: "1.2rem",
          backgroundColor: "#fff",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
          top: "-15%",
          borderRadius: "5px",
        }}
        breakpoints={{ "960px": "50vw", "641px": "80vw" }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            fontSize: "1.2rem",
            margin: "10px 0",
          }}
          className="dialog-header"
        >
          <p>Match: </p> <span>{data?.A_team}</span> Vs{" "}
          <span>{data?.B_team}</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            paddingTop: "10px",
            marginTop: "10px",
            borderTop: "1px solid gray",
          }}
        >
          <input
            type="text"
            onChange={(e) => setEnterover(e.target.value)}
            style={{
              border: "1px solid gray",
              width: "100%",
              height: "40px",
              padding: "5px",
            }}
            placeholder="Enter Over or Enter Player Name"
          />
          <input
            type="text"
            onChange={(e) => setRun(e.target.value)}
            style={{
              border: "1px solid gray",
              width: "100%",
              height: "40px",
              padding: "5px",
            }}
            placeholder="Enter Run"
          />

          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div>
              <input
                type="radio"
                onChange={(e) => setInnings(e.target.value)}
                id="live"
                name="status"
                value="1"
                defaultChecked
              />
                <label for="live">1st Innings</label>
            </div>

            <div className="flex items-center">
              <input
                type="radio"
                onChange={(e) => setInnings(e.target.value)}
                id="upcoming"
                name="status"
                value="2"
              />
                <label for="upcoming">2nd Innings</label>
            </div>
          </div>

          <button
            onClick={handleSubmit}
            style={{
              backgroundColor: "green",
              color: "white",
              padding: "6px 0",
              borderRadius: "3px",
            }}
          >
            Submit
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default AddInningsModal;
