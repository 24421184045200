import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useGetUserQuery } from "../../features/api/loginApi";

const UserAuth = ({ children, allowedRoles }) => {
  const location = useLocation();

  const isAuthenticated = localStorage.getItem("accessToken");
  const { data, error, isLoading } = useGetUserQuery(isAuthenticated, {
    skip: !isAuthenticated,
    refetchOnMountOrArgChange: true,
  });
  const userRole = data?.user?.role;

  if (isLoading) {
    return (
      <div className="flex flex-col items-center mt-[20%]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ path: location.pathname }} replace />;
  }

  if (!allowedRoles.includes(userRole)) {
    return <Navigate to="/admin" replace />;
  }

  return children;
};

export default UserAuth;
