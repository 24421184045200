import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { useGetUsersQuery } from "../../../features/api/usersApi";
import {
  useEditClubMutation,
  useEditClubQuery,
  useEditCustomerMutation,
  useSignupMutation,
  useUpdateClubQuery,
} from "../../../features/api/loginApi";

const CustomerinfoModal = ({ data, closeModal, openModal }) => {
  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  // const ModalClose = (e) => {
  //     if (e.target.id === 'ModalBackground')
  //         closeModal()
  // }

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [sponsorUsername, setSponsorUsername] = useState("");
  const [clubId, setClubId] = useState("");
  const [sponsorCommission, setSponsorCommission] = useState("");

  const { data: users } = useGetUsersQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const filterClub = users?.users?.filter((user) => user?.role === "club");

  let matched = false;
  users?.users?.forEach((u) => {
    if (u?.userId === userName) {
      matched = true;
    }
  });

  const [editCustomerUser] = useEditCustomerMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const editCustomer = {
      id: data?.id,
      name: name ? name : data?.name,
      mobileNumber: mobileNumber ? mobileNumber : data?.mobileNumber,
      sponsorUsername: sponsorUsername
        ? sponsorUsername
        : data?.sponsorUsername,
      clubId: clubId ? clubId : data?.clubId,
      sponsorCommission: sponsorCommission
        ? sponsorCommission
        : data?.sponsorCommission,
      password: password ? password : data?.password,
    };
    console.log(editCustomer);

    if (matched) {
      toast.warning("ভাই, এই নামে একজন আছে মাপ করেন");
    } else {
      try {
        const response = await editCustomerUser(editCustomer).unwrap();
        console.log(response);
        if (response) {
          closeModal();
          toast.success("Create Successfully done...");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  if (!openModal) return null;

  return (
    <div
      id="ModalBackground"
      // onClick={ModalClose}
      className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50"
    >
      <div className="w-11/12 sm:4/5 md:3/5 lg:w-2/5 p-5 rounded bg-white text-black">
        <div className="flex items-center justify-between ">
          <h1 className="text-xl font-bold">
            {" "}
            <span className="pr-3 text-green-400">|</span>User Information
          </h1>
          <p
            className="text-lg text-gray-500 cursor-pointer"
            onClick={closeModal}
          >
            <FaTimes />
          </p>
        </div>
        <div className="">
          <form onSubmit={handleSubmit} className="mt-5">
            <div className="flex items-center justify-between w-full my-4 ">
              <label htmlFor="name" className="text-sm font-bold ">
                Name <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="name"
                defaultValue={data?.name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter full name"
                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
              />
            </div>
            <div className="flex items-center justify-between w-full my-4 ">
              <label htmlFor="username" className="text-sm font-bold ">
                User Name <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="username"
                value={data?.userId}
                disabled
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Enter user name"
                className="p-2 cursor-not-allowed rounded w-2/3 text-sm border-2 focus:outline-teal-600"
              />
            </div>
            {matched === true && (
              <label className="text-red-500">Already exist</label>
            )}
            <div className="flex items-center justify-between w-full my-4 ">
              <label htmlFor="mobileNumber" className="lg:text-sm font-bold ">
                Mobile Number <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="mobileNumber"
                defaultValue={data?.mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
                placeholder="Enter mobile number"
                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
              />
            </div>
            <div className="flex items-center justify-between w-full my-4 ">
              <label htmlFor="CommissionRate" className="lg:text-sm font-bold ">
                Sponsored By <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="CommissionRate"
                defaultValue={data?.sponsorUsername}
                onChange={(e) => setSponsorUsername(e.target.value)}
                placeholder="Commission Rate"
                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
              />
            </div>
            <div className="flex items-center justify-between w-full my-4 ">
              <label
                htmlFor="TotalOver/UnderCommissionRate"
                className="lg:text-sm font-bold "
              >
                Club <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="TotalOver/UnderCommissionRate"
                defaultValue={data?.clubId}
                onChange={(e) => setClubId(e.target.value)}
                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
              />
            </div>
            <div className="flex items-center justify-between w-full my-4 ">
              <label
                htmlFor="EveryOver/UnderCommissionRate"
                className="lg:text-sm font-bold "
              >
                Sponsor Commission <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="EveryOver/UnderCommissionRate"
                defaultValue={data?.sponsorCommission}
                onChange={(e) => setSponsorCommission(e.target.value)}
                placeholder="Every Over/Under Commission Rate"
                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
              />
            </div>
            <div className="relative flex items-center justify-between w-full my-4 ">
              <label htmlFor="Password" className="text-sm font-bold">
                Password <span className="text-red-400">*</span>
              </label>
              <div className="relative  w-2/3 " id="Password">
                <input
                  type={showPassword ? "text" : "password"}
                  autoComplete={showPassword ? "on" : "new-password"}
                  placeholder="Password"
                  className="p-2 rounded w-full text-sm border-2 focus:outline-teal-600 "
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div
                  className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 text-xl cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="bg-green-800 text-white text-sm w-full font-bold p-2 mt-3 rounded"
            >
              Update
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomerinfoModal;
