import React, { useEffect, useState } from "react";
import AdminPopUp from "../ModalAdmin/AdminPopUp/AdminPopUp";
import { useGetUserQuery } from "../../features/api/loginApi";
import UserNotificationsPopUp from "../ModalAdmin/UserNotificationsPopUp/UserNotificationsPopUp";
import axios from "axios";
import WithdrawNotificationPopup from "../ModalAdmin/UserNotificationsPopUp/WithdrawNotificationPopup";
import { Link, useLocation } from "react-router-dom";
import ReloadPusher from "../ReloadPusher";
import { useGetAlldwnotificationsQuery } from "../../features/api/usersApi";


const HeaderAdmin = ({ toggleSidebar }) => {
  const { pathname } = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  const {data, refetch} = useGetAlldwnotificationsQuery(null,{
    refetchOnMountOrArgChange:true
  });
  const depositHistory = data?.dwnotifications;
  
  const filterDepositNotification = depositHistory?.filter(
    (depos) => depos?.deposit != null && depos?.action == 0 || depos?.deposit != null && depos?.action == 1
  );
  const filterWithdrawNotification = depositHistory?.filter(
    (withd) =>
      (withd?.withdraw != null && withd?.action == 0) || withd?.withdraw != null && withd?.action == 1
  );

//Puser Refetch data start
  const [getData, setGetData] = useState({});
  useEffect(() => {
    if (Object.keys(getData).length > 0) {
    refetch();      
    }
  }, [getData, refetch]);
  //Puser Refetch data End

  const [openAdminPopUp, setOpenAdminPopUp] = useState(false);
  const handleAdminPopUp = () => setOpenAdminPopUp(true);
  const handleCloseAdminPopUp = () => setOpenAdminPopUp(false);

  const [openUserNotificationsPopUp, setOpenUserNotificationsPopUp] =
    useState(false);
  const handleUserNotificationsPopUp = () =>
    setOpenUserNotificationsPopUp(true);
  const handleCloseUserNotificationsPopUp = () =>
    setOpenUserNotificationsPopUp(false);

  const [openWithdrawNotificationsPopUp, setOpenWithdrawNotificationsPopUp] =
    useState(false);
  const handleWithdrawNotificationsPopUp = () =>
    setOpenWithdrawNotificationsPopUp(true);
  const handleCloseWithdrawNotificationsPopUp = () =>
    setOpenWithdrawNotificationsPopUp(false);

  const {
    data: user,
    error,
    isLoading,
  } = useGetUserQuery(accessToken, {
    skip: !accessToken,
    refetchOnMountOrArgChange: true,
  });

  return (
    <div className="relative ">
      <header className="flex fixed w-full z-40">
        <div
          className="w-1/6 hidden lg:block "
          style={{
            background: "linear-gradient(0deg, #08545B, #37103a)",
            fontFamily: "Charm",
          }}
        >
          <h1 className="text-xl text-center py-2">
            {" "}
            AdminPanel ({user?.user?.role})
          </h1>
        </div>
        <div className="w-full lg:w-5/6 bg-emerald-700 ">
          <div className="flex items-center justify-between text-lg">
            <i
              className={`fa-solid fa-bars p-3 hover:bg-emerald-800 }`}
              onClick={toggleSidebar}
            ></i>

            <div className="lg:hidden " style={{ fontFamily: "Charm" }}>
              <Link
                to={
                  pathname == "/admin/betting-panel"
                    ? "/admin"
                    : "/admin/betting-panel"
                }
                className="text-md text-center py-2"
              >
                {" "}
                AdminPanel
              </Link>
            </div>
            <div className="flex gap-1 pr-5">
              <i className="fa-solid fa-envelope-open-text p-3 hover:bg-emerald-800"></i>
              <i className="fa-regular fa-envelope p-3 hover:bg-emerald-800"></i>

              <div
                className={`hover:bg-emerald-800 `}
                // onClick={handleUserNotificationsPopUp}
              >
                <i className="fa-solid fa-bell p-3 "></i>
              </div>
              <div
                className={`relative cursor-pointer hover:bg-emerald-800 `}
                onClick={handleWithdrawNotificationsPopUp}
              >
                <i className="fa-solid fa-bell p-3  "></i>
                <span className="absolute bottom-2 right-0 w-4 h-4 text-xs bg-red-500 rounded-full flex items-center justify-center">
                  {filterWithdrawNotification?.length}
                </span>
              </div>
              <div
                className={`relative cursor-pointer hover:bg-emerald-800 ${
                  openUserNotificationsPopUp ? "bg-emerald-800" : ""
                }`}
                onClick={handleUserNotificationsPopUp}
              >
                <i className="fa-solid fa-bell p-3  "></i>
                <span className="absolute bottom-2 right-0 w-4 h-4 text-xs bg-red-500 rounded-full flex items-center justify-center">
                  {filterDepositNotification?.length}
                </span>
              </div>

              <div
                className={`hover:bg-emerald-800 ${
                  openAdminPopUp ? "bg-emerald-800" : ""
                }`}
                onClick={handleAdminPopUp}
              >
                <i className="fa-solid fa-user-shield p-3  "></i>
              </div>
            </div>
          </div>
        </div>
      </header>
      <AdminPopUp
        openPopUp={openAdminPopUp}
        closePopUp={handleCloseAdminPopUp}
      />
      <WithdrawNotificationPopup
        depoData={filterWithdrawNotification}
        openPopUp={openWithdrawNotificationsPopUp}
        closePopUp={handleCloseWithdrawNotificationsPopUp}
      />

      <UserNotificationsPopUp
        depoData={filterDepositNotification}
        openPopUp={openUserNotificationsPopUp}
        closePopUp={handleCloseUserNotificationsPopUp}
      />
      <ReloadPusher setGetData={setGetData} />
    </div>
  );
};

export default HeaderAdmin;
