import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { useEditDefaultAnswerMutation } from "../../../../features/api/setdefaultmatchApi";
import { toast } from "react-toastify";

const DefaultAnswerRateModal = ({ passData }) => {
  const [visible, setVisible] = useState(false);
  const [rate, setRate] = useState(null);
  const rateInputRef = useRef(null); // Create a ref for the rate input field

  const [editDefaultAnswer] = useEditDefaultAnswerMutation();

  useEffect(() => {
    if (visible) {
      // Focus on the rate input field when the modal is visible
      rateInputRef.current?.focus();
    }
  }, [visible]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const sendData = {
        id: passData?.id,
        rate: rate,
      };
      const response = await editDefaultAnswer(sendData);
      if (response?.data) {
        toast.success(response?.data?.message);
        setVisible(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="card flex justify-content-center">
      <button
        onClick={() => setVisible(true)}
        className="w-[40px] text-lg mx-auto rounded-sm hover:bg-emerald-900 bg-emerald-700 text-white"
      >
        {passData?.rate}
      </button>
      <Dialog
        header="Edit Answer Rate"
        visible={visible}
        onHide={() => setVisible(false)}
        style={{
          width: "20vw",
          backgroundColor: "white",
          color: "black",
          padding: "10px",
        }}
        breakpoints={{ "960px": "50vw", "641px": "80vw" }}
      >
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              paddingTop: "10px",
              marginTop: "10px",
              borderTop: "1px solid gray",
            }}
          >
            <input
              type="text"
              disabled
              value={passData?.title}
              style={{
                border: "1px solid gray",
                width: "100%",
                height: "30px",
                padding: "5px",
              }}
            />
            <input
              type="number"
              onChange={(e) => setRate(e.target.value)}
              ref={rateInputRef}
              style={{
                border: "1px solid gray",
                width: "100%",
                height: "30px",
                padding: "5px",
              }}
              min="0" // Minimum value (optional)
              step="0.01" // Allow decimal steps
            />
            <button
              style={{
                backgroundColor: "green",
                color: "white",
                padding: "6px 0",
                borderRadius: "3px",
              }}
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default DefaultAnswerRateModal;
