import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";

const AdminDashboard = () => {
  const [isAllBettingVisible, setIsAllBettingVisible] = useState(false);
  const [isAllInboxVisible, setIsAllInboxVisible] = useState(false);
  const [isMobileNumberHistoryVisible, setIsMobileNumberHistoryVisible] =
    useState(false);
  const [isUserInfoVisible, setIsUserInfoVisible] = useState(false);

  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarVisible(false);
      } else {
        setIsSidebarVisible(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const toggleAllBetting = () => {
    setIsAllBettingVisible(!isAllBettingVisible);
    setIsAllInboxVisible(false);
    setIsMobileNumberHistoryVisible(false);
    setIsUserInfoVisible(false);
  };

  const toggleAllInbox = () => {
    setIsAllInboxVisible(!isAllInboxVisible);
    setIsAllBettingVisible(false);
    setIsMobileNumberHistoryVisible(false);
    setIsUserInfoVisible(false);
  };

  const toggleMobileNumberHistory = () => {
    setIsMobileNumberHistoryVisible(!isMobileNumberHistoryVisible);
    setIsAllInboxVisible(false);
    setIsAllBettingVisible(false);
    setIsUserInfoVisible(false);
  };

  const toggleUserInfo = () => {
    setIsUserInfoVisible(!isUserInfoVisible);
    setIsMobileNumberHistoryVisible(false);
    setIsAllInboxVisible(false);
    setIsAllBettingVisible(false);
  };

  return (
    <div className="">
      <HeaderAdmin toggleSidebar={toggleSidebar}></HeaderAdmin>

      <div className="relative flex">
        <div className={`${isSidebarVisible ? "lg:w-1/6 md:w-1/6" : ""}`}>
          <div
            className={`w-4/6 fixed bg-emerald-700 h-screen z-30 ${
              isSidebarVisible ? "lg:w-1/6 md:w-1/6" : "hidden"
            }`}
          >
            <div className="text-base font-semibold mx-3 mt-20 mb-5 ">
              <h1>Ball65</h1>
              <p>Bet Platform</p>
            </div>
            <div className="">
              <ul className="text-sm font-semibold">
                <Link to="/admin">
                  <li
                    onClick={() => setIsSidebarVisible(false)}
                    className="p-3 border-l-4 border-blue-400 bg-gray-950 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out"
                  >
                    <i className="fa-solid fa-gauge-high mr-3" />
                    Dashboard
                  </li>
                </Link>
                <Link to="/admin/notice-board">
                  <li
                    onClick={() => setIsSidebarVisible(false)}
                    className="p-3 border-l-4 hover:border-blue-400 border-emerald-700 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out"
                  >
                    <i className="fa-solid fa-clapperboard mr-3" />
                    Notice Board
                  </li>
                </Link>

                <div
                  className={`p-3 border-l-4 hover:border-blue-400 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out flex justify-between ${
                    isAllBettingVisible
                      ? "border-blue-400 bg-gray-800"
                      : "border-emerald-700"
                  }`}
                  onClick={toggleAllBetting}
                >
                  <div>
                    <i className="fa-solid fa-list-ul mr-3" />
                    All Betting
                  </div>
                  <div>
                    {isAllBettingVisible ? (
                      <i className="fa-solid fa-angle-up " />
                    ) : (
                      <i className="fa-solid fa-angle-down " />
                    )}
                  </div>
                </div>
                {isAllBettingVisible && (
                  <ul className="bg-gray-600 text-sm transition duration-300 ease-in-out">
                    <Link to="/admin/betting-panel">
                      <li
                        onClick={() => setIsSidebarVisible(false)}
                        className="px-3 py-1 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out"
                      >
                        <i className="fa-regular fa-circle mr-3" />
                        Betting Panel
                      </li>
                    </Link>
                    <Link to="/admin/betting-transaction">
                      <li
                        onClick={() => setIsSidebarVisible(false)}
                        className="px-3 py-1 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out"
                      >
                        <i className="fa-regular fa-circle mr-3" />
                        Betting Transaction
                      </li>
                    </Link>
                    <Link to="/admin/closed-match-history">
                      <li
                        onClick={() => setIsSidebarVisible(false)}
                        className="px-3 py-1 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out"
                      >
                        <i className="fa-regular fa-circle mr-3" />
                        Closed Match History
                      </li>
                    </Link>
                  </ul>
                )}

                <div
                  className={`p-3 border-l-4 hover:border-blue-400 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out flex justify-between ${
                    isAllInboxVisible
                      ? "border-blue-400 bg-gray-800"
                      : "border-emerald-700"
                  }`}
                  onClick={toggleAllInbox}
                >
                  <div>
                    <i className="fa-solid fa-envelopes-bulk mr-2" />
                    All Inbox
                  </div>
                  <div>
                    {isAllInboxVisible ? (
                      <i className="fa-solid fa-angle-up " />
                    ) : (
                      <i className="fa-solid fa-angle-down " />
                    )}
                  </div>
                </div>
                {isAllInboxVisible && (
                  <ul className="bg-gray-600 text-sm transition duration-300 ease-in-out">
                    <Link to="/admin/user-withdraw-inbox">
                      <li
                        onClick={() => setIsSidebarVisible(false)}
                        className="px-3 py-1 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out"
                      >
                        <i className="fa-regular fa-circle mr-3" />
                        User Withdraw
                      </li>
                    </Link>
                    <Link to="/admin/user-deposit-inbox">
                      <li
                        onClick={() => setIsSidebarVisible(false)}
                        className="px-3 py-1 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out"
                      >
                        <i className="fa-regular fa-circle mr-3" />
                        User Deposit
                      </li>
                    </Link>
                    <Link to="/admin/club-withdraw-inbox">
                      <li
                        onClick={() => setIsSidebarVisible(false)}
                        className="px-3 py-1 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out"
                      >
                        <i className="fa-regular fa-circle mr-3" />
                        Club Withdraw
                      </li>
                    </Link>
                  </ul>
                )}

                <div
                  className={`p-3 border-l-4 hover:border-blue-400 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out flex justify-between ${
                    isMobileNumberHistoryVisible
                      ? "border-blue-400 bg-gray-800"
                      : "border-emerald-700"
                  }`}
                  onClick={toggleMobileNumberHistory}
                >
                  <div>
                    <i className="fa-solid fa-list-ul mr-3" />
                    Mobile Number History
                  </div>
                  <div>
                    {isMobileNumberHistoryVisible ? (
                      <i className="fa-solid fa-angle-up " />
                    ) : (
                      <i className="fa-solid fa-angle-down " />
                    )}
                  </div>
                </div>
                {isMobileNumberHistoryVisible && (
                  <ul className="bg-gray-600 text-sm transition duration-300 ease-in-out">
                    <Link to="/admin/withdraw-numbers">
                      <li
                        onClick={() => setIsSidebarVisible(false)}
                        className="px-3 py-1 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out"
                      >
                        <i className="fa-regular fa-circle mr-3" />
                        Withdraw
                      </li>
                    </Link>
                    <Link to="/admin/deposit-numbers">
                      <li
                        onClick={() => setIsSidebarVisible(false)}
                        className="px-3 py-1 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out"
                      >
                        <i className="fa-regular fa-circle mr-3" />
                        Deposit
                      </li>
                    </Link>
                  </ul>
                )}
                <div
                  className={`p-3 border-l-4 hover:border-blue-400 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out flex justify-between ${
                    isUserInfoVisible
                      ? "border-blue-400 bg-gray-800"
                      : "border-emerald-700"
                  }`}
                  onClick={toggleUserInfo}
                >
                  <div>
                    <i className="fa-solid fa-users-gear mr-2" />
                    User Information
                  </div>
                  <div>
                    {isUserInfoVisible ? (
                      <i className="fa-solid fa-angle-up " />
                    ) : (
                      <i className="fa-solid fa-angle-down " />
                    )}
                  </div>
                </div>
                {isUserInfoVisible && (
                  <ul className="bg-gray-600 text-sm transition duration-300 ease-in-out">
                    <Link to="/admin/all-user">
                      <li
                        onClick={() => setIsSidebarVisible(false)}
                        className="px-3 py-1 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out"
                      >
                        <i className="fa-regular fa-circle mr-3" />
                        All User
                      </li>
                    </Link>
                    <Link to="/admin/all-club">
                      <li
                        onClick={() => setIsSidebarVisible(false)}
                        className="px-3 py-1 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out"
                      >
                        <i className="fa-regular fa-circle mr-3" />
                        All Club
                      </li>
                    </Link>
                    <Link to="/admin/all-user-withdraw">
                      <li
                        onClick={() => setIsSidebarVisible(false)}
                        className="px-3 py-1 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out"
                      >
                        <i className="fa-regular fa-circle mr-3" />
                        All User Withdraw Numbers
                      </li>
                    </Link>
                  </ul>
                )}

                <Link to="/admin/user-balance-transfer">
                  <li
                    onClick={() => setIsSidebarVisible(false)}
                    className="p-3 border-l-4 hover:border-blue-400 border-emerald-700 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out"
                  >
                    <i className="fa-solid fa-square-h mr-2" /> Balance Transfer
                    History
                  </li>
                </Link>
                <Link to="/admin/third-admin-balance-transfer">
                  <li
                    onClick={() => setIsSidebarVisible(false)}
                    className="p-3 border-l-4 hover:border-blue-400 border-emerald-700 hover:bg-gray-950 cursor-pointer transition duration-75 ease-in-out"
                  >
                    <i className="fa-brands fa-hire-a-helper mr-2" /> 3rd Admin
                    BT History
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>

        <div
          className={`w-full min-h-screen bg-gray-300 ${
            isSidebarVisible ? "lg:w-5/6" : "w-full"
          }`}
        >
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
