import React, { useState } from "react";
import {
  FaComments,
  FaHome,
  FaMoneyBillAlt,
  FaTicketAlt,
  FaWallet,
} from "react-icons/fa";
import Withdraw from "../Modal/Withdraw/Withdraw";
import Deposit from "../Modal/Deposit/Deposit";
import MyBet from "../MyBet/MyBet";
import { Link } from "react-router-dom";

const BottomAppBar = () => {
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
  const [openDepositModal, setOpenDepositModal] = useState(false);

  const handleWithdrawModal = () => setOpenWithdrawModal(true);
  const handleCloseWithdrawModal = () => setOpenWithdrawModal(false);

  const handleDepositModal = () => setOpenDepositModal(true);

  const handleCloseDepositModal = () => setOpenDepositModal(false);
  return (
    <div>
      <div className="relative lg:hidden">
        <div
          className="fixed bottom-0 left-0 right-0 "
          style={{ backgroundColor: "#10323a" }}
        >
          <div className="px-5 py-3 mx-auto max-w-5xl">
            <div className="flex justify-between">
              <Link to="/">
                <div className="grid justify-items-center">
                  <FaHome className="text-lg" />
                  <h2 className="font-bold">Home</h2>
                </div>
              </Link>

              <div className="grid justify-items-center">
                <FaTicketAlt className="text-lg" />
                <h2 className="font-bold">
                  My Bet <span>0</span>
                </h2>
              </div>
              <div
                className={`grid justify-items-center ${
                  openWithdrawModal ? "text-green-400" : ""
                }`}
                onClick={handleWithdrawModal}
              >
                <FaWallet className="text-lg" />
                <h2 className="font-bold">Withdraw</h2>
              </div>
              <div
                className={`grid justify-items-center ${
                  openDepositModal ? "text-green-400" : ""
                }`}
                onClick={handleDepositModal}
              >
                <FaMoneyBillAlt className="text-lg" />
                <h2 className="font-bold">Deposit</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Withdraw
        openModal={openWithdrawModal}
        closeModal={handleCloseWithdrawModal}
      ></Withdraw>
      <Deposit
        openModal={openDepositModal}
        closeModal={handleCloseDepositModal}
      ></Deposit>
    </div>
  );
};

export default BottomAppBar;
