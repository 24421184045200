import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FaAngleDown, FaList } from "react-icons/fa";
import AddClubForm from "../../../../components/ModalAdmin/AddClubForm/AddClubForm";
import {
  useEditUserMutation,
  useGetUsersQuery,
  useRemoveUserMutation,
} from "../../../../features/api/usersApi";
import { toast } from "react-toastify";
import UpdateModal from "./UpdateModal";
import ClubInfoModal from "../../../../components/ModalAdmin/ClubInfoModal/ClubInfoModal";

const AllClub = () => {
  const [openAddClubForm, setOpenAddClubForm] = useState(false);
  const handleAddClubForm = () => setOpenAddClubForm(true);
  const handleCloseAddClubForm = () => setOpenAddClubForm(false);
  const [openClubInfoModal, setOpenClubInfoModal] = useState(false);
  const [passData, setPassData] = useState(null);
  const handleClubInfoModal = (data) => {
    setOpenClubInfoModal(true);
    setPassData(data);
  };
  const handleCloseClubInfoModal = () => setOpenClubInfoModal(false);

  function dateFormat(dateString) {
    const date = new Date(dateString);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hour = date.getHours();
    let min = date.getMinutes();
    let time = hour + ":" + min;
    let getDate = day + "-" + month + "-" + year;
    return getDate;
  }
  function timeFormat(dateString) {
    const date = new Date(dateString);
    let hour = date.getHours();
    let min = date.getMinutes();
    let time = hour + ":" + min;
    return time;
  }

  const { data: users } = useGetUsersQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const club = users?.users?.filter((user) => user?.role === "club");
  const customer = users?.users?.filter((user) => user?.role === "customer");
  const totalBalance = club?.reduce((sum, item) => sum + +item?.balance, 0);

  const [removeUser] = useRemoveUserMutation();
  const [updateUser] = useEditUserMutation();
  const handleUpdate = async (row) => {
    const confirm = window.confirm("Are you sure to update?");
    if (confirm) {
      try {
        const data = {
          id: row?.id,
          status: row?.status == 0 ? 1 : 0,
        };
        const res = await updateUser(data);
        console.log(res);
        if (res?.error) {
          toast.error("Error..!!!");
        } else {
          toast.success(res?.data?.user);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDelete = async (id) => {
    const confirm = window.confirm("Are you sure?");
    if (confirm) {
      try {
        const res = await removeUser(id);
        console.log(res);
        if (res) {
          toast.success("Successfully Remove..!!!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [searchText, setSearchText] = useState("");
  const columns = [
    {
      name: "Name",
      selector: (row) =>
        row.name ? row.name : <p className="text-[#565454] font-bold">Null</p>,
      sortable: true,
    },
    {
      name: "User Id",
      selector: (row) => row.userId,
      sortable: true,
    },
    {
      name: "Amount of Member ",
      selector: (row) => {
        const filterClubId = customer?.filter(
          (user) => user?.clubId === row?.userId
        );
        return <div>{filterClubId?.length}</div>;
      },
      sortable: true,
    },
    {
      name: "Balance",
      selector: (row) => row.balance,
      sortable: true,
    },
    {
      name: "Personal Id",
      selector: (row) => row.ownershipIdOfClub,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex gap-2">
          <button
            className="bg-green-700 text-white p-1 rounded"
            onClick={() => handleClubInfoModal(row)}
          >
            Details
          </button>
          <button
            onClick={() => handleUpdate(row)}
            className={`${
              row?.status === "1"
                ? "bg-green-600 text-white p-1 rounded"
                : "bg-red-500 text-white p-1 rounded"
            }`}
          >
            {row?.status === "1" ? "Active" : "Deactive"}
          </button>
          <button
            onClick={() => handleDelete(row?.id)}
            className="bg-red-500 text-white font-bold p-1 rounded"
          >
            Delete
          </button>
        </div>
      ),
      sortable: true,
      width:'200px'
    },
  ];

  const customStyles = {
    tableWrapper: {
      style: {
        display: "table",
      },
    },
    responsiveWrapper: {
      style: {},
    },
    headRow: {
      style: {
        color: "white",
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: "teal",
      },
    },
  };
  const customFilter = (rows, searchText) => {
    return rows?.filter((row) => {
      return Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      );
    });
  };
  return (
    <div className="mt-10">
      <div className="bg-white text-black p-5">
        <div className="  flex gap-2 ">
          <FaList className="text-xl mt-2"></FaList>
          <h1 className="text-2xl font-semibold">
            All Club ( {club?.length} )
          </h1>
        </div>
        <h1 className="my-2 text-sm text-green-600 font-semibold">
          Total Club Balance: {totalBalance}
        </h1>
      </div>
      <div className="pt-5 px-5 flex gap-2 text-sm font-semibold mb-2">
        <button
          className="p-2 bg-sky-700 hover:bg-sky-900 rounded "
          onClick={handleAddClubForm}
        >
          <i className="fa-solid fa-plus"></i> Add Club
        </button>
        <button className="p-2 bg-sky-700 hover:bg-sky-900 rounded">
          <i className="fa-solid fa-repeat"></i> Sort By Balance
        </button>
      </div>
      <div className="m-5 mt-0 bg-white rounded ">
        <div className="flex items-center justify-end px-5 py-4 ">
          <label
            htmlFor="search"
            className="text-black text-sm font-semibold p-1"
          >
            Search:
          </label>
          <input
            type="text"
            id="search"
            placeholder=""
            className=" text-black text-sm border-2 px-2 py-1 rounded focus:outline-teal-600"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="px-5">
          <DataTable
            columns={columns}
            data={customFilter(club, searchText)}
            customStyles={customStyles}
            sortIcon={<FaAngleDown className="m-1" />}
            defaultSortAsc={true}
            paginationRowsPerPageOptions={[50, 100, 150]}
            paginationPerPage={100}
            pagination
            highlightOnHover
          />
        </div>
      </div>
      <AddClubForm
        openForm={openAddClubForm}
        closeForm={handleCloseAddClubForm}
      />
      <ClubInfoModal
        data={passData}
        openModal={openClubInfoModal}
        closeModal={handleCloseClubInfoModal}
      />
    </div>
  );
};

export default AllClub;
