import React from "react";
import { useGetMySponsorsQuery } from "../../../features/api/mybetsApi";
import { formatDateTime } from "../../../utils/formatDateTime";

const Sponsors = () => {
  const { data, isLoading } = useGetMySponsorsQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const getSponsors = data?.sponsors;

 
return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-4">My Sponsors</h1>


      {/* Mobile View */}
      <div className="my-5 text-sm lg:hidden">
        {isLoading ? (
          <p>Loading...</p>
        ) : getSponsors?.length === 0 ? (
          <p>No sponsors found.</p>
        ) : (
          getSponsors.map((data, idx) => (
            <div key={data?.id} className="border-b py-4">
              <div className="text-red-400 text-lg font-semibold">
                Sponsor {idx + 1}
              </div>
              <div className="flex flex-col">
                <div className="flex justify-between py-1">
                  <strong>Sl No:</strong>
                  <span>{idx + 1}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>User Id:</strong>
                  <span>{data?.userId}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>Mobile:</strong>
                  <span>{data?.mobileNumber}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>Status:</strong>
                  <span>{data?.status === 1 ? "Active" : "Deactive"}</span>
                </div>
                <div className="flex justify-between py-1">
                  <strong>Time:</strong>
                  <span>{formatDateTime(data?.created_at)}</span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Desktop View */}
      <div className="hidden lg:block overflow-x-auto mt-5 border border-emerald-950 rounded">
        <table className="min-w-full divide-y divide-emerald-950">
          <thead className="bg-gray-800 text-emerald-400 uppercase text-xs font-semibold">
            <tr>
              <th className="px-6 py-3 text-left">SN</th>
              <th className="px-6 py-3 text-left">User Id</th>
              <th className="px-6 py-3 text-left">Mobile</th>
              <th className="px-6 py-3 text-left">Status</th>
              <th className="px-6 py-3 text-left">Time</th>
            </tr>
          </thead>
          <tbody className="bg-gray-900 text-white">
            {isLoading ? (
              <tr>
                <td colSpan="5" className="text-center py-4">
                  Loading...
                </td>
              </tr>
            ) : getSponsors?.length === 0 ? (
              <tr>
                <td colSpan="5" className="text-center py-4">
                  No sponsors found.
                </td>
              </tr>
            ) : (
              getSponsors.map((data, idx) => (
                <tr key={data?.id} className="text-sm">
                  <td className="px-6 py-4">{idx + 1}</td>
                  <td className="px-6 py-4">{data?.userId}</td>
                  <td className="px-6 py-4">{data?.mobileNumber}</td>
                  <td className="px-6 py-4">
                    {data?.status === 1 ? "Active" : "Deactive"}
                  </td>
                  <td className="px-6 py-4">{formatDateTime(data?.created_at)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Sponsors;
