import { apiSlice } from "./apiSlice";

const depositnumberApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDepositNumber: builder.query({
      query: () => ({
        url: "/get-deposit-receiving-numbers-list",
      }),
      providesTags: ["get-deposit-receiving-numbers-list"],
    }),
    getDepositWithdrawNumberBalance: builder.query({
      query: () => ({
        url: "/get-dwbalance",
      }),
      providesTags: ["/get-dwbalance"],
    }),
    addDepositNumber: builder.mutation({
      query: (data) => ({
        url: "/add-deposit-receiving-numbers",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["get-deposit-receiving-numbers-list"],
    }),
    removeDepositNumber: builder.mutation({
      query: (id) => ({
        url: `/delete-deposit-receiving-numbers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["get-deposit-receiving-numbers-list"],
    }),
    editDepositNumber: builder.mutation({
      query: (editData) => ({
        url: `affiliate`,
        method: "PATCH",
        body: editData,
      }),
      invalidatesTags: ["get-deposit-receiving-numbers-list"],
    }),
  }),
});

export const {
  useGetDepositNumberQuery,
  useGetDepositWithdrawNumberBalanceQuery,
  useAddDepositNumberMutation,
  useRemoveDepositNumberMutation,
  useEditDepositNumberMutation,
} = depositnumberApi;
