import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ScoreModal from "./ScoreModal";
import MatchLimtModal from "./MatchLimtModal";
import MatchWaitModal from "./MatchWaitModal";
import BettingDHiddenModal from "./BettingDHiddenModal";
import { GoArrowBoth } from "react-icons/go";
import LiveMatchQuestion from "./LiveMatchQuestion";
import LiveMatchOverUnderDetails from "./LiveMatchOverUnderDetails";
import {
  useEditEveryOverUnderAriaVisibilityMutation,
  useEditInningsAriaVisibilityMutation,
  useEditLiveMatchAriaHideShowMutation,
  useEditLiveMatchHideFromPanelMutation,
  useEditLiveMatchHideShowMutation,
  useEditLiveMatchStopStatusMutation,
  useEditOverUnderAriaVisibilityMutation,
} from "../../../../features/api/bettingmatchApi";
import GameTypeBall from "./GameTypeBall";
import LiveMatchEveryOverUnderDetails from "./LiveMatchEveryOverUnderDetails";
import LiveMatchInningsTotalDetails from "./LiveMatchInningsTotalDetails";
import QuestionShowModal from "./QuestionShowModal";

function LiveMatch({
  item,
  questions,
  overUnderDetails,
  handleActionModal,
  handleAccordionAction1Modal,
  matchBet,
  quesBets,
  overBets,
  everyBets,
  inngsBets,
}) {
  const commonMatchId = item?.id;
  const totalMatchBet = matchBet?.reduce(
    (sum, cur) => sum + Number(cur?.betAmount),
    0
  );

  const overUnder = overUnderDetails?.filter((d) => d?.over_under_type == 1);
  const everyOverUnder = overUnderDetails?.filter(
    (d) => d?.over_under_type == 2
  );
  const inningsTotal = overUnderDetails?.filter((d) => d?.over_under_type == 3);

  const [openQuestionShowModal, setOpenQuestionShowModal] = useState(false);
  const handleCloseQuestionShowModal = () => setOpenQuestionShowModal(false);
  const handleQuestionShowModal = (data) => {
    setOpenQuestionShowModal(true);
  };

  const [editLiveMatchStopStatus] = useEditLiveMatchStopStatusMutation();
  const [editLiveMatchHideShow] = useEditLiveMatchHideShowMutation();
  const [editLiveMatchAriaHideShow] = useEditLiveMatchAriaHideShowMutation();
  const [editLiveMatchHideFromPanel] = useEditLiveMatchHideFromPanelMutation();
  const [editOverUnderAriaVisibility] =
    useEditOverUnderAriaVisibilityMutation();
  const [editEveryOverUnderAriaVisibility] =
    useEditEveryOverUnderAriaVisibilityMutation();
  const [editInningsAriaVisibility] = useEditInningsAriaVisibilityMutation();

  const handleUpdateStopStatus = async (item, val) => {
    try {
      if (val === 1) {
        const sendData1 = {
          id: item?.id,
          showStatus: item?.showStatus === "1" ? "0" : "1",
        };
        await editLiveMatchStopStatus(sendData1);
      } else if (val === 2) {
        const sendData2 = {
          id: item?.id,
          hide: item?.hide === "1" ? "0" : "1",
        };
        await editLiveMatchHideShow(sendData2);
      } else if (val === 3) {
        const sendData3 = {
          id: item?.id,
          ariaHide: item?.ariaHide === "1" ? "0" : "1",
        };
        await editLiveMatchAriaHideShow(sendData3);
      } else if (val === 4) {
        const sendData4 = {
          id: item?.id,
          hideFromPanel: 1,
        };
        await editLiveMatchHideFromPanel(sendData4);
      } else if (val === 5) {
        const sendData5 = {
          id: item?.id,
          over_under_area: item?.over_under_area === "1" ? "0" : "1",
        };
        await editOverUnderAriaVisibility(sendData5);
      } else if (val === 6) {
        const sendData6 = {
          id: item?.id,
          every_over_under_area:
            item?.every_over_under_area === "1" ? "0" : "1",
        };
        await editEveryOverUnderAriaVisibility(sendData6);
      } else if (val === 7) {
        const sendData7 = {
          id: item?.id,
          innings_total_area: item?.innings_total_area === "1" ? "0" : "1",
        };
        await editInningsAriaVisibility(sendData7);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [expanded, setExpanded] = useState(item?.ariaHide === "0");

  useEffect(() => {
    setExpanded(item?.ariaHide === "0");
  }, [item?.ariaHide]);

  const handleAccordionChange = (isExpanded) => {
    if (item?.ariaHide !== "1") {
      setExpanded(isExpanded);
    }
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={(event, isExpanded) => handleAccordionChange(isExpanded)}
      className=" bg-emerald-900 text-white border-b"
    >
      <div className="">
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <div className="flex flex-wrap gap-1 mt-2 sm:mt-0 items-center">
            <GameTypeBall data={item?.gameType} />
            <span className="font-semibold text-sm">{item?.A_team}</span>
            <GoArrowBoth size={22} />
            <span className="font-semibold text-sm">{item?.B_team}</span>
            <span className="text-sm">{item?.title}</span> <span>||</span>
            <span className="text-sm">{item?.date}</span>
          </div>
        </AccordionSummary>
        <div
          className="flex flex-wrap gap-y-3 gap-x-1 items-center pl-1"
          style={{ paddingBottom: "5px" }}
        >
          {/* <span className="flex items-center gap-x-1 flex-wrap text-sm"> */}
          {/* <BiSolidCricketBall size={30} className="text-red-800" /> */}
          {/* </span> */}
          <button
            onClick={() => handleActionModal(item)}
            className="bg-cyan-700 font-semibold text-white p-2 rounded-sm"
          >
            Action
          </button>
          <BettingDHiddenModal passData={item} />
          <MatchLimtModal datas={item} />
          <MatchWaitModal datas={item} />
          <button onClick={() => handleUpdateStopStatus(item, 1)}>
            {item?.showStatus === "0" ? (
              <span className="bg-red-700 font-semibold text-white p-2 rounded-sm uppercase">
                Active
              </span>
            ) : (
              <span className="bg-cyan-700 font-semibold text-white p-2 rounded-sm uppercase">
                Stop
              </span>
            )}
          </button>
          <button onClick={() => handleUpdateStopStatus(item, 2)}>
            {item?.hide === "1" ? (
              <span className="bg-red-700 font-semibold text-white p-2 rounded-sm uppercase">
                Show
              </span>
            ) : (
              <span className="bg-cyan-700 font-semibold text-white p-2 rounded-sm uppercase">
                Hide
              </span>
            )}
          </button>
          <button onClick={() => handleUpdateStopStatus(item, 3)}>
            {item?.ariaHide === "0" ? (
              <span className="bg-cyan-700 font-semibold text-white p-2 rounded-sm">
                Aria Hide
              </span>
            ) : (
              <span className="bg-red-700 font-semibold text-white p-2 rounded-sm">
                Aria Show
              </span>
            )}
          </button>
          {item?.gameType == "2" && (
            <button onClick={() => handleUpdateStopStatus(item, 5)}>
              {item?.over_under_area === "1" ? (
                <span className="bg-cyan-700 font-semibold text-white p-2 rounded-sm">
                  Total Hide
                </span>
              ) : (
                <span className="bg-red-700 font-semibold text-white p-2 rounded-sm">
                  Total Show
                </span>
              )}
            </button>
          )}
          {item?.gameType == "2" && (
            <button onClick={() => handleUpdateStopStatus(item, 6)}>
              {item?.every_over_under_area === "1" ? (
                <span className="bg-cyan-700 font-semibold text-white p-2 rounded-sm">
                  Every Hide
                </span>
              ) : (
                <span className="bg-red-700 font-semibold text-white p-2 rounded-sm">
                  Every Show
                </span>
              )}
            </button>
          )}
          {item?.gameType == "2" && (
            <button onClick={() => handleUpdateStopStatus(item, 7)}>
              {item?.innings_total_area === "1" ? (
                <span className="bg-cyan-700 font-semibold text-white p-2 rounded-sm">
                  Innings Hide
                </span>
              ) : (
                <span className="bg-red-700 font-semibold text-white p-2 rounded-sm">
                  Innings Show
                </span>
              )}
            </button>
          )}
          <button onClick={() => handleUpdateStopStatus(item, 4)}>
            <span className="bg-cyan-700 font-semibold text-white p-2 rounded-sm">
              Hide from panel
            </span>
          </button>
          <ScoreModal datas={item} />
          <button className="bg-cyan-700 font-semibold text-white p-2 rounded-sm">
            On / Off
          </button>
          <button
            onClick={() => handleQuestionShowModal()}
            className="bg-cyan-700 font-semibold text-white p-2 rounded-sm"
          >
            Q Show
          </button>
          <button
            className="font-semibold "
            style={{ marginTop: "-10px" }}
          ></button>
          || operator (OpaterT10){" "}
          <p className="bg-red-500 rounded-sm mr-2 text-white font-bold px-2">
            {matchBet?.length}
          </p>{" "}
          <p className="bg-cyan-600 rounded-sm text-white font-bold px-2">
            {totalMatchBet}
          </p>
        </div>
        {/* <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <span className="font-semibold">|| operator (OpaterT10)</span>
        </AccordionSummary> */}
      </div>

      <QuestionShowModal
        openModal={openQuestionShowModal}
        closeModal={handleCloseQuestionShowModal}
        questions={questions}
        overUnder={overUnder}
        everyOverUnder={everyOverUnder}
        inningsTotal={inningsTotal}
      />

      <AccordionDetails>
        {questions
          ?.filter((d) => d?.question?.qhide == "1")
          ?.map((ques, idx) => {
            const filterQuesBet = quesBets?.filter(
              (qBet) =>
                qBet?.matchId == ques?.question?.bettingId &&
                qBet?.questionId == ques?.question?.id
            );
            return (
              <LiveMatchQuestion
                key={idx}
                ques={ques["question"]}
                answers={ques["options"]}
                overUnderDetails={ques["over_under_details"]}
                handleAccordionAction1Modal={handleAccordionAction1Modal}
                filterQuesBet={filterQuesBet}
              />
            );
          })}
        {item?.over_under_area == "1" &&
          overUnder?.length > 0 &&
          overUnder
            ?.filter((d) => d?.qhide == "1")
            ?.map((item, idx) => {
              return (
                <LiveMatchOverUnderDetails
                  key={idx}
                  item={item}
                  handleActionModal={handleActionModal}
                  overBets={overBets}
                  totalOverMatchId={commonMatchId}
                />
              );
            })}
        {item?.every_over_under_area == "1" &&
          everyOverUnder?.length > 0 &&
          everyOverUnder
            ?.filter((d) => d?.qhide == "1")
            ?.map((item, idx) => {
              return (
                <LiveMatchEveryOverUnderDetails
                  key={idx}
                  item={item}
                  handleActionModal={handleActionModal}
                  everyBets={everyBets}
                  totalOverMatchId={commonMatchId}
                />
              );
            })}
        {item?.innings_total_area == "1" &&
          inningsTotal?.length > 0 &&
          inningsTotal
            ?.filter((d) => d?.qhide == "1")
            ?.map((item, idx) => {
              return (
                <LiveMatchInningsTotalDetails
                  key={idx}
                  item={item}
                  handleActionModal={handleActionModal}
                  inngsBets={inngsBets}
                  totalOverMatchId={commonMatchId}
                />
              );
            })}
      </AccordionDetails>
    </Accordion>
  );
}

export default LiveMatch;
