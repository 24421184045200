import React, { useState } from "react";
import { useGetUserQuery } from "../../../features/api/loginApi";
import { Password } from "@mui/icons-material";
import { toast } from "react-toastify";
import axios from "axios";

const ClubProfile = () => {
  const [isProfileClicked, setIsProfileClicked] = useState(true);
  const [isChangePasswordClicked, setIsChangePasswordClicked] = useState(false);

  const user = useGetUserQuery(null, { refetchOnMountOrArgChange: true })?.data
    ?.user;
  console.log(user);

  const [amount, setAmount] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleChangePassword = async () => {
    const changePass = {
      current_password: currentPassword,
      new_password: newPassword,
    };
    try {
      const response = await axios.post(
        "https://rakibvai.munihaelectronics.com/backend/public/api/change-password",
        changePass,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response?.data) {
        toast.success(response?.data?.message);
        localStorage.removeItem("accessToken");
        setTimeout(() => {
          window.location.reload();
        }, 0.5);
      }
    } catch (error) {
      toast.error(error?.response.data?.message);
    }
  };

  const handleClick = (type) => {
    if (type === "Profile") {
      setIsProfileClicked(true);
      setIsChangePasswordClicked(false);
    } else if (type === "ChangePassword") {
      setIsProfileClicked(false);
      setIsChangePasswordClicked(true);
    }
  };

  return (
    <div className="mt-12 p-5">
      <div>
        <div className="bg-[#0c675e] rounded">
          <div className="p-5">
            <div className="flex">
              <div
                className={`w-full text-center p-2 border-sky-500 ${
                  isProfileClicked ? "border-b-2 text-sky-500 " : ""
                }`}
                onClick={() => handleClick("Profile")}
              >
                <i class="fa-solid fa-user-tie text-xl"></i>
                <h1 className="text-sm font-semibold hidden md:block lg:block ">
                  PROFILE
                </h1>
              </div>
              <div
                className={`w-full text-center p-2 border-sky-500 ${
                  isChangePasswordClicked ? "border-b-2 text-sky-500" : ""
                }`}
                onClick={() => handleClick("ChangePassword")}
              >
                <i class="fa-solid fa-user-lock text-xl"></i>
                <h1 className="text-sm font-semibold hidden md:block lg:block">
                  CHANGE PASSWORD
                </h1>
              </div>
            </div>
            <div className="border border-sky-500"></div>

            <div className=" my-3">
              <div
                className={`h-72 lg:h-64 ${
                  isProfileClicked ? "block" : "hidden"
                }`}
              >
                <div className="px-5">
                  <div className="lg:flex items-center my-2">
                    <div className="w-full lg:w-1/4 my-2 text-sm font-bold ">
                      USER ID
                    </div>
                    <div className="w-full lg:w-3/4 my-2 p-2 rounded text-base text-white bg-[#06332f]">
                      {user?.userId}
                    </div>
                  </div>
                  <div className="lg:flex items-center my-2">
                    <div className="w-full lg:w-1/4 my-2 text-sm font-bold ">
                      MOBILE NO.
                    </div>
                    <div className="w-full lg:w-3/4 my-2 p-2 rounded text-base text-white bg-[#06332f]">
                      {user?.mobileNumber}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`h-72 lg:h-64 ${
                  isChangePasswordClicked ? "block" : "hidden"
                }`}
              >
                <div className="px-5">
                  <div className="lg:flex items-center w-full my-2 ">
                    <label
                      htmlFor="CurrentPassword"
                      className="w-1/4 my-2 text-sm font-bold mb-3"
                    >
                      CURRENT PASSWORD <span className="text-red-400">*</span>
                    </label>
                    <div className="lg:w-3/4  my-2" id="CurrentPassword">
                      <input
                        type="text"
                        // autoComplete={showPassword ? "on" : "new-password"}
                        placeholder="Current Password"
                        className="p-2 rounded w-full text-sm text-white bg-[#3c857e] focus:bg-transparent focus:outline-gray-200 "
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="relative lg:flex items-center w-full my-2 ">
                    <label
                      htmlFor="NewPassword"
                      className="w-1/4 my-2 text-sm font-bold mb-3"
                    >
                      NEW PASSWORD <span className="text-red-400">*</span>
                    </label>
                    <div className="relative lg:w-3/4 my-2 " id="NewPassword">
                      <input
                        type={showPassword ? "text" : "password"}
                        // autoComplete={showPassword ? "on" : "new-password"}
                        // type="text"
                        placeholder="New Password"
                        className="p-2 rounded w-full text-sm text-white bg-[#3c857e] focus:bg-transparent focus:outline-gray-200 "
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                      <div
                        className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 text-base cursor-pointer text-gray-200"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <i className="fa-solid fa-eye-slash" />
                        ) : (
                          <i className="fa-solid fa-eye" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="relative lg:flex items-center w-full my-2 ">
                    <label
                      htmlFor="ConfirmPassword"
                      className="w-1/4 my-2 text-sm font-bold mb-3"
                    >
                      CONFIRM PASSWORD <span className="text-red-400">*</span>
                    </label>
                    <div
                      className="relative lg:w-3/4 my-2 "
                      id="ConfirmPassword"
                    >
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        // autoComplete={confirmPassword ? "on" : "new-password"}
                        placeholder="Confirm Password"
                        className="p-2 rounded w-full text-sm text-white bg-[#3c857e] focus:bg-transparent focus:outline-gray-200 "
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <div
                        className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 text-base cursor-pointer text-gray-200"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        {showConfirmPassword ? (
                          <i className="fa-solid fa-eye-slash" />
                        ) : (
                          <i className="fa-solid fa-eye" />
                        )}
                      </div>
                    </div>
                  </div>
                  {confirmPassword && newPassword !== confirmPassword ? (
                    <p className="text-red-400 text-lg font-bold mt-1 text-center">
                      Password did not matched
                    </p>
                  ) : (
                    ""
                  )}
                  <button
                    onClick={handleChangePassword}
                    disabled={newPassword !== confirmPassword}
                    className={` ${
                      newPassword == confirmPassword
                        ? "bg-[#7934f3] text-white"
                        : "bg-gray-300 text-black"
                    } w-full  font-semibold p-2 rounded mt-3`}
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClubProfile;
