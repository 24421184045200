import React from "react";
import { useGetAllMyMembersQuery } from "../../../features/api/clubdashboardApi";

const ClubMembers = () => {
  const { data, isLoading } = useGetAllMyMembersQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const getData = data?.members;

  return (
    <div className="mt-12 p-5">
      <h1 className="text-lg my-4">Club Members</h1>
      <div className="w-full">
        {/* Desktop Table */}
        <div className="hidden md:block overflow-x-auto">
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="px-4 py-2 text-left">Sl No</th>
                <th className="px-4 py-2 text-left">User Id</th>
                <th className="px-4 py-2 text-left">Mobile Number</th>
                <th className="px-4 py-2 text-left">Status</th>
                <th className="px-4 py-2 text-left">Time</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="5" className="text-center py-4">
                    Loading...
                  </td>
                </tr>
              ) : (
                getData?.map((member, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-4 py-2">{index + 1}</td>
                    <td className="px-4 py-2">{member.userId}</td>
                    <td className="px-4 py-2">
                      {member.mobileNumber || member.phone}
                    </td>
                    <td className="px-4 py-2">
                      {member.status === "1" ? "Active" : "Inactive"}
                    </td>
                    <td className="px-4 py-2">{member.created_at}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {/* Mobile version listing */}
        <div className="block md:hidden mt-8">
          {isLoading ? (
            <div className="text-center">Loading...</div>
          ) : (
            getData?.map((member, index) => (
              <div
                key={index}
                className="border p-4 mb-4 rounded-lg shadow-md"
              >
                <h2 className="font-bold">Member {index + 1}</h2>
                <p><strong>User Id:</strong> {member.userId}</p>
                <p><strong>Mobile Number:</strong> {member.mobileNumber || member.phone}</p>
                <p><strong>Status:</strong> {member.status === "1" ? "Active" : "Inactive"}</p>
                <p><strong>Joined On:</strong> {member.created_at}</p>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ClubMembers;
