import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useAddUserWithdrawNumberMutation } from "../../../features/api/usersApi";
import { useGetPaymentMethodQuery } from "../../../features/api/paymentmethodApi";

const AllUserWithdrawModal = ({ closeForm, openForm }) => {
  useEffect(() => {
    if (openForm) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openForm]);

  const [userId, setUserId] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [method, setSelectMethod] = useState("");

  const PaymentMethods = useGetPaymentMethodQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.payment_methods;
  const WithdrawPaymentMethods = PaymentMethods?.filter((w) => w.type === "wd");

  console.log(WithdrawPaymentMethods);

  const [addUserWithdrawNumber] = useAddUserWithdrawNumberMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const sendData = {
        payment_method: method,
        to_number: mobileNumber,
        userId: userId,
      };
      const response = await addUserWithdrawNumber(sendData);
      if (response?.data) {
        closeForm();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FormClose = (e) => {
    if (e.target.id === "FormBackground") closeForm();
  };

  if (!openForm) return null;

  return (
    <div
      id="FormBackground"
      onClick={FormClose}
      className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center z-50"
    >
      <div className="w-11/12 sm:4/5 md:3/5 lg:w-2/5 p-5 rounded bg-white text-black">
        <div className="flex items-center justify-between ">
          <h1 className="text-xl font-bold">
            {" "}
            <span className="pr-3 text-green-400">|</span>All User withdraw
            Number
          </h1>
          <p className="text-lg text-gray-500" onClick={closeForm}>
            <FaTimes />
          </p>
        </div>
        <div className="">
          <form onSubmit={handleSubmit} action="" className="mt-5">
            <div className="flex items-center justify-between w-full my-4 ">
              <label htmlFor="ToUserId" className="text-sm font-bold ">
                User Id <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="ToUserId"
                placeholder="Enter User Id"
                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
                onChange={(e) => setUserId(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-between w-full my-4 ">
              <label htmlFor="FromUserId" className="text-sm font-bold ">
                Mobile Number<span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="FromUserId"
                placeholder="Enter Mobile Number"
                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
                onChange={(e) => setMobileNumber(e.target.value)}
              />
            </div>

            <div className="flex items-center justify-between w-full my-4 ">
              <label htmlFor="Notes" className="lg:text-sm font-bold ">
                Method
              </label>
              <select
                id="Notes"
                className="p-2 rounded w-2/3 text-sm border-2 focus:outline-teal-600"
                onChange={(e) => setSelectMethod(e.target.value)}
              >
                <option>--Seelct Method--</option>
                {WithdrawPaymentMethods?.map((m) => (
                  <option value={m.id}>{m.name}</option>
                ))}
              </select>
            </div>
            <button
              type="submit"
              className="bg-green-800 text-white text-sm w-full font-bold p-2 mt-3 rounded"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AllUserWithdrawModal;
