import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import { useAddNewQuestionMutation } from "../../../../features/api/getquestionApi";

const AddQuestionModal = ({ qiestionData, closeModal, openModal }) => {
  console.log(qiestionData?.id)
  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  const [addQuestion, setAddQuestion] = useState(null);

  const [addNewQuestion] = useAddNewQuestionMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const sendData = { addQuestion, match_id_for_q: qiestionData?.id };

      const response = await addNewQuestion(sendData);
      if (response?.data) {
        toast.success("Success...!!");
        closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!openModal) return null;

  return (
    <div
      id="ModalBackground"
      // onClick={ModalClose}
      className="fixed inset-0 bg-black bg-opacity-40 flex justify-center z-50"
    >
      <div className="w-11/12 sm:4/5 md:3/5 lg:w-2/5 h-[max-content] rounded bg-white text-black">
        <div className="flex justify-between border-b p-4">
          <h1 className="text-lg font-bold">Add Question</h1>
          <p
            className="text-lg text-gray-500 cursor-pointer"
            onClick={closeModal}
          >
            <FaTimes />
          </p>
        </div>
        <div className="flex flex-col gap-y-2 p-4">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-y-3">
              <label className="text-lg">
                Match: {qiestionData?.A_team} vs {qiestionData?.B_team}
              </label>
              <input
                type="text"
                onChange={(e) => setAddQuestion(e.target.value)}
                className="p-2 rounded w-full text-sm border-2 focus:outline-teal-600"
              />
              <button
                type="submit"
                className="bg-green-800 text-white text-sm w-full font-bold p-2 mt-3 rounded"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddQuestionModal;
