import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://rakibvai.munihaelectronics.com/backend/public/api",
  }),
  tagTypes: [
    "getallusers",
    "register",
    "login",
    "me",
    "rules",
    "get-withdraw-sending-numbers-list",
    "get-payment-methods-list",
    "get-deposit-receiving-numbers-list",
    "get-all-matches",
    "get-answer",
    "get-question",
    "get-all-defualt-matches",
    "get-default-questions",
    "mybets",
    "admin/all-bets",
    "get-default-answers",
    "dwnotifications",
    "myBalanceTransfer",
    "mySponsors",
    "myMembers",
    "myMembersbets",
    "clubTransaction",
    "club-withdraws"

    // "get-question"
  ],
  endpoints: (builder) => ({}),
});
