import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLoginMutation } from "../../../features/api/loginApi";

const AdminLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [loginUser] = useLoginMutation();
  const location = useLocation();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      userId,
      password,
    };
    try {
      const result = await loginUser(data).unwrap();
      const accessToken = result?.token;

      //   const from = location.state?.path || "/user/dashboard";
      const from = location.state?.path;
      if (result?.role === "superadmin") {
        localStorage.setItem("accessToken", accessToken);
        toast.success("Login successful");
        navigate("/admin");
      } else {
        toast.warning("Unauthorized access");
      }
    } catch (error) {
    //   console.log("error from admin", error?.data?.message);
      toast.error(error?.data?.message);
    }
  };

  return (
    <div className="h-screen w-screen bg-gray-200">
      <div className="h-1/2 bg-teal-600">
        <div className="pt-20">
          <div
            className="mb-10 mx-5 text-5xl lg:flex justify-center gap-2 "
            style={{ fontFamily: "Charm" }}
          >
            <h1>Welcome</h1>
            <p>ball65.com</p>
          </div>
          <div className="mx-auto w-80 lg:w-96 bg-white text-black">
            <div className="p-5 lg:p-10">
              <div className="flex gap-2 justify-center border-b pb-5">
                <i className="fa-solid fa-user-shield text-2xl"></i>
                <h1 className="text-2xl font-bold">Admin Login</h1>
              </div>
              <div className="mt-5">
                <form onSubmit={handleSubmit}>
                  <div className="w-full my-4">
                    <label
                      htmlFor="username"
                      className="text-sm font-semibold text-gray-500"
                    >
                      USERNAME <span className="text-red-400">*</span>
                    </label>
                    <input
                      type="text"
                      id="username"
                      onChange={(e) => setUserId(e.target.value)}
                      placeholder="User Name"
                      className="p-2 mt-2 rounded w-full text-sm border-2 focus:outline-teal-600"
                    />
                  </div>

                  <div className="relative w-full my-4">
                    <label
                      htmlFor="Password"
                      className="text-sm font-semibold text-gray-500"
                    >
                      PASSWORD <span className="text-red-400">*</span>
                    </label>
                    <div className="relative" id="Password">
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        className="p-2 mt-2 rounded w-full text-sm border-2 focus:outline-teal-600 "
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div
                        className="absolute top-1/2 right-0 mr-2 mt-1 transform -translate-y-1/2 text-black text-xl cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </div>
                    </div>
                  </div>

                  {/* <div className="my-3 flex justify-between">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="rememberMe"
                        name="rememberMe"
                      />
                      <label
                        for="rememberMe"
                        className="px-2 text-sm font-bold text-gray-500 cursor-pointer"
                      >
                        Stay Signed in
                      </label>
                    </div>
                    <label
                      for="rememberMe"
                      className="px-2 text-sm font-bold text-teal-500 hover:text-teal-700 cursor-pointer"
                    >
                      Forget Password ?
                    </label>
                  </div> */}
                  <button
                    type="submit"
                    className="bg-teal-500 hover:bg-teal-700 w-full text-sm font-bold text-white py-2 mt-3 rounded"
                  >
                    <i class="fa-solid fa-right-to-bracket mr-2" />
                    SIGN IN
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
