import React from "react";
import { useGetAgentDashboardAllCounterQuery } from "../../features/api/clubdashboardApi";

const ClubDashboardHome = () => {
  const { data } = useGetAgentDashboardAllCounterQuery();

  return (
    <div className="mt-16 px-2 py-1">
      <h1 className="text-3xl font-bold mb-6">Dashboard Overview</h1>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
        {data && (
          <>
            <div className="bg-emerald-800 p-4 rounded-lg text-white shadow-lg hover:shadow-xl transition-shadow duration-200">
              <div className="flex justify-between items-center border-b-2 border-white mb-2">
                <h1 className="text-3xl font-semibold">{data.total_own_balance}</h1>
                <p className="text-3xl font-semibold">৳</p>
              </div>
              <p className="text-sm">Total Balance</p>
            </div>

            <div className="bg-emerald-800 p-4 rounded-lg text-white shadow-lg hover:shadow-xl transition-shadow duration-200">
              <div className="flex justify-between items-center border-b-2 border-white mb-2">
                <h1 className="text-3xl font-semibold">{data.total_own_commission}</h1>
                <p className="text-3xl font-semibold">৳</p>
              </div>
              <p className="text-sm">Total Commission</p>
            </div>

            <div className="bg-emerald-800 p-4 rounded-lg text-white shadow-lg hover:shadow-xl transition-shadow duration-200">
              <div className="flex justify-between items-center border-b-2 border-white mb-2">
                <h1 className="text-3xl font-semibold">{data.total_own_withdraw}</h1>
                <p className="text-3xl font-semibold">৳</p>
              </div>
              <p className="text-sm">Total Withdraw</p>
            </div>

            <div className="bg-emerald-800 p-4 rounded-lg text-white shadow-lg hover:shadow-xl transition-shadow duration-200">
              <div className="flex justify-between items-center border-b-2 border-white mb-2">
                <h1 className="text-3xl font-semibold">{data.total_own_members}</h1>
              </div>
              
              <p className="text-sm">Total Members</p>
            </div>

            <div className="bg-emerald-800 p-4 rounded-lg text-white shadow-lg hover:shadow-xl transition-shadow duration-200">
              <div className="flex justify-between items-center border-b-2 border-white mb-2">
                <h1 className="text-3xl font-semibold">{data.total_members_balance}</h1>
                <p className="text-3xl font-semibold">৳</p>
              </div>
              <p className="text-sm">Total Members Balance</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ClubDashboardHome;
