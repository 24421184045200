import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FaAngleDown, FaList } from "react-icons/fa";
import UserToUserBalanceTransferForm from "../../../components/ModalAdmin/UserToUserBalanceTransferForm/UserToUserBalanceTransferForm";
import {
  useEditUserMutation,
  useGetAllUserWithdrawQuery,
  useGetUsersQuery,
  useRemoveUserMutation,
  useRemoveUserWithdrawMutation,
} from "../../../features/api/usersApi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CustomerinfoModal from "../../../components/ModalAdmin/CustomerinfoModal/CustomerinfoModal";
import AllUserWithdrawModal from "./AllUserWithdrawModal";
import { useGetPaymentMethodQuery } from "../../../features/api/paymentmethodApi";

const AllUserWithdraw = () => {
  const [openClubInfoModal, setOpenClubInfoModal] = useState(false);
  const [passData, setPassData] = useState(null);
  const handleClubInfoModal = (data) => {
    setOpenClubInfoModal(true);
    setPassData(data);
  };
  const handleCloseClubInfoModal = () => setOpenClubInfoModal(false);

  const { data, isLoading, refetch } = useGetAllUserWithdrawQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  console.log(data);

  const [customUser, setCustomUser] = useState(false);
  const sortedCustomer = data?.slice().sort((a, b) => a?.userId - b?.userId);

  const [openUserToUserBTForm, setOpenUserToUserBTForm] = useState(false);
  const handleUserToUserBTForm = () => setOpenUserToUserBTForm(true);
  const handleCloseUserToUserBTForm = () => setOpenUserToUserBTForm(false);
  const [searchText, setSearchText] = useState("");

  function dateFormat(dateString) {
    const date = new Date(dateString);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hour = date.getHours();
    let min = date.getMinutes();
    let time = hour + ":" + min;
    let getDate = day + "-" + month + "-" + year;
    return getDate;
  }
  function timeFormat(dateString) {
    const date = new Date(dateString);
    let hour = date.getHours();
    let min = date.getMinutes();
    let time = hour + ":" + min;
    return time;
  }
  const PaymentMethods = useGetPaymentMethodQuery(null, {
    refetchOnMountOrArgChange: true,
  })?.data?.payment_methods;

  // console.log(PaymentMethods);
  const [updateUser] = useEditUserMutation();
  const handleUpdate = async (row) => {
    const confirm = window.confirm("Are you sure to update?");
    if (confirm) {
      try {
        const data = {
          id: row?.id,
          status: row?.status == 0 ? 1 : 0,
        };
        console.log(data);
        const res = await updateUser(data);
        console.log(res);
        if (res?.error) {
          toast.error("Error..!!!");
        } else {
          toast.success(res?.data?.user);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const [removeUserWithdraw] = useRemoveUserWithdrawMutation();
  const handleUserWithdrawDelete = async (id) => {
    const confirm = window.confirm("Are you sure?");
    if (confirm) {
      try {
        const res = await removeUserWithdraw(id);
        console.log(res);
        if (res) {
          toast.success("Successfully Remove..!!!");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "100px",
    },
    {
      name: "UserId",
      selector: (row) => row?.userId,
      sortable: true,
    },
    {
      name: "Number",
      selector: (row) => row?.number,
      sortable: true,
    },
    {
      name: "Method",
      selector: (row) => {
        const name = PaymentMethods?.find(
          (m) => m?.id == row?.payment_method_id
        );
        return <p>{name?.name}</p>;
      },
      sortable: true,
    },
    // {
    //   name: "Type",
    //   selector: (row) => row?.status,
    //   sortable: true,
    // },
    {
      name: "Action",
      selector: (row) => (
        <div className="flex gap-2">
          {/* <button
            onClick={() => handleClubInfoModal(row)}
            className="bg-green-700 text-white p-1 rounded"
          >
            View
          </button> */}
          {/* <button
            onClick={() => handleUpdate(row)}
            className={`${
              row?.status === "1"
                ? "bg-green-600 text-white p-1 rounded"
                : "bg-red-500 text-white p-1 rounded"
            }`}
          >
            {row?.status === "1" ? "Active" : "Deactive"}
          </button> */}
          <button
            onClick={() => handleUserWithdrawDelete(row?.id)}
            className="bg-red-500 text-white font-bold p-1 rounded"
          >
            Delete
          </button>
        </div>
      ),
      sortable: true,
      width: "200px",
    },
  ];

  const customStyles = {
    // table: {
    //     style: {
    //         color: 'black',
    //         border: '1px solid gray'

    //     },
    // },
    tableWrapper: {
      style: {
        display: "table",
      },
    },
    responsiveWrapper: {
      style: {},
    },
    headRow: {
      style: {
        color: "white",
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: "teal",
      },
    },
  };
  // if (isLoading) return <p>Loading...</p>>
  const customFilter = (rows, searchText) => {
    return rows?.filter((row) => {
      return Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchText.toLowerCase())
      );
    });
  };

  return (
    <div className="mt-10">
      <div className="bg-white text-black p-5">
        <div className="  flex gap-2 ">
          <FaList className="text-xl mt-2"></FaList>
          <h1 className="text-2xl font-semibold">
            All User withdraw Number ( {data?.length} )
          </h1>
        </div>
      </div>
      <div className="pt-5 px-5 flex gap-2 text-sm font-semibold mb-2">
        <button
          className="p-2 bg-sky-700 hover:bg-sky-900 rounded"
          onClick={handleUserToUserBTForm}
        >
          <i className="fa-solid fa-money-bill-transfer"></i> AddItem
        </button>
        {/* <button
          onClick={() => setCustomUser(!customUser)}
          className="p-2 bg-sky-700 hover:bg-sky-900 rounded"
        >
          <i className="fa-solid fa-repeat"></i> Sort By Balance
        </button> */}
      </div>
      <div className="m-5 mt-0 bg-white rounded ">
        <div className="flex items-center justify-end px-5 py-4 ">
          <label
            htmlFor="search"
            className="text-black text-sm font-semibold p-1"
          >
            Search:
          </label>
          <input
            type="text"
            id="search"
            placeholder=""
            className=" text-black text-sm border-2 px-2 py-1 rounded focus:outline-teal-600"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="px-5">
          <DataTable
            columns={columns}
            data={customFilter(customUser ? sortedCustomer : data, searchText)}
            customStyles={customStyles}
            sortIcon={<FaAngleDown className="m-1" />}
            defaultSortAsc={true}
            paginationRowsPerPageOptions={[50, 100, 150]}
            paginationPerPage={100}
            pagination
            highlightOnHover
          />
        </div>
      </div>
      <AllUserWithdrawModal
        openForm={openUserToUserBTForm}
        closeForm={handleCloseUserToUserBTForm}
      />
      <CustomerinfoModal
        data={passData}
        openModal={openClubInfoModal}
        closeModal={handleCloseClubInfoModal}
      />
    </div>
  );
};

export default AllUserWithdraw;
