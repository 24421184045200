import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";
import { useAddTransferMutation } from "../../../features/api/mybetsApi";
import { useGetUserQuery } from "../../../features/api/loginApi";
import { toast } from "react-toastify";

const BalanceTransfer = ({ closeModal, openModal }) => {
  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  const user = useGetUserQuery(null, { refetchOnMountOrArgChange: true })?.data
    ?.user;

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [toUser, setToUser] = useState("");
  const [notes, setNotes] = useState("");
  const [amount, setTotalAmount] = useState("");

  const [addTransfer] = useAddTransferMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const sendData = {
      touser: toUser,
      t_amount: amount,
      notes: notes,
      password,
    };
    const response = await addTransfer(sendData);
    if (response?.data) {
      toast.success(response?.data?.message);
      closeModal();
      
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (!openModal) return null;

  return (
    <div
      // id="modalBackground"
      // onClick={modalClose}
      className="fixed inset-0 bg-black bg-opacity-40 snap-alw flex justify-center items-center"
    >
      <div
        className="w-11/12 md:3/5 lg:w-2/5 p-5 rounded"
        style={{ background: "linear-gradient(360deg, #37103a, #08545B)" }}
      >
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold">
            {" "}
            <span className="pr-3 text-green-400">|</span>Balance Transfer{" "}
          </h1>
          <p className="text-lg text-gray-500" onClick={closeModal}>
            <FaTimes />
          </p>
        </div>
        <div className=" mt-4">
          <form onSubmit={handleSubmit} action="">
            <div className="w-full my-2">
              <label htmlFor="userId" className="text-sm font-semibold">
                To <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="userId"
                value={toUser}
                onChange={(e) => setToUser(e.target.value)}
                placeholder="User Id"
                className="p-2 rounded w-full text-sm text-white bg-gray-950"
              />
            </div>
            <div className="w-full my-2">
              <label htmlFor="notes" className="text-sm font-semibold">
                Notes <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                id="notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Notes"
                className="p-2 rounded w-full text-sm text-white bg-gray-950"
              />
            </div>
            <div className="w-full my-2">
              <label htmlFor="amount" className="text-sm font-semibold">
                Amount <span className="text-red-400">*</span>
              </label>
              <input
                type="number"
                id="amount"
                value={amount}
                onChange={(e) => setTotalAmount(Number(e.target.value))}
                placeholder="Amount"
                className="p-2 rounded w-full text-sm text-white bg-gray-950"
              />
            </div>

            <div className="relative w-full my-2">
              <label htmlFor="Password" className="text-sm font-semibold">
                Password <span className="text-red-400">*</span>
              </label>
              <div className="relative" id="Password">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  className="p-2 rounded w-full text-sm text-white bg-gray-950 "
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div
                  className="absolute top-1/2 right-0 mr-2 transform -translate-y-1/2 text-white text-xl cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
              </div>
            </div>

            <button
              type="submit"
              className="bg-green-800 text-sm w-full font-bold p-3 mt-3 rounded"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BalanceTransfer;
