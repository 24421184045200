import React from "react";
import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { useEditLiveMatchHideFromPanelMutation } from "../../../../features/api/bettingmatchApi";

const HiddenMatchModal = ({
  closeModal,
  openModal,
  liveMatches,
  upComingMatches,
}) => {
  const liveHiddenMatch = liveMatches?.filter(
    (hidePanel) => hidePanel?.match?.hideFromPanel == "1"
  );
  const upcomingHiddenMatch = upComingMatches?.filter(
    (hidePanel) => hidePanel?.match?.hideFromPanel == "1"
  );

  const [editLiveMatchHideFromPanel] = useEditLiveMatchHideFromPanelMutation();
  const handleAddToPanel = async (item) => {
    try {
      const sendData = {
        id: item,
        hideFromPanel: 0,
      };
      const response = await editLiveMatchHideFromPanel(sendData);
      if (response?.data) {
        // closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [openModal]);

  function formatDate(dateString) {
    const inputDate = new Date(dateString);
    const options = {
      timeZone: "Asia/Dhaka",
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const dhakaTime = inputDate.toLocaleString("en-US", options);
    return dhakaTime;
  }

  if (!openModal) return null;

  return (
    <div
      id="ModalBackground"
      // onClick={ModalClose}
      className="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-cente z-50"
    >
      <div className="w-full md:w-2/5 h-[max-content] m-2 md:m-0 rounded-md md:rounded bg-white text-black">
        <div className="flex items-center justify-between border-b p-4">
          <h1 className="text-xl font-bold">
            {" "}
            <span className="pr-3 text-green-400">|</span>Hidden Match
          </h1>
          <p
            className="text-lg text-gray-500 cursor-pointer"
            onClick={closeModal}
          >
            <FaTimes />
          </p>
        </div>
        <div className="p-4 overflow-y-auto h-[90vh]">
          <div>
            <div className="border grid grid-cols-12">
              <span className="col-span-1 border-r text-center py-2 font-bold">
                SN.
              </span>
              <span className="col-span-9 py-2 ml-2 font-bold">
                Live Matches
              </span>
              <span className="col-span-2 p-2 border-l text-center font-bold">
                Actions
              </span>
            </div>
            <div>
              {liveHiddenMatch?.map((live, index) => {
                const { id, A_team, B_team, title, date } = live?.match;
                return (
                  <div key={id}>
                    <div className="border grid grid-cols-12">
                      <span className="grid justify-center items-center col-span-1 border-r text-center py-2 font-bold">
                        {index + 1}
                      </span>
                      <span className="grid items-center col-span-8 md:col-span-9 py-2 ml-2 font-bold">
                        {`${A_team} vs ${B_team}, ${title} || ${formatDate(
                          date
                        )}`}
                      </span>
                      <span className="grid justify-center items-center col-span-3 md:col-span-2 py-2 border-l text-center font-bold">
                        <button
                          onClick={() => handleAddToPanel(id)}
                          className="bg-green-700 text-white font-bold p-2 rounded-sm"
                        >
                          To Pannel
                        </button>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div className="border grid grid-cols-12">
              <span className="col-span-1 border-r text-center py-2 font-bold">
                SN.
              </span>
              <span className="col-span-9 py-2 ml-2 font-bold">
                Upcoming Matches
              </span>
              <span className="col-span-2 p-2 border-l text-center font-bold">
                Actions
              </span>
            </div>
            <div>
              {upcomingHiddenMatch?.map((live, index) => {
                const { id, A_team, B_team, title, date } = live?.match;
                return (
                  <div key={id}>
                    <div className="border grid grid-cols-12">
                      <span className="grid justify-center items-center col-span-1 border-r text-center py-2 font-bold">
                        {index + 1}
                      </span>
                      <span className="grid items-center col-span-8 md:col-span-9 py-2 ml-2 font-bold">
                        {`${A_team} vs ${B_team}, ${title} || ${formatDate(
                          date
                        )}`}
                      </span>
                      <span className="grid justify-center items-center col-span-3 md:col-span-2 py-2 border-l text-center font-bold">
                        <button
                          onClick={() => handleAddToPanel(id)}
                          className="bg-red-700 text-white font-bold p-2 rounded-sm"
                        >
                          To Pannel
                        </button>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HiddenMatchModal;
